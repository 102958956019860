import React from "react";
import List from "./List";
import Card from "../../components/Card";
import { isSetupComplete, useUser } from "../Auth/modules/auth";
import AccountSetup from "./AccountSetup";
import { Link } from "react-router";
import Hero from "../../components/Hero";
import TrainingVideos, { TRAINING_TYPE_INTRODUCTION } from "../../components/TrainingVideos";

const incompleteSubTitle = (
  <div>
    <div className="mb-4">
      DeepZen’s publisher portal allows you to conveniently manage all your
      audiobook projects in one place, quickly and cost-effectively. DeepZen
      offers a managed production service, we use digital (synthetic) voices but
      maintain quality through a rigorous quality process. Every audiobook we
      produce is proofed by an editor and sent to you for review.
    </div>
    <div className="mb-4">
      Here you can complete your account set-up. Once your account is set up,
      you’ll be able to create audiobook projects. Please follow the set-up
      checklist to complete the creation of your account and to review the cost
      and terms of our service.
    </div>
    <div className="mb-4">
      If you’d like to calculate the cost of your first audiobook project before
      completing the set-up process, simply click on the Pricing button in the
      main menu. Here you’ll find full details of how we charge for audiobooks.
      You can also use our handy price estimator tool to calculate the cost of
      your audiobook project.
    </div>
    <div className="mb-4">
      Keen to listen to the voices in our library? Click on the Voice Library
      button in the main menu to listen to a range of voices reading fiction and
      non-fiction samples. If you have any questions about the set-up process,
      please email <a href="mailto:help@depzen.io">help@deepzen.io</a>.
    </div>
  </div>
);
const completeSubTitle =
  "The dashboard is the place to keep track of all your projects and create new ones. You can also see the status of audio projects in production at a glance.";

const Dashboard = () => {
  const user = useUser();
  const isComplete = isSetupComplete(user);

  return (
    <div className="pb-5">
      <Hero
        title="DeepZen Portal Dashboard"
        description={isComplete ? completeSubTitle : incompleteSubTitle}
      />

      <div className="px-4 lg:sm:p-0">
        {isComplete ? (
          <div className="container">
            <Card className="mt-4">
              <List />
            </Card>
            <TrainingVideos 
              className="container mt-6"
              trainingType={TRAINING_TYPE_INTRODUCTION}
            />
          </div>
        ) : (
          <AccountSetup />
        )}

        <div className="text-gray-600 mt-6 text-center">
          <Link to="/help" className="flex-inline items-center">
            <svg
              className="w-5 h-5 inline mr-1"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z"
              ></path>
            </svg>
            Need help? See our FAQ
          </Link>
        </div>

      </div>
    </div>
  );
};

export default Dashboard;
