import React from "react";
import Detail from "../Projects/Detail";

const Layout = ({ children }) => (
    <div className="flex flex-col flex-1 overflow-y-auto">{children}</div>
);

export default {
  path: "guest",
  component: Layout,
  childRoutes: [{ path: ":id", component: Detail }],
};
