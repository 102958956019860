import React from "react";
import logoIcon from "../../assets/logo-icon.png";
import Voices from "../Voices";
import { Link } from "react-router";

const Layout = ({ children }) => (
    <>
    <nav className="bg-gray-900">
      <div className="flex items-center justify-between h-16">
        <div className="flex items-center">
          <div className="flex-shrink-0">
            <Link to="https://deepzen.io" target="_blank" className="flex-inline items-center">
              <img className="h-8 ml-6 w-auto" src={logoIcon} alt="DeepZen" />
            </Link>  
          </div>
        </div>
      </div>
    </nav>
    <div className="flex flex-col flex-1 overflow-y-auto">{children}</div>
  </>
);

export default {
  path: "sample",
  component: Layout,
  childRoutes: [{ path: "voices", component: Voices }],
};
