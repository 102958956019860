import React, { useEffect, useState } from "react";
import TextField from "../../../components/Forms/TextField";
import Button from "../../../components/Button";
import { Field, reduxForm } from "redux-form";
import usePricing from "../../Pricing/usePricing";
import { signs } from "../../Pricing/PricingCard";
import axios from "axios";
import { useUser } from "../../Auth/modules/auth";
import { CardElement } from "@stripe/react-stripe-js";

const PaymentForm = ({
  wordCount,
  text,
  editMode,
  handleSubmit,
  paymentMethod,
  setPaymentMethod,
  distribution,
  loading,
}) => {
  const [estimation, setEstimation] = useState(null);
  const prices = usePricing();
  const user = useUser();
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    axios
      .post("portal/text-estimation/", {
        text,
        edit_mode: editMode,
        distribution,
      })
      .then((res) => {
        setEstimation(res.data);

        const totalPrice = Number(
          res.data.prices.reduce((sum, row) => sum + row.amount, 0)
        );

        if (totalPrice === 0) {
          setPaymentMethod("invoice");
        }

        setTotalPrice(totalPrice);
      })
      .catch((e) => alert("An error occurred while estimating price."));
  }, [wordCount]);

  if (!prices || !user) return null;

  return (
    <form onSubmit={handleSubmit}>
      <div className="rounded-md bg-blue-50 text-blue-800 py-4 px-5 shadow-sm text-xl mb-4">
        <div>
          <strong>Estimated duration:</strong> ~
          {estimation?.hours ? Number(estimation.hours).toLocaleString() : "-"}{" "}
          hours
        </div>
        {estimation?.prices.map((row, index) => (
          <div key={index}>
            <strong>{row.description}:</strong> {signs[prices.currency]}
            {Number(row.amount).toLocaleString("en-US", {
              maximumFractionDigits: 2,
            })}
          </div>
        )) ?? "Loading..."}
        {estimation?.prices.length > 1 && (
          <div>
            <strong>Total price:</strong> {signs[prices.currency]}
            {totalPrice.toLocaleString("en-US", {
              maximumFractionDigits: 2,
            })}
          </div>
        )}
      </div>

      {!user.business.manual_billing && totalPrice > 0 && (
        <>
          <div className="bg-gray-100 rounded-md px-4 overflow-hidden shadow-sm">
            <div
              onClick={() => setPaymentMethod("credit_card")}
              className="p-4 font-semibold text-xs tracking-wider bg-gray-200 -mx-4 cursor-pointer"
            >
              <input
                type="radio"
                checked={paymentMethod === "credit_card"}
                className="form-radio mr-1 pointer-events-none"
              />{" "}
              PAY WITH CREDIT CARD
            </div>

            {paymentMethod === "credit_card" && (
              <div className="py-4">
                <div className="p-4 bg-white shadow-md rounded-md">
                  <CardElement
                    options={{
                      hidePostalCode: true,
                      style: {
                        base: {
                          fontSize: "17px",
                          color: "#424770",
                          "::placeholder": {
                            color: "#aab7c4",
                          },
                        },
                        invalid: {
                          color: "#9e2146",
                        },
                      },
                    }}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="bg-gray-100 rounded-md px-4 overflow-hidden shadow-sm my-4">
            <div
              onClick={() => setPaymentMethod("invoice")}
              className="p-4 font-semibold text-xs tracking-wider bg-gray-200 -mx-4 cursor-pointer"
            >
              <input
                type="radio"
                checked={paymentMethod === "invoice"}
                className="form-radio mr-1"
              />{" "}
              PAY WITH BANK / WIRE TRANSFER
            </div>

            {paymentMethod === "invoice" && (
              <div className="py-4">
                You’ll receive an invoice with our payment details by email and
                your audio project will be put into production once the payment
                has been received.
              </div>
            )}
          </div>
        </>
      )}

      <Field
        name="customer_po_number"
        label="Customer PO Number (optional)"
        component={TextField}
      />

      <Button
        loading={loading}
        size="xlarge"
        block
        type="success"
        className="mt-4"
      >
        Create Project
      </Button>
    </form>
  );
};

export default reduxForm({
  form: "create-project-payment",
  initialValues: {
    customer_po_number: "",
  },
})(PaymentForm);
