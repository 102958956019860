import React from "react";
import { Popconfirm, Switch, Popover } from "antd";
import { TableRow, TableCell } from "../../../../components/Table";
import classNames from "classnames";
import { Icon } from "antd";
import IssueTimeInput from "./IssueTimeInput";
import Recorder from "../../../Recorder";
import Button from "../../../../components/Button";

const IssueRow = ({ issue, onChange, onDelete, isReview, project }) => {
  const handleChange = (field, value) => onChange({ ...issue, [field]: value });
  
  const recorderContent = (issue) => (
    <Recorder
      defaultAudioURL={issue.audioDetails?.recording}
      forceRecord={!issue.record}
      onAudioChange={(e, b) => onAudioChange(e, b)}
    />
  );

  const onAudioChange = (e, b) => {
    if(!issue.audioDetails)
      issue.audioDetails = {};
    issue.audioDetails.recording = e;
    issue.audioDetails.base64Data = b;
    handleChange("audioDetails", issue.audioDetails);
  };

  const handleVisibleChange = (visible) => {
    issue.record = visible;
    handleChange("record", issue.record);
  };

  const closePopover = () => {
    issue.record = false;
    handleChange("record", issue.record);
  };

  return (
    <TableRow className={classNames(issue.id ? "" : "bg-gray-100")}>
      <TableCell valign="top" className="font-medium">
        <IssueTimeInput
          value={issue.time}
          disabled={isReview}
          onValueChange={(value) => handleChange("time", value)}
        />
      </TableCell>
      <TableCell valign="top" className="font-medium">
        <select
          className="form-select h-8 pt-1 w-full text-sm"
          value={issue.type_of_issue}
          disabled={isReview}
          onChange={(e) => handleChange("type_of_issue", e.target.value)}
        >
          <option value="">-- Choose --</option>
          <option value="X">X - Noise</option>
          <option value="M">M - Missing/omitted audio</option>
          <option value="T">T - Typo</option>
          <option value="[]">[] - Excessive pause</option>
          <option value="][">][ - Needs pause</option>
          <option value="I">I - Incorrect word (misread)</option>
          <option value="P">
            P - Pronunciation issue (mispronunciation, wrong pronunciation for
            the dialect)
          </option>
          <option value="A">A - Awkward or unclear (slur, stumble)</option>
        </select>
      </TableCell>
      
      {isReview ? (
        <>
          <TableCell valign="top" className="font-medium">
            {issue.status}
            {issue.editor_notes && (
              <span className="italic text-sm text-gray-500 font-normal ml-1">
                ({issue.editor_notes})
              </span>
            )}
          </TableCell>
          <TableCell valign="top" className="pt-3" width="2%">
            <Switch
              size="small"
              onChange={(checked) => handleChange("approved", checked)}
              checked={issue.approved}
            />
          </TableCell>
          <TableCell className="font-medium">
            <textarea
              className={classNames(
                "form-textarea block w-full text-sm transition-all",
                issue.approved && "opacity-0 pointer-events-none"
              )}
              rows={1}
              value={issue.comment}
              onChange={(e) => handleChange("comment", e.target.value)}
            />
          </TableCell>
        </>
      ) : (
        <>
          <TableCell className="font-medium">
            <textarea
              className="form-textarea text-sm"
              rows={1}
              value={issue.comment}
              onChange={(e) => handleChange("comment", e.target.value)}
            />
          </TableCell>
          {project.edit_mode === "managed" && (
            <TableCell className="flex justify-center">
              <Popover
                content={recorderContent(issue)}
                title={
                  <p>
                    Record
                    <button onClick={(e) => closePopover()} className="float-right">
                      <Icon type="close-circle-o" />
                    </button>
                  </p>
                }
                trigger="click"
                visible={issue.record}
                onVisibleChange={(d) => handleVisibleChange(d)}
              >
                {issue.audioDetails?.recording ? (
                  <Button type="info">Recorded</Button>
                ) : (
                  <Button className="px-5">Record</Button>
                )}
              </Popover>
            </TableCell>
          )}
          <TableCell valign="top" className="pt-3" width="2%">
            <Popconfirm onConfirm={onDelete} title="Are you sure?">
              <Icon type="delete" className="text-lg cursor-pointer" />
            </Popconfirm>
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

export default IssueRow;
