import React, { Component } from "react";
import LeftArrow from "./LeftArrow";
import RightArrow from "./RightArrow";
import Select from "react-select";
import classNames from "classnames";

const selectCustomStyles = {
  valueContainer: (provided) => ({ ...provided, justifyContent: "center" }),
  menuList: (provided) => ({
    ...provided,
    textAlign: "center"
  })
};

const Slider = ({ project, chapters, activeChapterIndex, onChange }) => {
  let chapterTitles = {};
  project.book.books.map((c) => (chapterTitles[c.id] = c.title));

  const handleNextSlide = () => {
    if (activeChapterIndex < chapters.length - 1) {
      onChange(activeChapterIndex + 1);
    }
  };

  const handlePrevSlide = () => {
    if (activeChapterIndex > 0) {
      onChange(activeChapterIndex - 1);
    }
  };

  const chapterOptions = chapters.map((c, i) => ({
    value: i,
    label: chapterTitles[c.chapter],
  }));

  return (
    <div className="flex flex-col text-center content-center items-center w-full">
      <div className="flex flex-row content-center w-full items-center justify-center">
        <div
          className={classNames("",
            activeChapterIndex === 0 && "opacity-0 pointer-events-none"
          )}
        >
          <LeftArrow goToPrevSlide={handlePrevSlide} />
        </div>

        <div className="w-1/2 pb-1">
          <Select
            menuPortalTarget={document.body} 
            value={chapterOptions[activeChapterIndex]}
            onChange={(o) => onChange(o.value)}
            options={chapterOptions}
            styles={selectCustomStyles}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
          />
        </div>

        <div
          className={classNames("",
            activeChapterIndex === chapters.length - 1 &&
            "opacity-0 pointer-events-none"
          )}
        >
          <RightArrow goToNextSlide={handleNextSlide} />
        </div>
      </div>
    </div>
  );
};

export default Slider;
