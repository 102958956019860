import React, { useState } from "react";
import TextField from "../../../components/Forms/TextField";
import DropzoneField from "../../../components/Forms/DropzoneField";
import Button from "../../../components/Button";
import { required } from "../../../services/validations";
import SelectField from "../../../components/Forms/SelectField";
import TextAreaField from "../../../components/Forms/TextAreaField";
import { Field, getFormValues, reduxForm, change } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import genres from "./genres";
import usePricing from "../../Pricing/usePricing";
import { signs } from "../../Pricing/PricingCard";
import { RadioGroup } from "@headlessui/react";
import classNames from "classnames";
import { Link } from "react-router";
import Contract from "./DistributionContract";
import DistributionInfo from "./DistributionInfo";

const DEFAULT_OPENING_CREDITS = `[PUBLISHER] presents [TITLE] by [AUTHOR]. Digitally narrated using the voice of [NARRATOR].

(Please include any links to additional content here)`;

const DEFAULT_CLOSING_CREDITS = `This recording of [TITLE] by [AUTHOR], was presented by [PUBLISHER].

Sound recording copyright [YEAR] and produced by DeepZen Limited [YEAR]`;

const EDIT_MODES = [
  {
    id: "managed",
    name: "Managed Service",
  },
  {
    id: "automated",
    name: "Automated Service",
  },
];

const BasicsForm = ({ loading, voices, handleSubmit }) => {
  const values = useSelector(getFormValues("create-project-basics"));
  const pricing = usePricing();
  const [showContract, setShowContract] = useState(false);
  const [showDistributionInfo, setShowDistributionInfo] = useState(false);
  const dispatch = useDispatch();

  if (!pricing) return null;

  const estimate = (pfh) =>
    values &&
    parseInt(values.word_count) > 0 &&
    Math.round(
      Number(
        Math.ceil(parseInt(values.word_count) / pricing.num_of_words_per_hour) *
          pfh
      )
    );
  const estimation = estimate(pricing.pfh[values.edit_mode]);
  const firstProjectEstimation = pricing.first_project_price[values.edit_mode]
    ? estimate(pricing.first_project_price[values.edit_mode])
    : null;

  const normalizeDob = (val, prevVal) => {
      // Prevent non-digit characters being entered
      if (isNaN(parseInt(val[val.length - 1], 10))) {
          return val.slice(0, -1);
      }
  
      // When user is deleting, this prevents immediate re-addition of '/' when it's deleted 
      if (prevVal && (prevVal.length >= val.length)) {
          return val;
      }
  
      // Add / at appropriate sections of the input
      if (val.length === 2 || val.length === 5) {
          val += '/';
      }
  
      // Prevent characters being entered after Dob is full
      if (val.length >= 10) {
          return val.slice(0, 10);
      }
  
      return val;
  }

  return (
    <form onSubmit={handleSubmit}>
      <Contract
        show={showContract}
        onComplete={(result) => {
          dispatch(change("create-project-basics", "distribution", result));
          setShowContract(false);
        }}
      />

      <DistributionInfo
        show={showDistributionInfo}
        onClose={(result) => {
          setShowDistributionInfo(false);
        }}
        onClickSetupAgreement={(result) => {
          setShowDistributionInfo(false);
          setTimeout(() => {
            dispatch(change("create-project-basics", "distribution", result));
            setShowContract(true);
          }, 200);
        }}
      />

      <RadioGroup
        value={values.edit_mode}
        onChange={(value) =>
          dispatch(change("create-project-basics", "edit_mode", value))
        }
      >
        <RadioGroup.Label className="sr-only">Privacy setting</RadioGroup.Label>
        <div className="bg-white rounded-md -space-y-px">
          {EDIT_MODES.map((mode, idx) => (
            <RadioGroup.Option
              key={mode.name}
              value={mode.id}
              className={({ checked }) =>
                classNames(
                  idx === 0 ? "rounded-t-md" : "rounded-b-md",
                  checked
                    ? "bg-indigo-50 border-indigo-200 z-10"
                    : "border-gray-200",
                  "relative border p-4 flex cursor-pointer focus:outline-none"
                )
              }
            >
              {({ active, checked }) => (
                <>
                  <span
                    className={classNames(
                      checked
                        ? "bg-indigo-600 border-transparent"
                        : "bg-white border-gray-300",
                      active ? "ring-2 ring-offset-2 ring-indigo-500" : "",
                      "h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center p-1"
                    )}
                    aria-hidden="true"
                  >
                    <span className="rounded-full bg-white w-1.5 h-1.5" />
                  </span>
                  <div className="ml-3 flex flex-col">
                    <RadioGroup.Label
                      as="span"
                      className={classNames(
                        checked ? "text-indigo-900" : "text-gray-900",
                        "block text-sm font-medium"
                      )}
                    >
                      {mode.name}
                    </RadioGroup.Label>
                  </div>
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>

      <div className="flex items-center border p-4 rounded-md mb-7 mt-4">
        <label className="flex justify-center items-center">
          <input
            type="checkbox"
            onChange={(e) => {
              dispatch(
                change(
                  "create-project-basics",
                  "distribution",
                  e.target.checked
                )
              );

              if (e.target.checked) {
                setShowContract(true);
              }
            }}
            className="mr-3"
          />
          {/* 
                Distribution (+
                  {signs[pricing.currency]}
                  {pricing.distribution})
                  */}
          Distribution (+$69/ £49 year one, and a yearly fee of $24.99/ £19.99
          thereafter)
        </label>
        <a onClick={(e) => setShowDistributionInfo(true)}>
          <svg
            className="w-4 h-4 inline ml-1"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </a>
      </div>

      <Link
        to="/pricing"
        target="_blank"
        className="-mt-3 flex items-center mb-4 text-sm text-blue-400 font-bold"
      >
        <svg
          className="w-4 h-4 inline mr-1"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        Click here for more information on pricing options
      </Link>

      <Field
        name="title"
        label="Book Title"
        component={TextField}
        validate={[required]}
      />

      <div className="h-4" />

      <div className="mt-4 md:-ml-4 md:flex">
        <div className="flex mb-4 md:flex-1 md:ml-4">
          <Field
            name="isbn"
            label="Audiobook ISBN/numeric identifier"
            component={TextField}
            validate={[required]}
          />
        </div>
        <div className="flex mb-4 md:flex-1 md:ml-4">
          <Field
            name="genre"
            label="Genre"
            component={SelectField}
            validate={[required]}
            options={[
              { value: "", label: "-- Choose Genre --" },
              ...genres.map((genre) => ({ value: genre, label: genre })),
            ]}
          />
        </div>
        <div className="flex mb-4 md:flex-1 md:ml-4">
          <Field
            name="word_count"
            label="Word Count of Manuscript"
            info={
              <span>
                If you don’t have a word count to hand you can use a conversion
                tool to quickly calculate this. We suggest{" "}
                <a target="_blank" href="https://countwordsfree.com/">
                  https://countwordsfree.com/
                </a>
              </span>
            }
            component={TextField}
            validate={[required]}
            type="number"
            step={1}
          />
        </div>
        <div className="flex mb-4 md:flex-1 md:ml-4">
          <Field
            name="publication_date"
            label="Final File Delivery Date"
            type="date"
            info=""
            component={TextField}
            validate={[required]}
            normalize={normalizeDob}
          />
        </div>
      </div>

      <div className="h-4" />

      <Field
        className="h-48"
        name="opening_credits"
        label="Opening Credits"
        component={TextAreaField}
        info="We recommend the following for opening and closing credits but feel free to add your own wording"
        validate={[required]}
      />

      <div className="h-4" />

      <Field
        className="h-48"
        name="closing_credits"
        label="Closing Credits"
        info="We recommend the following for opening and closing credits but feel free to add your own wording"
        component={TextAreaField}
        validate={[required]}
      />

      <div className="h-4" />

      <Field
        name="voice"
        label="Voice"
        component={SelectField}
        info="Click on the Voice Library button in the top menu to listen to voices."
        validate={[required]}
        options={[
          { value: "", label: "-- Choose Voice --" },
          ...voices.map((v) => ({
            value: v.id,
            label: `${v.language} / ${v.name_of_narrator} / ${
              v.gender === "f" ? "Female" : "Male"
            }`,
          })),
        ]}
      />

      <div className="h-4" />

      <Field
        name="file"
        label="Upload an e-Pub, Microsoft Word or TXT file"
        component={DropzoneField}
        validate={[required]}
        accept=".epub, .doc, .docx, .txt"
      />

      {!!estimation && (
        <div className="rounded-md bg-blue-50 text-blue-800 py-4 px-5 shadow-sm text-lg mt-4">
          <div>
            <strong>Estimated audiobook production price: </strong>
            <span
              className={
                pricing.first_project_price[values.edit_mode]
                  ? "line-through opacity-75"
                  : ""
              }
            >
              ~{signs[pricing.currency]}
              {estimation}
            </span>
            {pricing.first_project_price[values.edit_mode] && (
              <span className="ml-2">
                {signs[pricing.currency]}
                {firstProjectEstimation}
              </span>
            )}
          </div>
          {values.distribution && (
            <>
              <div>
                <strong>Distribution set-up cost: </strong>
                <span>
                  {signs[pricing.currency]}
                  {pricing.distribution}
                </span>
              </div>
              <div>
                <strong>Total price: </strong>
                <span>
                  ~{signs[pricing.currency]}
                  {(pricing.first_project_price[values.edit_mode]
                    ? firstProjectEstimation
                    : estimation) + pricing.distribution}
                </span>
              </div>
            </>
          )}

          {pricing.first_project_price[values.edit_mode] && (
            <div className="text-sm">
              <svg
                className="w-4 h-4 inline"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>{" "}
              {pricing.first_project_price
                ? "Your first project discount has been applied."
                : "The estimated price and duration may vary after you’ve removed extraneous content from your manuscript."}
            </div>
          )}
        </div>
      )}

      <Button
        loading={loading}
        size="large"
        block
        type="success"
        className="mt-4"
      >
        Save and Continue
      </Button>
    </form>
  );
};

export default reduxForm({
  form: "create-project-basics",
  initialValues: {
    opening_credits: DEFAULT_OPENING_CREDITS,
    closing_credits: DEFAULT_CLOSING_CREDITS,
    edit_mode: "managed",
    distribution: false,
  },
})(BasicsForm);
