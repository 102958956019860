import React from "react";

const StepClasses = ({ removedClasses, setRemovedClasses }) => (
  <>
    Use this option to remove extraneous content within chapters that is not
    required in the audiobook. For example, images, footnotes, quotations,
    tables and captions.
    <p className="mt-2">
      Click on the dropdown menu or scroll through the manuscript using the
      buttons to the left and right of the drop-down menu and click on the
      specific content type that you would like to remove.
    </p>
    <p className="mt-2">
      Please note, once you have removed a specific content type (e.g.
      quotation), the tool will automatically remove all instances of that
      content type. This will be shown as struck through text.
    </p>
    <p className="my-2">
      To remove or change a selected content type, click 'undo' below.
    </p>
    {removedClasses.length > 0 && (
      <div className="bg-red-50 px-3 py-2 mt-3">
        <div className="font-semibold text-red-800">Text to be deleted</div>

        {removedClasses.map((c) => (
          <div key={c} className="mt-1">
            {c}
            <a
              className="text-xs ml-2"
              onClick={() =>
                setRemovedClasses(removedClasses.filter((h) => h !== c))
              }
            >
              Undo
            </a>
          </div>
        ))}
      </div>
    )}
  </>
);

export default StepClasses;
