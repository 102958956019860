import React from "react";
import { Link } from "react-router";
import logoIcon from "../../assets/logo-icon.png";
import Navbar from "./Navbar";
import Content from "./Content";
import Footer from "./Footer";

const HomePage = () => (
    <div className="flex w-screen overflow-y-auto justify-center">
        <div className="w-full">
            <Navbar />
            <Content />
            <Footer />
        </div>
    </div>
);

export default HomePage;
