import React from "react";
import InputMask from "react-input-mask";

const formatChars = {
  a: "[0-9]",
  b: "[0-5]",
};

export class IssueTimeInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
    };
  }

  componentDidMount() {
    let issueTime = "";
    if (this.props.value) {
      this.props.value.split(":").map((item, index) => {
        issueTime = issueTime + item;
      });
    }
    this.setState({ value: issueTime });
  }

  componentDidUpdate(oldProps) {
    if (oldProps.value !== this.props.value) {
      let issueTime = "";
      if (this.props.value) {
        this.props.value.split(":").map((item, index) => {
          issueTime = issueTime + item;
        });
      }
      this.setState({ value: issueTime });
    }
  }

  onChange = (event) => {
    this.setState({
      value: event.target.value,
    });
    this.props.onValueChange(event.target.value);
  };

  beforeMaskedValueChange = (newState, oldState, userInput) => {
    var { value } = newState;
    var selection = newState.selection;
    var cursorPosition = selection ? selection.start : null;

    // keep minus if entered by user
    if (
      value.endsWith("-") &&
      userInput !== "-" &&
      !this.state.value.endsWith("-")
    ) {
      if (cursorPosition === value.length) {
        cursorPosition--;
        selection = { start: cursorPosition, end: cursorPosition };
      }
      value = value.slice(0, -1);
    }

    return {
      value,
      selection,
    };
  };

  render() {
    return (
      <InputMask
        className="form-input w-28 p-2 h-8 text-sm"
        mask="aa:ba:ba"
        formatChars={formatChars}
        alwaysShowMask={true}
        maskChar={"_"}
        value={this.state.value}
        onChange={this.onChange}
        beforeMaskedValueChange={this.beforeMaskedValueChange}
        disabled={this.props.disabled}
      />
    );
  }
}

export default IssueTimeInput;
