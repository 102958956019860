import React from "react";
import Card, { CardBody } from "../../../../components/Card";

export function EditingBook() {
  return (
    <Card title="Audiobook Creation">
      <CardBody>
       <p>
        Your audiobook is being created. 
       </p>
       <p>
        If you selected the automated service, you’ll receive your completed audiobook within an estimated 3 days. 
       </p>
       <p>
        If you chose the managed service, your audio files has been passed to our team of editors for review and corrections. Generally this stage takes 7 to 10 working days from receipt of pronunciation guidance. We’ll send you an email when your audiobook is ready for your corrections review. 
       </p>
      </CardBody>
    </Card>
  );
}

export default EditingBook;
