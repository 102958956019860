import React from "react";

const StepChapter = ({ book, removedChapters, onUndo }) => (
  <>
    {removedChapters.length > 0 && (
      <div className="bg-red-50 px-3 py-2 mt-3">
        <div className="font-semibold text-red-800">Chapters to be removed</div>
        {removedChapters.map((i) => (
          <div key={i} className="mt-1">
            {book.chapters[i].label}
            <a className="text-xs ml-2" onClick={() => onUndo(i)}>
              Undo
            </a>
          </div>
        ))}
      </div>
    )}

    <p className="mt-2">
      Use this option to remove chapters or sections or pages (e.g. Contents, Copyright page) that are
      not required in the audiobook.
    </p>
    <p className="mt-2">
      Click on the dropdown menu or scroll through the manuscript using the
      buttons to the left and right of the drop-down menu.
    </p>
    <p className="my-2">
      Select the chapter or section you wish to delete and click the 'delete
      this chapter' button.
    </p>
  </>
);

export default StepChapter;
