import React, { useEffect, useState } from "react";
import PageHeader from "../../../components/PageHeader";
import Loading from "../../../components/Loading";
import ApiError from "../../../components/ApiError";
import Card, { CardBody } from "../../../components/Card";
import { Icon, message } from "antd";
import axios from "axios";
import { Link } from "react-router";
import DropzoneField from "../../../components/Forms/DropzoneField";
import { Field, reduxForm, submit } from "redux-form";
import { required } from "../../../services/validations";
import Button from "../../../components/Button";
import { useDispatch } from "react-redux";
import TextField from "../../../components/Forms/TextField";
import TextAreaField from "../../../components/Forms/TextAreaField";
import SelectField from "../../../components/Forms/SelectField";
import CoverImageInfo from "./CoverImageInfo";

const Form = ({ handleSubmit, loading, apiError, data }) => {
  const [frontCoverForm, setFrontCoverForm] = useState(!data?.front_cover);
  const [showCoverImageInfo, setShowCoverImageInfo] = useState(false);

  return (
    <form onSubmit={handleSubmit}>
      {apiError && <ApiError error={apiError} />}

      <CoverImageInfo
        show={showCoverImageInfo}
        onClose={(result) => {
          setShowCoverImageInfo(false);
        }}
      />

      <Card
        title={
          <div className="flex items-center">
            <h3>Cover Image</h3>
            <a onClick={(e) => setShowCoverImageInfo(true)}>
              <svg
                className="w-4 h-4 inline ml-1"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </a>
          </div>
        }
        className="mb-4"
      >
        <CardBody>
          {data?.front_cover && (
            <>
              <img style={{ width: 250, height: 250 }} src={data.front_cover} />
              {!frontCoverForm && (
                <Button
                  onClick={() => setFrontCoverForm(true)}
                  size="xs"
                  className="mt-2 px-2"
                >
                  Change cover image
                </Button>
              )}
            </>
          )}

          {frontCoverForm && (
            <Field
              name="front_cover"
              label="3000x3000 JPEG image}"
              component={DropzoneField}
              accept=".jpg, .jpeg"
            />
          )}
        </CardBody>
      </Card>

      <Card title="Metadata">
        <CardBody>
          <Field
            name="title"
            label="Book Title"
            component={TextField}
            validate={[required]}
            className="mb-3"
            info={
              <span>
                Add your book’s title as per your print book or eBook.
              </span>
            }
          />
          <Field
            name="subtitle"
            label="Book Subtitle"
            component={TextField}
            validate={[required]}
            className="mb-3"
            info={
              <span>
                Add your book’s subtitle as per your print book or eBook.
              </span>
            }
          />
          <Field
            name="publisher"
            label="Publisher"
            component={TextField}
            validate={[required]}
            className="mb-3"
            info={
              <span>
                Add the name of your publishing company name (Publisher) and the
                imprint for the title you are uploading. If you are an author
                and don’t have these, simply enter your name or pen name (as it
                appears on the cover image).
              </span>
            }
          />
          <Field
            name="imprint"
            label="Imprint"
            component={TextField}
            validate={[required]}
            className="mb-3"
            info={
              <span>
                Add the name of your publishing company name (Publisher) and the
                imprint for the title you are uploading. If you are an author
                and don’t have these, simply enter your name or pen name (as it
                appears on the cover image).
              </span>
            }
          />
          <Field
            name="publication_date"
            label="Publication Date"
            type="date"
            component={TextField}
            validate={[required]}
            className="mb-3"
            info={
              <span>
                This is the date your title was first published. It should be
                the same as the date on the copyright page of your print or
                eBook.
              </span>
            }
          />

          <Field
            name="on_sale_date"
            label="On-Sale Date"
            type="date"
            component={TextField}
            validate={[required]}
            className="mb-3"
            info={
              <span>
                This is the first date your title can be listed for sale. Please
                put in today’s date if the date is not in the future.
              </span>
            }
          />

          <Field
            name="grade_level"
            label="Grade Level"
            component={SelectField}
            validate={[required]}
            className="mb-3"
            info={
              <span>
                If an adult title please select ‘Adult’, if a children’s title
                please select the appropriate US grade level from the drop down
                menu.
              </span>
            }
            options={[
              { value: "", label: "-- Choose Grade Level --" },
              { value: "Grades K-3", label: "Grades K-3" },
              { value: "Grades 3-6", label: "Grades 3-6" },
              { value: "Grades 5-8", label: "Grades 5-8" },
              { value: "Pre-K", label: "Pre-K" },
              { value: "Young Adult", label: "Young Adult" },
              { value: "Adult", label: "Adult" },
            ]}
          />

          <Field
            name="country_of_publication"
            label="Country of Publication"
            component={TextField}
            validate={[required]}
            className="mb-3"
            info={
              <span>
                This is the Country of publication as per your copyright page.
              </span>
            }
          />

          <Field
            name="short_description"
            label="Short Description"
            component={TextField}
            validate={[required]}
            className="mb-3"
            info={
              <span>
                The short description is the type of blurb that might go on the
                back cover of a physical book.
              </span>
            }
          />

          <Field
            name="main_description"
            label="Main Description"
            component={TextAreaField}
            validate={[required]}
            className="mb-3"
            info={
              <span>
                <div className="underline">
                  The long description of the book
                </div>
                , is also referred to as the blurb, which can include any
                awards, nominations or media quotes you’d like to include.
              </span>
            }
          />

          <Field
            name="author"
            label="Author Name"
            component={TextField}
            validate={[required]}
            className="mb-3"
            info={
              <span>
                The name or pen name of the author as it appears on the cover
                image or the copyright page.
              </span>
            }
          />

          <Field
            name="genre_code"
            label="Genre codes"
            component={TextField}
            validate={[required]}
            className="mb-3"
            info={
              <span>
                Genre codes help retailers identify the correct category in
                which to list your title. You can select up to three. We prefer
                you to supply BISAC codes. You can find a list of codes here:{" "}
                <a target="_blank" href="https://bisg.org/page/bisacedition ">
                  https://bisg.org/page/bisacedition
                </a>
              </span>
            }
          />

          <Field
            name="keywords"
            label="Keywords"
            component={TextField}
            className="mb-3"
            info={
              <span>
                Please enter search terms that are pertinent or relevant to
                helping customers find your title.
              </span>
            }
          />

          <Field
            name="copyright_statement"
            label="Copyright Statement (Edition Type Code)"
            className="mb-3"
            component={TextField}
            info={
              <span>
                This is the copyright statement for the audiobook, you need to
                add the copyright holder and year and the producer and
                production year. This is the correct format: © Rights Holder,
                Year ℗ Audio Producer, Year.
                <br /> E.g. © Robert Kee, 1984 ℗ Lume Books, 2020
              </span>
            }
          />

          <Field
            name="audience_type"
            label="Audience Type"
            component={SelectField}
            validate={[required]}
            className="mb-3"
            info={
              <span>
                What audience are you targeting with your title? <br />
                Please select the most appropriate type from the drop down menu.
              </span>
            }
            options={[
              { value: "", label: "-- Choose Audience Type --" },
              { value: "General/Trade", label: "General/Trade" },
              { value: "Adult education", label: "Adult education" },
              { value: "Children/Juvenile", label: "Children/Juvenile" },
              {
                value: "College/ higher education",
                label: "College/ higher education",
              },
              { value: "ELT/ ESL", label: "ELT/ ESL" },
              {
                value: "Elementary/high school",
                label: "Elementary/high school",
              },
              { value: "Young Adult", label: "Young Adult" },
            ]}
          />

          <Field
            name="rights_type"
            label="Rights Type"
            component={SelectField}
            validate={[required]}
            className="mb-3"
            info={
              <span>
                Select ‘For Sale with exclusive Rights in the specified
                countries/ territories’ if you are the rights holder. <br />
                Select ‘For Sale with non-exclusive Rights in the specified
                countries/ territories’ if you are publishing an out of
                copyright title.
              </span>
            }
            options={[
              { value: "", label: "-- Choose Rights Type --" },
              {
                value: "1",
                label:
                  "For Sale with exclusive Rights in the specified countries/ territories",
              },
              {
                value: "2",
                label:
                  "For Sale with non-exclusive Rights in the specified countries/ territories",
              },
            ]}
          />

          <Field
            name="territory"
            label="Territory"
            component={TextField}
            className="mb-3"
            info={
              <span>
                Please enter the territory your title can be sold in. <br />
                For example, if you have worldwide rights, enter ‘WORLD’, if you
                only have rights to sell in certain enter those territories.{" "}
                <br />
                E.g. World excluding United States & Canada or UK and
                Commonwealth.
              </span>
            }
          />

          <Field
            name="isbn13"
            label="ISBN"
            component={TextField}
            validate={[required]}
            className="mb-3"
            info={
              <span>
                This is the 13 digit ISBN assigned to the downloadable audio
                edition.
              </span>
            }
          />

          <Field
            name="publisher_audio_retail_price"
            label="Publisher Audio DD Retail Price"
            type="number"
            component={TextField}
            validate={[required]}
            className="mb-3"
            info={
              <span>
                This is the retail a la carte price of your audiobook. <br />
                Please enter the price in USD and exclusive of any sales taxes
                or VAT.
              </span>
            }
          />

          <Field
            name="publisher_audio_library_price"
            label="Publisher Audio DD Library Price"
            type="number"
            component={TextField}
            validate={[required]}
            className="mb-3"
            info={
              <span>
                This is the library a la carte price of your audiobook. <br />
                It is generally set at 2.5 or 3 times the a la carte retail
                price. <br />
                Please enter the price in USD and exclusive of any sales taxes
                or VAT.
              </span>
            }
          />
        </CardBody>
      </Card>

      <div className="mt-4 flex justify-end">
        <Button htmlType="submit" loading={loading}>
          Save Changes
        </Button>
      </div>
    </form>
  );
};

const ConnectedForm = reduxForm({ form: "distribution" })(Form);

export function Distribution({ params }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const [formLoading, setFormLoading] = useState(false);
  const [formError, setFormError] = useState(null);

  const reload = () => {
    setData(null);
    setLoading(true);
    setError(null);

    axios
      .get(`portal/projects/${params.id}/distribution/`)
      .then((res) => {
        setLoading(false);
        setData(res.data);
      })
      .catch((e) => {
        setError(e);
        setLoading(false);
      });
  };

  useEffect(reload, [params.id]);

  const dispatch = useDispatch();
  const triggerFormSubmission = () => dispatch(submit("distribution"));
  const handleSubmit = (values) => {
    setFormLoading(true);

    let formData = new FormData();
    Object.entries(values)
      .filter(([key, value]) => key !== "front_cover" || !!value)
      .forEach(([key, value]) =>
        formData.append(key, key === "front_cover" ? value[0] : value)
      );

    axios
      .post(`portal/projects/${params.id}/distribution/`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        setFormLoading(false);
        setData(res.data);
        message.success("Saved changes successfully.");
      })
      .catch((e) => {
        setFormError(e);
        message.error("An error occurred");
        setFormLoading(false);
      });
  };

  if (loading) return <Loading />;
  if (error) return <ApiError error={error} />;
  if (!data) return null;

  return (
    <div className="px-5 pb-4">
      <PageHeader
        title={
          <Link to={`/projects/${params.id}`} className="flex items-center">
            <Icon type="left" className="text-base mr-2" /> {data.project.title}
          </Link>
        }
        subTitle="Add/edit Metadata"
        extra={<Button onClick={triggerFormSubmission}>Save Changes</Button>}
      />

      <div className="sm:flex items-start">
        <Card className="sm:w-1/3">
          <CardBody>
            <h3 className="capitalize text-md pr-10 mb-3 leading-6 font-medium text-gray-900">
              Ready to upload the metadata (bibliographic information) for your
              audiobook?
            </h3>
            <p className="mb-2">
              You can use this page to add or update metadata and assets for
              your audiobook’s distribution. This metadata page can be accessed
              at any time before downloading the final files for your audiobook.
            </p>
            <p className="mb-2">
              Remember that everything you add here will be public so please
              double check all your entries and make sure there are no spelling
              or grammatical errors.
            </p>
            <p className="mb-2">
              Lastly, don’t forget to click{" "}
              <span className="text-black underline">'Save changes'</span> at
              the top or bottom right hand corner of the screen.
            </p>
          </CardBody>
        </Card>

        <div className="hidden sm:block w-2/3 ml-5">
          <ConnectedForm
            initialValues={
              data.distribution
                ? { ...data.distribution, front_cover: undefined }
                : {}
            }
            data={data.distribution}
            onSubmit={handleSubmit}
            loading={formLoading}
            apiError={formError}
          />
        </div>
      </div>
    </div>
  );
}

export default Distribution;
