import Create from "./Create";
import Payment3DCallback from "./Create/Payment3DCallback";
import Detail from "./Detail";
import Distribution from "./Distribution";

export default {
  path: "projects",
  childRoutes: [
    { path: "create", component: Create },
    { path: "3ds", component: Payment3DCallback },
    { path: ":id/distribution", component: Distribution },
    { path: ":id", component: Detail },
  ],
};
