import React, { useEffect, useState } from "react";
import Card, { CardBody } from "../../../../components/Card";
import Chapters from "../components/Chapters";
import Issues from "../components/Issues";

const CustomerProofingReview = ({ project, chapters, onSubmit }) => {
  const [issues, setIssues] = useState([]);
  const [activeChapterIndex, setActiveChapterIndex] = useState(0);

  useEffect(() => setIssues([...project.issues]), [project]);

  if (!chapters || chapters.length === 0) {
    return null;
  }

  return (
    <Card title="Corrections Review">
      <CardBody>
        You will receive an email notification asking you to approve the
        corrections you requested. If you are happy with the correction please
        click the approve button. If you are not happy with the correction
        please add a note with further instructions. Please note we may email
        you, or call you, to better understand your requirements.
      </CardBody>

      <Chapters
        project={project}
        chapters={chapters}
        activeChapterIndex={activeChapterIndex}
        setActiveChapterIndex={setActiveChapterIndex}
      >
        <Issues
          onSubmit={onSubmit}
          project={project}
          chapter={chapters[activeChapterIndex].chapter}
          issues={issues}
          setIssues={setIssues}
          isReview
        />
      </Chapters>
    </Card>
  );
};

export default CustomerProofingReview;
