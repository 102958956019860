import Start from "./Login/Start";
import Finish from "./Login/Finish";
import Logout from "./Logout";
import notLoggedIn from "../../containers/notLoggedIn";
import Token from "./Login/Token";

export default {
  path: "auth",
  childRoutes: [
    { path: "login/token", component: notLoggedIn(Token) },
    { path: "login/start", component: notLoggedIn(Start) },
    { path: "login/finish", component: notLoggedIn(Finish) },
    { path: "logout", component: Logout },
  ],
};
