import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Icon, Popover, Radio, Tooltip } from "antd";
import axios from "axios";
import { Keywords, Sample } from "./Help";
import classNames from "classnames";
import {
  alphabets,
  convertTranscription,
  parseTranscription,
} from "../../../../../services/lexicons";

const Match = ({ match, onChangeStressLevel }) => {
  const content = match.entry ? (
    <div style={{ maxWidth: 400 }}>
      <table className="w-full">
        <tr>
          <th className="w-1/2">DeepZen</th>
          <th className="w-1/2 text-right">IPA</th>
        </tr>
        <tr className="text-lg" style={{ fontFamily: "monospace" }}>
          <td>{match.entry.deepzen}</td>
          <td className="text-right">{match.entry.ipa}</td>
        </tr>
        {match.entry.keywords.length > 0 && (
          <>
            <tr>
              <th colSpan={2}>Keywords</th>
            </tr>
            <tr>
              <td colSpan={2}>
                <Keywords data={match.entry.keywords} />
              </td>
            </tr>
            <tr>
              <th colSpan={2}>Example</th>
            </tr>
            <tr>
              <td colSpan={2}>
                <Sample
                  phoneme={match.entry.deepzen}
                  data={match.entry.sample}
                />
              </td>
            </tr>
          </>
        )}
      </table>

      {match.entry?.type === "vowel" && (
        <div className="mt-4">
          <div className="font-bold">Stress:</div>

          <Radio.Group
            size="small"
            defaultValue={match.stressLevel ?? "no"}
            buttonStyle="solid"
            onChange={(e) => {
              onChangeStressLevel(
                e.target.value === "no" ? null : e.target.value
              );
            }}
          >
            <Radio.Button value="no">No stress</Radio.Button>
            <Radio.Button value="primary">Primary</Radio.Button>
            <Radio.Button value="secondary">Secondary</Radio.Button>
          </Radio.Group>
        </div>
      )}
    </div>
  ) : null;

  const visual = (
    <span
      style={{ fontFamily: "monospace" }}
      className={classNames(
        "mr-1 rounded inline-block py-0.5 px-1.5 font-bold cursor-pointer",
        match.valid
          ? match.stressLevel === "primary"
            ? "bg-green-300"
            : match.stressLevel === "secondary"
            ? "bg-blue-300"
            : "bg-gray-300"
          : "bg-red-300"
      )}
    >
      {match.phoneme}
    </span>
  );

  return match.valid ? (
    <Popover
      content={content}
      title={match.entry.type}
      trigger="hover"
      placement="bottom"
      zIndex={999999}
    >
      {visual}
    </Popover>
  ) : (
    visual
  );
};

const Matches = ({ data, onChangeStressLevel }) => {
  return (
    <div>
      {data.map((p, i) => (
        <Match
          key={i}
          match={p}
          onChangeStressLevel={(value) => onChangeStressLevel(i, value)}
        />
      ))}
    </div>
  );
};

const Form = ({
  value,
  onChange,
  matches,
  alphabet,
  onChangeAlphabet,
  language,
  isValid,
  validationErrors,
}) => {
  const [synthesizing, setSynthesizing] = useState(false);
  const [playing, setPlaying] = useState(false);
  const inputRef = useRef();

  const synthesize = useCallback(() => {
    const transcription = convertTranscription(
      language,
      alphabet,
      "combilex",
      matches
    );

    setSynthesizing(true);

    axios
      .post(
        "https://vm-api.deepzen.io/lexicon/synthesize/",
        { language, transcription, pos: "nnp" },
        {
          headers: {
            Authorization:
              "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjY5NzUzNzgwLCJqdGkiOiJlMjA1YWFkMmMwMTc0ZmZlODk5YjA3MTk2MzE4M2VlZiIsInVzZXJfaWQiOjgxfQ.17pFHk5rKUraYHZWdBm8GooTsIrJKI87pyWZBdY37nE",
          },
        }
      )
      .then((res) => {
        const player = new Audio();
        player.src = res.data.wav_url;
        player.onplay = () => setPlaying(true);
        player.onended = () => setPlaying(false);
        player.play();
      })
      .finally(() => setSynthesizing(false));
  }, [matches, language]);

  const handleStressLevelChange = useCallback((i, stressLevel) => {
    const newMatches = [...matches];
    newMatches[i].stressLevel = stressLevel;

    // Generate transcription with updated stress level
    const newTranscription = convertTranscription(
      language,
      alphabet,
      alphabet,
      newMatches
    );

    onChange(newTranscription);
  });

  return (
    <div className="bg-white rounded-lg relative">
      <div className="flex mt-1">
        {value.length > 0 && !isValid && (
          <Tooltip
            defaultVisible
            zIndex={99999}
            title={validationErrors.join(", ")}
          >
            <div className="cursor-pointer absolute right-2 top-1 text-blue-500 z-50">
              <Icon
                type="exclamation-circle"
                className="text-xl z-50 text-red-600"
              />
            </div>
          </Tooltip>
        )}

        {isValid && (
          <a
            onClick={synthesize}
            className="cursor-pointer absolute right-2 top-1 text-blue-500"
          >
            <Icon
              type={
                synthesizing
                  ? "loading"
                  : playing
                  ? "pause-circle"
                  : "play-circle"
              }
              spin={synthesizing}
              className="text-xl z-50"
            />
          </a>
        )}

        <Popover
          zIndex={99999}
          trigger="focus"
          content={
            <div className="">
              <div className="flex text-xs">
                <label className="flex items-center mr-4">
                  <input
                    ref={inputRef}
                    type="radio"
                    checked={alphabet === "deepzen"}
                    value="deepzen"
                    className="mr-1"
                    onChange={(e) => onChangeAlphabet(e.target.value)}
                  />
                  DeepZen
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    checked={alphabet === "ipa"}
                    value="ipa"
                    className="mr-1"
                    onChange={(e) => onChangeAlphabet(e.target.value)}
                  />
                  IPA
                </label>
              </div>

              {matches.length > 0 && (
                <div className="h-6 mt-4 flex items-center">
                  <Matches
                    data={matches}
                    onChangeStressLevel={handleStressLevelChange}
                  />
                </div>
              )}
            </div>
          }
          placement="bottomLeft"
        >
          <input
            value={value}
            onChange={(e) => onChange(e.target.value)}
            className="flex-grow border rounded px-2 py-0.5 text-base h-8"
            style={{ fontFamily: "monospace" }}
            placeholder="Pronunciation"
          />
        </Popover>
      </div>

      <div className="hidden">
        <label className="flex items-center">
          <input
            type="radio"
            checked={alphabet === "deepzen"}
            value="deepzen"
            className="mr-1"
            onChange={(e) => onChangeAlphabet(e.target.value)}
          />
          DeepZen
        </label>
        <label className="flex items-center">
          <input
            type="radio"
            checked={alphabet === "ipa"}
            value="ipa"
            className="mr-1"
            onChange={(e) => onChangeAlphabet(e.target.value)}
          />
          IPA
        </label>
      </div>
    </div>
  );
};

const TranscriptionInput = ({ value, onChange, language }) => {
  const [transcription, setTranscription] = useState("");
  const [matches, setMatches] = useState([]);
  const [alphabet, setAlphabet] = useState("deepzen"); // deepzen, ipa, xsampa, combilex

  const mounted = useRef(false);

  useEffect(() => {
    if (mounted.current || !value) {
      return;
    }

    handleTranscriptionChange(
      value
        ? convertTranscription(
            language,
            "combilex",
            "deepzen",
            parseTranscription(language, "combilex", value)
          )
        : ""
    );

    mounted.current = true;
  }, [value]);

  const validationErrors = useMemo(() => {
    let errors = [];

    const primaryStressCount = matches.filter(
      (m) => m.stressLevel === "primary"
    ).length;
    const consonantStress = matches.filter(
      (m) => m.stressLevel && m.entry?.type === "consonant"
    );
    const vowels = matches.filter((m) => m.entry?.type === "vowel");
    const schwaStress = vowels.filter(
      (m) => m.entry.optionalStress && m.stressLevel
    );

    const invalidEntries = matches.filter((m) => !m.valid);
    const alphabetConfig = alphabets[alphabet];
    const stressSymbolsAsInvalidEntry = invalidEntries.filter(
      (m) =>
        m.phoneme.trim() === alphabetConfig.stress.primary ||
        m.phoneme.trim() === alphabetConfig.stress.secondary
    );

    if (primaryStressCount > 1) {
      errors.push("There can't be more than one primary stress");
    } else if (stressSymbolsAsInvalidEntry.length > 0) {
      errors.push("The stress should be added to right of the stressed vowel.");
    } else if (invalidEntries.length > 0) {
      errors.push("Please fix invalid phonemes.");
    } else if (consonantStress.length > 0) {
      errors.push("Consonants can not have stress modifiers.");
    } else if (schwaStress.length > 0) {
      errors.push("Schwa (@) can not have stress modifiers.");
    } else if (vowels.length > 1 && primaryStressCount === 0) {
      errors.push("There must be one primary stress.");
    }

    // US-specific rhotic vowel rule
    if (
      language === "us" &&
      matches.filter((m, i) => {
        if (
          m.entry?.deepzen === "@" &&
          matches[i + 1]?.entry?.deepzen === "@" &&
          matches[i + 2]?.entry?.deepzen === "r"
        ) {
          return true;
        } else if (
          m.entry?.deepzen === "@" &&
          matches[i + 1]?.entry?.deepzen === "r"
        ) {
          return true;
        } else if (
          m.entry?.deepzen === "aw" &&
          matches[i + 1]?.entry?.deepzen === "r"
        ) {
          return true;
        } else {
          return false;
        }
      }).length > 0
    ) {
      errors.push(
        "This is not a rhotic vowel. Please add an r attached to the right of @ @@ aw."
      );
    }

    return errors;
  }, [matches]);

  const handleAlphabetChange = (newAlphabet) => {
    const newTranscription = convertTranscription(
      language,
      alphabet,
      newAlphabet,
      matches
    );
    const newMatches = parseTranscription(
      language,
      newAlphabet,
      newTranscription
    );

    setTranscription(newTranscription);
    setMatches(newMatches);
    setAlphabet(newAlphabet);
  };

  const handleTranscriptionChange = (value) => {
    const matches = parseTranscription(language, alphabet, value);

    setTranscription(value);
    setMatches(matches);
    emitChange(matches);
  };

  const emitChange = (matches) => {
    const updatedValue = convertTranscription(
      language,
      alphabet,
      "combilex",
      matches
    );

    onChange(matches?.length > 0 ? updatedValue : "");
  };

  return (
    <Form
      value={transcription}
      onChange={handleTranscriptionChange}
      language={language}
      alphabet={alphabet}
      onChangeAlphabet={handleAlphabetChange}
      matches={matches}
      isValid={transcription.length > 0 && validationErrors.length === 0}
      validationErrors={validationErrors}
    />
  );
};

export default TranscriptionInput;
