import React from "react";

const Share = ({ project }) => {
  // Can be shared on just a few steps
  if (
    [
      "customer_linguistics_input",
      "customer_proofing",
      "customer_proofing_review",
    ].indexOf(project.status) === -1
  ) {
    return null;
  }

  return (
    <div className="mt-1 sm:mt-0 sm:col-span-2">
      <div className="max-w-lg flex rounded-md shadow-sm">
        <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
          Share
        </span>
        <input
          type="text"
          readOnly
          onFocus={(e) => e.target.select()}
          className="form-input flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
          value={`${window.location.protocol}//${window.location.host}/guest/${project.id}/`}
        />
      </div>
    </div>
  );
};

export default Share;
