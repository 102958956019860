import React from "react";
import classNames from "classnames";

const CheckboxField = ({
  input,
  label,
  defaultValue,
  description,
  meta,
  className,
  size,
  ...otherProps
}) => (
  <label className="relative flex items-start">
    <div className="flex items-center h-5">
      <input
        type="checkbox"
        defaultChecked={defaultValue}
        className={classNames(
          "form-checkbox focus:ring-indigo-500 h-4 w-4 text-blue-600 border-gray-300 rounded",
          input.disabled && "cursor-not-allowed bg-gray-100",
          className
        )}
        {...input}
        {...otherProps}
      />
    </div>
    <div className="ml-1 text-sm">
      <label className="font-medium text-gray-500 mb-0.5 block">{label}</label>
      <p className="text-gray-500">{description}</p>
    </div>
  </label>
);

export default CheckboxField;
