import React, { useState } from "react";
import Button from "../../../../components/Button";
import Card, { CardBody } from "../../../../components/Card";
import { API_URL } from "../../../../config";
import Chapters from "../components/Chapters";
import { secondsToHms } from "../../../../services/time";

const BookDelivered = ({ project, chapters }) => {
  const [activeChapterIndex, setActiveChapterIndex] = useState(0);
  
  return (
    <Card
      title="Download Your Audiobook Files"
      headerActions={[
        <a
          target="_blank"
          href={API_URL + "/portal/projects/" + project.id + "/downloadzip/"}
        >
          <Button type="success">Download as ZIP</Button>
        </a>,
      ]}
    >
      <CardBody>
        <div className="flex justify-between">
          <span>Happy listening!</span>
          {project?.total_duration && (
            <span className="text-sm">
              Total duration: {secondsToHms(project.total_duration)}
            </span>
          )}
        </div>
        
      </CardBody>
      <Chapters
        project={project}
        chapters={chapters}
        activeChapterIndex={activeChapterIndex}
        setActiveChapterIndex={setActiveChapterIndex}
      />
    </Card>
  );
};

export default BookDelivered;
