import React, { useEffect, useState } from "react";
import { Table, TableRow, TableCell, TableBody } from "../../components/Table";
import RightArrow from "../../components/Icons/RightArrow";
import { Link, browserHistory } from "react-router";
import ApiError from "../../components/ApiError";
import Loading from "../../components/Loading";
import axios from "axios";
import moment from "moment";
import statuses from "../Projects/Detail/modules/statuses";
import Blank from "./Blank";
import Button from "../../components/Button";
import SearchBox from "../../components/SearchBox";
import SortBox from "../../components/SortBox";
import Pagination from "../../components/Pagination";
import Search from "../../components/Icons/Search";

const List = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [originalDataLength, setOriginalDataLength] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [sort, setSort] = useState({
    sort: "created_at",
    sortDir: "desc"
  });
  const [searchedValue, setSearchedValue] = useState("");

  const fetchData = async (_page, _pageSize, _searchedValue, _sort) => {
    try {
      setLoading(true);
      const response = await axios.get(`portal/projects`, {
        params: {
          page: _page,
          page_size: _pageSize,
          sort: _sort.sort,
          sortDir: _sort.sortDir,
          search: _searchedValue
        }
      });
      setData(response.data.results);
      setOriginalDataLength(response.data.count);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(e);
    }
  };

  useEffect(() => {
    fetchData(page, pageSize, searchedValue, sort);
  }, [page, pageSize, sort]);

  useEffect(() => {
    fetchData(1, pageSize, searchedValue, sort); // Arama yapıldığında sayfayı 1'e sıfırlıyoruz
  }, [searchedValue]);

  if (loading) return <Loading />;
  if (error) return <ApiError error={error} />;

  const handleSearchChange = (e) => {
    const newValue = e.target.value;
    setSearchedValue(newValue);
  };

  const handleSortChange = (newSort) => {
    setSort(newSort);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPage(1);
    setPageSize(newPageSize);
  };

  return (
      <>
        <div className="flex justify-end py-2">
          <SearchBox
              placeholder="Search by title"
              onChange={handleSearchChange}
              value={searchedValue}
          />
          <SortBox
              className="ml-1 mr-5"
              data={[
                { title: "Project title", field: "title", type: "text" },
                { title: "Created", field: "created_at", type: "date" }
              ]}
              defaultFieldIndex={1}
              defaultSort={"desc"}
              onChange={handleSortChange}
              sort={sort}
          />
        </div>

        {data.length === 0 ? (
            <div className="py-5">
              <div className="flex justify-center items-center">
                <Search size={10} />
              </div>
              <div className="flex justify-center items-center text-lg text-gray-900">
                No Result
              </div>
              <div className="flex justify-center items-center text-sm text-gray-500">
                Try changing the search term.
              </div>
            </div>
        ) : (
            <Table className="border-t border-gray-200">
              <TableBody>
                {data.map((project) => (
                    <TableRow
                        key={project.id}
                        className="cursor-pointer"
                        onClick={() => browserHistory.push(`/projects/${project.id}`)}
                    >
                      <TableCell valign="center" className="pt-3">
                        <div className="flex items-center">
                          <span className="text-base mr-3">{project.title}</span>
                          {project.needs_payment ? (
                              <span className="text-xs mt-0.5 bg-orange-100 text-orange-800 px-2 rounded-full">
                        Awaiting payment
                      </span>
                          ) : (
                              [
                                "customer_linguistics_input",
                                "customer_sample_review",
                                "customer_proofing",
                                "customer_proofing_review",
                              ].indexOf(project.status) !== -1 && (
                                  <span className="text-xs mt-0.5 bg-orange-100 text-orange-800 px-2 rounded-full">
                          Requires your attention
                        </span>
                              )
                          )}
                        </div>
                        <div className="text-xs text-gray-500">
                    <span>
                      Started on {moment(project.created_at).format("ll")}
                    </span>
                          <span className="mx-2">&bull;</span>
                          <span>
                      {
                        statuses[
                            project.status
                                .replace(/_post_review/g, "")
                                .replace(/_post/, "")
                            ]
                      }
                    </span>
                        </div>
                      </TableCell>
                      <TableCell className="text-right">
                        <RightArrow size={7} className="text-gray-500" />
                      </TableCell>
                    </TableRow>
                ))}
              </TableBody>
            </Table>
        )}

        {originalDataLength > 0 && data.length > 0 && (
            <Pagination
                page={page}
                pageSize={pageSize}
                totalSize={originalDataLength}
                onChangePage={(newPage) => handlePageChange(newPage.page)}
                onChangePageSize={(newPageSize) => handlePageSizeChange(newPageSize)}
            />
        )}

        <div className="hidden sm:flex justify-center p-5 bg-gray-100 border-t">
          <Link to="/projects/create">
            <Button type="info" size="large">
              Create New Project
            </Button>
          </Link>
        </div>
      </>
  );
};

export default List;