import React from "react";
import Button from "../../../components/Button";
import Modal, { ModalHeader } from "../../../components/Modal";

const CoverImageInfo = ({ show, onClose }) => {
  const handleClose = () => onClose(true);
  
  return (
    <>
      <Modal
        show={show}
        onClose={handleClose}
        contentClassName="bg-white m-8 w-3/4"
      >
        <ModalHeader onClose={handleClose}>
          Cover Image
        </ModalHeader>
        <div className="flex flex-col">
          <div
            className="p-4 border-b"
            style={{ maxHeight: 400, overflowX: "hidden", overflowY: "auto" }}
            itemScope
          >

          <h3 className="text-md pr-10 mb-2 leading-6 font-medium text-gray-900">
            Your cover image should meet the following specifications: 
          </h3>

          <ol className="list-disc ml-8 mb-2">
            <li>
                JPG file format
            </li>
            <li>
                Square art required (1:1 ratio), without use of borders or letterboxing
            </li>
            <li>
                Minimum 72 dpi resolution, 24-bit colour, and a minimum of 2400 x 2400 pixels (preferably 3000 x 3000px at 300 dpi)
            </li>
         </ol>
        </div>

          <div className="flex flex-row-reverse justify-between py-4">
            <div>
              <Button
                size="large"
                type="danger"
                className="mx-3"
                onClick={handleClose}
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CoverImageInfo;
