import React from "react";
import classNames from "classnames";

const generateSize = (s) => {
  const size = s ? s : 5;
  return { width: 4 * size, height: 4 * size };
};

const Base = (Comp, type = "fill") => ({ size, className }) => (
  <svg
    className={classNames(
      "inline",
      className)}
    style={generateSize(size)}  
    fill={type === "fill" ? "currentColor" : "none"}
    stroke={type === "stroke" ? "currentColor" : "none"}
    viewBox="0 0 20 20"
  >
    <Comp />
  </svg>
);

export default Base;
