import React, { useState } from "react";
import AudioPlayer from "../../components/AudioPlayer";
import Button from "../../../../components/Button";
import PlusIcon from "../../../../components/Icons/Plus";
import { secondsToHms } from "../../../../services/time";
import { Link } from "react-router";

export function ChapterDetailsPlayer({ deliveredChapter, onAddIssue }) {
  const blankIssue = (time) => ({
    chapter: deliveredChapter.chapter,
    time,
    comment: "",
  });

  const [paused, setPaused] = useState(true);

  const handlePauseAudio = (issueTime) => {
    if (paused) {
      onAddIssue(blankIssue(secondsToHms(issueTime)));
    }
  };

  return (
    <>
      <div className="flex bg-gray-100 py-2 px-4">
        <AudioPlayer
          className="w-5/6 flex"
          wavUrl={`${
            deliveredChapter.wav.startsWith("/")
              ? "https://studio-api.deepzen.io"
              : ""
          }${deliveredChapter.wav}`}
          autoPlay={false}
          pauseAudio={paused}
          onPlayAudio={() => setPaused(false)}
          onPauseAudio={handlePauseAudio}
        />

        {onAddIssue && (
          <div className="w-1/6 flex pl-1">
            <Button
              size="small"
              block
              className="rounded-full"
              onClick={() =>
                paused ? onAddIssue(blankIssue("")) : setPaused(true)
              }
              icon={PlusIcon}
            >
              Add correction
            </Button>
          </div>
        )}
      </div>
      <div>
        {onAddIssue && (
          <div className="text-center px-4 pb-4 pt-1">
            You can read about how to add corrections{" "}
            <Link to="help" className="text-blue-600 font-bold">
              here
            </Link>
            . You can share a secure link (see top right-hand corner) with a
            third-party to proof the audiobook. Simply copy and paste into an
            email or an instant messaging service.
          </div>
        )}
      </div>
    </>
  );
}

export default ChapterDetailsPlayer;
