import { message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ApiError from "../../components/ApiError";
import Button from "../../components/Button";
import Modal, { ModalHeader } from "../../components/Modal";
import PageHeader from "../../components/PageHeader";
import InviteForm from "./InviteForm";
import moment from "moment";

const Users = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [showInviteForm, setShowInviteForm] = useState(false);

  const reload = () => {
    setError(null);

    axios
      .get("portal/sub-users/")
      .then((res) => setData(res.data))
      .catch((e) => setError(e));
  };

  useEffect(() => {
    reload();
  }, []);

  const handleModalClose = () => setShowInviteForm(false);

  const handleSubmitInvite = (values) => {
    axios
      .post("portal/sub-users/", values)
      .then((res) => {
        message.success("Invitation has been sent successfully.");
        reload();
        setShowInviteForm(false);
      })
      .catch(() => message.error("An error occurred."));
  };

  const handleRemove = (id) => {
    if (window.confirm("Are you sure?")) {
      axios
        .delete(`portal/sub-users/${id}/`)
        .then(() => reload())
        .catch(() => message.error("An error occurred."));
    }
  };

  return (
    <div className="container pb-4">
      <Modal show={showInviteForm} onClose={handleModalClose}>
        <ModalHeader onClose={handleModalClose}>Invite</ModalHeader>
        <InviteForm onSubmit={handleSubmitInvite} />
      </Modal>

      <PageHeader
        title="User Admin"
        subTitle="Here you can create sub-accounts for team members and colleagues to access projects on the portal. They’ll receive an email inviting them to login."
        extra={[
          <Button onClick={() => setShowInviteForm(true)} type="info">
            Create new
          </Button>,
        ]}
      />

      <div className="shadow bg-white overflow-hidden border-b border-gray-200 sm:rounded-lg">
        {data && data.length === 0 && (
          <div className="p-12 text-center text-lg">
            Sub-accounts will appear here.
          </div>
        )}

        {error && <ApiError error={error} />}

        {data && data.length > 0 && (
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Email
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Invitation Date
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Status
                </th>
                <th scope="col" className="relative px-6 py-3">
                  <span className="sr-only">Actions</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((user, i) => (
                <tr
                  key={user.email}
                  className={i % 2 === 0 ? "bg-white" : "bg-gray-50"}
                >
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {user.email}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {moment(user.created_at).fromNow()}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {user.joined_at ? (
                      `Joined ${moment(user.joined_at).fromNow()}`
                    ) : (
                      <span className="rounded-full bg-orange-200 text-orange-800 px-4 py-1">
                        Invited
                      </span>
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    {/*
                      <button
                        onClick={() => handleRemove(user.id)}
                        className="text-indigo-600 hover:text-indigo-900"
                      >
                        Remove
                      </button>
                    */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default Users;
