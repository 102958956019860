import React, { Component } from "react";
import Card, { CardBody } from "../../../../components/Card";
import TranscriptionInput from "../components/TranscriptionInput";
import Button from "../../../../components/Button";
import { Popover, Switch, Icon, Popconfirm } from "antd";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableHeadCell,
  TableCell,
} from "../../../../components/Table";
import Recorder from "../../../Recorder";
import Help from "../components/TranscriptionInput/Help";

export class CustomerLinguisticsInputs extends Component {
  constructor(props) {
    super(props);

    const audioDetails = {
      url: null,
      blob: null,
      base64Data: null,
    };

    this.state = {
      words: this.props.project.lexicon_entries
        .map((word) => ({
          ...word,
          approved: false,
          record: false,
          audioDetails: {
            ...audioDetails,
            url: null,
            recording: word.recording,
          },
        }))
        .sort((a, b) => a.token.localeCompare(b.token)),
    };
  }

  closePopover = (i) => {
    let words = [...this.state.words];
    words[i].record = false;
    this.setState({ words: words });
  };

  handleVisibleChange = (visible, i) => {
    let words = [...this.state.words];
    words[i].record = visible;
    this.setState({ words: words });
  };

  handleIPA = (value, i) => {
    let words = [...this.state.words];
    words[i].ipa = value;
    this.setState({ words: words });
  };

  onSwitchChange = (e, i) => {
    let words = [...this.state.words];
    words[i].approved = e;
    this.setState({ words: words });
  };

  render() {
    const { project } = this.props;

    const recorderContent = (i, word) => (
      <Recorder
        defaultAudioURL={word.audioDetails.recording}
        forceRecord={!word.record}
        onAudioChange={(e, b) => onAudioChange(e, b, i)}
      />
    );

    const onAudioChange = (e, b, i) => {
      let words = [...this.state.words];
      words[i].audioDetails.recording = e;
      words[i].audioDetails.base64Data = b;
      this.setState({ words: words });
    };

    const popoverTitle = (i, text) => (
      <p>
        {text}
        <button onClick={() => this.closePopover(i)} className="float-right">
          <Icon type="close-circle-o" />
        </button>
      </p>
    );

    const allWordsApproved =
      this.state.words.filter((w) => !w.approved).length === 0;
    const handleApproveAllWords = (e) =>
      this.setState({
        words: this.state.words.map((w) => ({ ...w, approved: e })),
      });

    return (
      <div>
        <Card title="Pronunciation Guidance">
          <CardBody>
            We ask that you provide an audio pronunciation or a written
            transcription. You can enter these directly into the portal. You can
            share a secure link (see top right-hand corner) with a third-party
            to provide pronunciations. Simply copy and paste into an email or an
            instant messaging service.
            <br />
            <br />
            {project.edit_mode === "managed"
              ? "Below are two options to provide pronunciation guidance: written transcriptions using IPA or audio recordings of the correct pronunciations. We strongly encourage you to use audio recordings where possible to achieve the best pronunciation results in your finished audiobook. We cannot guarantee correct pronunciation for any words left blank."
              : "We cannot guarantee correct pronunciation for any words left blank."}
          </CardBody>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeadCell>Word</TableHeadCell>
                <TableHeadCell>Transcription</TableHeadCell>
                {project.edit_mode === "managed" && (
                  <TableHeadCell className="text-center">Record</TableHeadCell>
                )}
                <TableHeadCell className="text-right">
                  Approve{" "}
                  <Switch
                    size="small"
                    onChange={handleApproveAllWords}
                    checked={allWordsApproved}
                  />
                </TableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.words &&
                this.state.words.map((word, i) => (
                  <TableRow key={word.id}>
                    <TableCell>{word.token}</TableCell>
                    <TableCell>
                      <TranscriptionInput
                        value={word.ipa}
                        onChange={(e) => this.handleIPA(e, i)}
                        language="uk"
                      />
                    </TableCell>
                    {project.edit_mode === "managed" && (
                      <TableCell className="flex justify-center">
                        <Popover
                          content={recorderContent(i, word)}
                          title={popoverTitle(i, word.text)}
                          trigger="click"
                          visible={word.record}
                          onVisibleChange={(d) =>
                            this.handleVisibleChange(d, i)
                          }
                        >
                          {word.audioDetails.recording ? (
                            <Button type="info">Recorded</Button>
                          ) : (
                            <Button className="px-5">Record</Button>
                          )}
                        </Popover>
                      </TableCell>
                    )}
                    <TableCell className="text-right w-10">
                      <Switch
                        size="small"
                        onChange={(e) => this.onSwitchChange(e, i)}
                        checked={word.approved}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <CardBody>
            <Popconfirm
              title="Confirm submission"
              onConfirm={() => this.props.onSubmit(this.state.words)}
            >
              <Button
                block
                type="primary"
                size="large"
                disabled={
                  this.state.words.filter((a) => !a.approved).length !== 0
                }
              >
                Submit All
              </Button>
            </Popconfirm>

            <div className="mt-4 text-center">
              <a
                onClick={() => this.props.onSave(this.state.words)}
                className="text-blue-500 font-bold"
              >
                Save Progress
              </a>
            </div>
          </CardBody>
        </Card>

        <Help language={"uk"} />
      </div>
    );
  }
}

export default CustomerLinguisticsInputs;
