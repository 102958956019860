import React from "react";
import Button from "../../../components/Button";
import Modal, { ModalHeader } from "../../../components/Modal";
import distributionContractContents from "./distributionContractContents";

const Contract = ({ show, onComplete }) => {
  const handleAccept = () => onComplete(true);
  const handleDecline = () => onComplete(false);

  return (
    <>
      <Modal
        show={show}
        onClose={handleDecline}
        contentClassName="bg-white m-8 w-3/4"
      >
        <ModalHeader onClose={handleDecline}>
          Distribution Terms and Conditions
        </ModalHeader>
        <div className="flex flex-col">
          <div
            className="p-4 border-b"
            style={{ maxHeight: 400, overflowX: "hidden", overflowY: "auto" }}
            itemScope
          >
            {distributionContractContents}
          </div>

          <div className="flex flex-row-reverse justify-between py-4">
            <div>
              <Button
                size="large"
                type="success"
                className="mx-3"
                onClick={handleAccept}
              >
                I accept
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Contract;
