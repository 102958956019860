import React, { useState } from "react";
import Card, { CardBody } from "../../components/Card";
import PageHeader from "../../components/PageHeader";
import Alphabet from "./Alphabet";
import Contract from "../Projects/Create/DistributionContract";
import DistributionInfo from "../Projects/Create/DistributionInfo";
import TrainingVideos, { TRAINING_TYPE_ALL } from "../../components/TrainingVideos";
import SignupLink from "../../components/SignupLink";
import { existAccessToken } from "../Auth/modules/auth";

const faqAccount = [
  {
    title:
      "What do I need to do to register for an account and start my first audiobook project?",
    content: (onClickDistributionInfo) => {
    return (
      <ol className="list-decimal ml-8">
        <li>
          Enter your business contact details. Please make sure you have the
          following information to hand:
          <ul className="list-disc list-inside">
            <li>Company Name</li>
            <li>Your registered and postal business address</li>
            <li>Company Registration Number (if applicable)</li>
            <li>Company Tax ID (if applicable)</li>
            <li>VAT Number (if applicable)</li>
            <li>Your name, email address* and contact telephone number. <br/> <span className="ml-5"> *Please note you cannot use a generic email address such as info@.</span> </li>
          </ul>
        </li>
        <li>
          Review our pricing and estimate the cost of your first audiobook
          project using our handy estimator tool.
        </li>
        <li>Listen to our available voices and select the one that best suits your project.</li>
        <li>Review and accept our terms of service. </li>
      </ol>
    );
   }
  },
  {
    title: "What do I need to get a quote?",
    content: (onClickDistributionInfo) => {
    return (
      <>
          <p className="mb-2">
            If you are an author or publisher with less than 500 hours of audio
            (50 audiobooks) to produce in a year, simply enter the word count of
            your manuscript into the quotation tool here (link to tool -
            https://portal.deepzen.io/pricing) for an approximate cost. DeepZen
            will confirm the quote once you have uploaded your manuscript to the
            portal and removed extraneous content.
          </p>
          <p className="mb-2">
            If you are an author or publisher with more than 500 hours of audio
            (50 plus audiobooks) to <u>produce</u> please email our sales team at{" "}
            <a href="mailto:hello@deepzen.io" className="font-bold underline">hello@deepzen.io</a> for a
            personalised quote.{" "}
          </p>
          <p className="mb-2">
            You will need to select your preferred service option before you get your quote.
          </p>  
          <p className="mb-2">We offer two service options: </p>
          <p className="font-medium leading-7 text-gray-900 text-lg">Managed Service</p>
          <p>
            The managed service is more suitable for fiction. This is an <u>end to end</u> service 
            which includes a pronunciation check, proofing review by DZ editors, customer quality review 
            and 2 corrections stages, post-processing and mastering. The turn-around for this service
            is 1 to 2 weeks. The cost per finished hour is $129.00/ £99.00.
          </p> 
          <p className="font-medium leading-7 text-gray-900 text-lg mt-2">Automated Service</p>
          <p>
            The automated service is more appropriate for non-fiction genres. 
            After you’ve uploaded your ePub, we provide a full pronunciation check, 
            make any necessary pronunciation <u>corrections</u> and then convert your ePub into audio. 
            We post-process and master your audiobook to industry standard specifications so the title 
            is ready for distribution. The cost is $69.00/ £49.00 per finished <u>hour</u> and we deliver files
            within 3 to 5 days. 
          </p>
          <p className="mt-2">
            You’ll receive a price for your audio project which we calculate based on the number of words 
            in your manuscript.
          </p>
          <p className="mt-2">
            Don’t forget to add our distribution set up service to your project. 
            You can find out more about the service <a className="underline font-bold text-blue-500" onClick={(e) => onClickDistributionInfo(true)}>here</a>. 
            We charge an initial fee of $69/£49, then a yearly fee of $25/ £19.99 thereafter. 
          </p>
          <p className="mt-2">
            To confirm the project, the balance is payable in advance by credit card or by bank transfer.
          </p>
          <p className="mt-2">
            We’ll start production once the payment has been received. <u>Generally</u> we deliver books within 
            a 5 day period for the automated service and 2 weeks for the managed service subject to you 
            providing any information requested within the specified timelines given. 
          </p>
      </>    
    )
    },
  },
  {
    title: "How do you calculate the cost of an audiobook project?",
    content: (onClickDistributionInfo) => {
    return (
      <p>
        The way we work out the cost of an audiobook is to divide the word count of your manuscript by 10,000. 
        This gives us an estimated duration (or runtime) which we then multiply by the per finished hour rate. 
      </p>
    );
    }
  },
  {
    title:
      "What information do I need to upload to the Customer Portal when I create a project?",
    content: (onClickDistributionInfo) => {
      return (
      <ol className="list-decimal ml-8">
        <li>
          The manuscript in ePub format. If your manuscript is in a different
          format, please email us at <a href="mailto:hello@deepzen.io" className="font-bold underline">hello@deepzen.io</a> to discuss if its suitable
          for conversion
        </li>
        <li>
          The ISBN you’ll be allocating to your audiobook project  
        </li>
        <li>The primary genre of the title </li>
        <li>
          The total word count of the manuscript. If you don’t have this to hand
          you can use an online word count tool like {" "}
          <a href="https://countwordsfree.com/" target="_blank" className="font-bold underline">https://countwordsfree.com/</a>, which also accepts ePub files
        </li>
        <li>
          The opening and closing credits we should include in your audiobook{" "}
        </li>
        <li>
          The name of the voice you have selected from our voice library for the
          project
        </li>
      </ol>
    );
    }
  },
  {
    title: "What format should my manuscript be in? ",
    content: (onClickDistributionInfo) => {
    return (
      <p>
        All manuscripts should be uploaded as an ePub file, free from spelling and grammatical errors. 
        If your manuscript is in a different format, please email us at {" "}
        <a href="mailto:hello@deepzen.io" className="font-bold underline">hello@deepzen.io</a> to discuss if its suitable for conversion.
      </p>
    );
  }
  },
  {
    title: "What if my ePub file includes images, captions or text boxes?",
    content: (onClickDistributionInfo) => {
    return (
      <p>
        Images will automatically be removed. Our ePub tool then gives you the option to edit your manuscript and to remove extraneous content. Extraneous content can include captions, quotes, footnotes. Essentially, you should remove any text that you do not wish to include in the audiobook. 
      </p>  
    );  
    }
  },
  {
    title: "What if my ePub includes bullet points or symbols?",
    content: (onClickDistributionInfo) => {
      return (
        <p>
          These will be automatically removed when the manuscript is converted into audio.
        </p>
      );
    }
  },
  {
    title: "What if my ePub includes graphs and tables? ",
    content: (onClickDistributionInfo) => {
      return (
        <p>
          These will need to be converted into text, either before you upload your manuscript or using the ePub tool, which allows you to edit specific parts of text. 
        </p>
      )
    }
  },
  {
    title:
      "What should I do if I need to make a correction to the manuscript after I’ve uploaded and confirmed it?",
    content: (onClickDistributionInfo) => {
      return (
        <p>
          We are happy to change a single word or sentence during the editorial process but are unable 
          to make substantial edits once you have submitted your manuscript. 
          Please contact <a href="mailto:hello@deepzen.io" className="font-bold underline">hello@deepzen.io</a> for any amendments.
        </p>
    );
    }
  },
  
];

const faqProject = [
  {
    title: (
      <p>What should I include in Opening and Closing Credits? </p>
    ),
    content: (
      <div>
        We recommend including the following information:{" "}
        <ul className="list-disc ml-8">
          <li>
            <div>Opening credits:</div>
            [PUBLISHER] presents [TITLE] by [AUTHOR]. Digitally narrated using
            the voice of [NARRATOR].
            <br />
            <br />
          </li>
          <li>
            <div>Closing credits:</div>
            This recording of [TITLE] by [AUTHOR], was presented by [PUBLISHER] and produced by DeepZen Limited, [YEAR].
            <br />
            <br />
          </li>
          <li>
          <div>Copyright holder:</div>
            Sound recording copyright [YEAR] and produced by DeepZen Limited, [YEAR]
          </li>
        </ul>
      </div>
    ),
  },
  {
    title: "What happens after I’ve uploaded the manuscript?",
    content: (
      <div>
        <ol className="list-disc ml-8">
          <li>
            The cost of producing your audiobook will be confirmed and the
            balance is payable in advance by credit card or by bank transfer. In
            either case, you’ll be emailed an invoice.
          </li>
          <br/>
          <li>
            Once payment has been received, we’ll then review the manuscript and
            request pronunciation guidance on any unfamiliar words. You’ll be
            notified by email if we require any pronunciation guidance. Simply
            login to the portal to provide either a recording or a phonetic
            transcription of the requested words. The email will include a
            secure link that you can share with a colleague or third party to
            provide the pronunciation guidance. This link will give them
            restricted access to the pronunciation page only.
          </li>
          <br/>
          <li>
            Once you’ve provided pronunciation guidance, you’ll be notified by email about the next step. 
            <p className="my-2">
              For the automated service, you’ll be notified by email when your title is ready for download. 
            </p>
            <p className="mb-2">
              For the managed service, you’ll be notified when it’s ready for your corrections review. 
              Simply login to the portal to listen to the audio and annotate the text with any required 
              changes. The email will include a secure link that you can share with a colleague or 
              third party to proof the audiobook. This link will give them limited access to proofing page 
              for the title only. Our team will make the requested corrections and provide feedback on 
              any issues. This stage takes 1 to 2 working days. 
            </p>
          </li>
          <br/>
          <li>
            Once you’ve approved corrections, we’ll post-process the title and you’ll be notified by email 
            when your mastered and formatted audiobook files are available to download from the portal. 
            If you’ve a partner we’ll also deliver directly to them.  
            This stage generally takes 1 to 2 working days. 
          </li>
        </ol>
      </div>
    ),
  },
  {
    title: "What are our payment terms?",
    content:
      "DeepZen operates a payment in advance policy for all new customers. You will be asked to select a payment method as part of creating a new audiobook project. You have the option to pay immediately by credit card or you can request an invoice and pay by bank transfer. We’ll issue an invoice, which will be sent to you on receipt by email.",
  },
  {
    title: "How long will my audiobook take to produce? ",
    content: (
        <p>
          This depends on several factors including how quickly you make your payment, 
          which service option you select, how quickly you provide pronunciation guidance, 
          proofing reports, etc. Generally, we aim to complete an automated project in 5 days 
          and a managed project in 2 weeks as long as you provide any information we request 
          within the specified timelines. 
        </p>
    ),
  },
  {
    title:
      "How can I be sure all proper nouns or fictional names will be pronounced correctly in the audio? ",
    content: (
      <>
        <p className="mb-2">
          Once you have uploaded your manuscript, we run the entire manuscript through a tool that predicts 
          the correct pronunciation of words. Sometimes, we’ll have a number of words that require your input. 
          We will provide you with a list of words that our team requires guidance on within the portal. 
          This will usually include unfamiliar proper names, foreign or fictional words, jargon, medical
          terminology or acronyms. 
        </p>
        <p>
          You will then have the option to provide an audio recording of the word or a transcription 
          in the form of the International Phonetic Alphabet (IPA). 
        </p>
      </>  
    ),
  },
  {
    title:
      "What is the International Phonetic Alphabet (IPA) and where can I find it? ",
    content: (
      <p>
        The IPA is an alphabetic system of phonetic notation to demonstrate how a word should be pronounced. 
        You can usually find it in the dictionary listing for a word or on Wikipedia. 
        You can find the IPA at the end of this section. 
      </p>
    ),
  },
  {
    title: "Why do you ask for pronunciation guidance? ",
    content: (
      <>
      <p className="mb-2">
        Our text-to-speech system predicts the pronunciation for any word in the
        text you provide. However, much like a speaker sounding out a new or
        unknown word, our system may incorrectly predict the pronunciations of
        less common words. In order to ensure that all words are pronounced
        correctly, we ask that you provide{" "}
        <strong>written transcriptions and/or audio pronunciations</strong> for
        specific words.
      </p>
      <p className="mb-2">
        Types of words we may ask for pronunciations of: 
      </p>
      <ul className="list-disc ml-8">
        <li>Proper nouns: </li>
        <ul className="list-inside list-disc">
          <li>
            Pronunciation of character names and any subject matters frequently
            referred to in the text.
          </li>
          <li> Pronunciation of place names. </li>
          <li>Pronunciation of the author’s full name.</li>
        </ul>
        <li>Words from other languages: </li>
        <ul className="list-inside list-disc">
          <li>
            Pronunciations of any non-English (foreign and fictional) words in
            the text.{" "}
          </li>
          <li>
            Our system generates the pronunciation based on the accent of the
            voice being used. Therefore, foreign words will often be said with
            an English (US or UK) accent.
          </li>
        </ul>
        <li>Less common words: </li>
        <ul className="list-inside list-disc">
          <li>
            Pronunciations of any jargon or domain-specific words/phrases. This
            includes law terminology, medical terminology, acronyms, etc.
          </li>
        </ul>
        <li>Words with multiple correct pronunciations:</li>
        <ul className="list-inside list-disc">
          <li>
            {" "}
            If you have a preference for the pronunciation of words with
            multiple correct pronunciations e.g. “Caribbean” or “Beijing” please
            include these.{" "}
          </li>
        </ul>
        <li>
          Please note, if we do not receive pronunciation guidance for a word
          with multiple pronunciations or otherwise, we will not be able to
          amend this after editing is complete.
        </li>
      </ul>
      </>
    ),
  },
  {
    title: "How Do I Provide Pronunciations?",
    content: (
      <>
        In the Customer Portal you are given two options; to provide written
        transcriptions using the IPA and to record the correct pronunciations.
        <div className="mt-4">
          <strong>Audio Recordings:</strong>
          <div className="underline">
            The simplest way to provide pronunciations is by using the voice
            recording tool at the Pronunciation Guidance stage in the Customer
            Portal.
          </div>
        </div>
        <div className="mt-4">
          All audio recordings must be made in a quiet room that is free of
          background noise.
          <ul className="list-disc mt-4 ml-8">
            <li>
              Please do not record audio files outside or anywhere where others
              are speaking.{" "}
            </li>
            <li className="font-medium">
              <p className="font-bold">Please record each word 3 times:</p>
              <ul className="list-inside list-disc">
                <li>
                  First, say the word very slowly (~50% of your normal speed).
                  Break the word into syllables and say each syllable slowly and
                  distinctly.{" "}
                </li>
                <li>
                  Second, say the word slowly (~75% of your normal speed).
                  Ensure that you are clearly enunciating each sound and
                  syllable.
                </li>
                <li>
                  Third, say the word as you would naturally, with natural speed
                  and emphasis.
                </li>
                <li>
                  Please make sure your pronunciation is consistent across all
                  three recordings.{" "}
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="mt-4">
          <strong>Written Transcriptions:</strong>
          <div>
            <u>
              Written transcriptions can be provided to supplement voice
              recordings, or in the case where recordings cannot be provided.
            </u>{" "}
            We recommend you only use this option if you are familiar with the
            International Phonetic Alphabet (IPA). For guidance with writing
            phonetic transcriptions using IPA, please refer to the International
            Phonetic Alphabet table below.
          </div>
          <div className="mt-4">
            Please only provide a single transcription per word—do not include
            transcriptions with optional phones.
          </div>
          <div className="mt-4">Please note:</div>
          <ul className="list-disc mt-4 ml-8">
            <li>Each symbol must correspond to a single sound.</li>
            <li>
              Syllable boundaries are accurately marked in the transcription.
              Please use dashes ‘-’ to indicate syllable structure.
            </li>
            <li>
              Both primary and secondary stress are indicated in transcriptions
              as appropriate.
              <ul className="mt-1 list-inside list-disc">
                <li>
                  Every word must include one primarily stressed syllable.
                </li>
                <li>
                  Words may have zero or more syllables with secondary stress.
                </li>
                <li>Please do not use capitalization to indicate stress. </li>
                <li>
                  Please use ‘1’ to indicate primary stress and ‘2’ to indicate
                  secondary stress. These should be included at the end of the
                  syllable they are referring to.
                </li>
              </ul>
            </li>
            <li>
              {" "}
              “Clarification” (UK pronunciation) would be transcribed as:{" "}
              <strong>IPA Symbols: /kla2-rɪ-fɪ-keɪ1-ʃən</strong>
            </li>
          </ul>
          <div className="mt-4">
            Please note, our editorial review will not begin until DeepZen
            receives clarification on all pronunciation queries.
          </div>
        </div>
      </>
    ),
  },
  {
    title: "International Phonetic Alphabet Table",
    content: <Alphabet />,
  },
  {
    title: "What sort of proofing corrections can I make in the managed service? ",
    content: (
      <div>
        <div className="mb-4">
          There are two main types of corrections you may wish to make:{" "}
        </div>
        <ul className="list-disc ml-4">
          <li>
            Technical errors which include: 
            <ul className="ml-6 list-inside list-disc mb-4">
                <li>Extraneous noise; </li>
                <li>Repeated audio/ missing/omitted audio; </li>
                <li>Misspellings;</li>
                <li>Pauses; </li>
            </ul>
          </li>
        </ul>
        <ul className="list-disc ml-4">
          <li>
            Performance errors which include:
            <ul className="ml-6 list-inside list-disc mb-4">
                <li>Incorrect words (misread); </li>
                <li>
                    Pronunciation issues (mispronunciation, wrong pronunciation for the
                    dialect);{" "}
                </li>
                <li>Awkward or Unclear pronunciations (slur, stumble); </li>
                <li>Expression issues (questions, emotions); </li>
            </ul>
          </li>
        </ul>
      </div>
    ),
  },
];

const faqReady = [
    {
    title: "How will I receive my audiobook files?",
    content: (onClickDistributionContract) => {
      return (
        <p>
          You will be able to download all of your files from the portal as a Zip file.
        </p>
      );
    },
  },
  {
    title: "What audio specifications does DeepZen adhere to? ",
    content: (onClickDistributionContract) => { 
    return (
      <div>
        DeepZen delivers fully mastered audio files to industry standard 
        specifications, unless you specify otherwise. 
        <div className="mt-4 mb-2">These are:</div>
        <ul className="ml-8 list-disc">
          <li>
            DeepZen will deliver the Audio Files in MP3 format (192 kbps or higher) 
            or WAV files (44.1 kHz and 16 bit mono or stereo) 
          </li>
          <li>
            The Audio Files will be delivered in chapters and each individual chapter 
            will be named by a numerical identifier (ISBN) provided by the Customer. 
          </li>
          <li>
          	Chapters, credits, and any introductions will be delivered as standalone files 
            no longer than 120 minutes each. If the book has no chapters, 
            it will be split into segments of 30-120 minutes each 
          </li>
          <li>
            Audio files will be supplied in the proper order, and include a three-digit number
            that denotes the order in which the files should appear 
            (example: ISBN_001.mp3, ISBN_002.mp3, ISBN_003.mp3, etc.)
          </li>
          <li>
            Each chapter will start with a verbal indication that it is a new chapter 
            (such as the chapter number or the name of the chapter)
          </li>
          <li>
            Opening credits will include only the title and author/narrator names.
          </li>
          <li>
            All additional introductions (preface, author's notes) will be in a single, separate file. 
          </li>
          <li>
            Closing credits will be included and will reference the completion of the book (e.g. “The End”)
          </li>
          <li>
            Each individual file will contain between 0.5 and 1 second of silence (room tone) 
            at the beginning of file, before narration performance begins
          </li>
          <li>
            Each individual file will contain between 1 and 5 seconds of silence (room tone) 
            at the end of file, after narration completes
          </li>
          <li>
            RMS for each file will measure between -23dB and -18dB, with peak values below -3dB, 
            and a maximum -60dB floor
          </li>
          <li>
            No copyrighted materials or extraneous sounds (pages turning, etc.) will be included 
          </li>
        </ul>
      </div>
    );
    }
  },
  {
    title: "How will my files be named (file naming convention)? ",
    content: (onClickDistributionContract) => { 
    return (
      <>
        <div className="mb-4">
          Your files will be delivered in chapters and each individual chapter
          will be named by a numerical identifier (ISBN) provided by you at
          manuscript upload stage.
        </div>
        Audio files will be supplied in the correct order and include a
        three-digit number that denotes the order in which the files should
        appear e.g. ISBN_001, ISBN_002.
      </>
    );
    }
  },
  {
    title:
      "Do I need to identify the use of a digital voice in the metadata for my book?",
    content: (onClickDistributionContract) => { 
    return (
      <div>
        <div className="mb-4">The choice is yours.</div>
        <div className="mb-4">
          However, we believe it is best practice to be transparent about the
          fact that the audiobooks we produce are narrated using a digital
          voice.
        </div>
        <div className="mb-4">
          There are some limitations with identifying AI or synthesized voices
          in Onix. As DeepZen is the first organisation to be doing this we’ve
          attempted to address these limitations by adding the information is
          several ways.
        </div>
        <div className="mb-4">
          DeepZen recommends including the following in metadata when a digital
          voice replica has been used for narration:
        </div>
        <div className="mb-4">
          Contributor Field: Narrator – we use a pseudonym for all of our
          narrators, with the exception of Edward Herrmann who must be credited
          for any works where his voice is used.
        </div>
        <div className="mb-4">
          To make metadata compliant we recommend the following:
        </div>
        <div className="mb-4">
          In the description field for the narrator we add either Synthesized
          Voice – Male or Synthesized Voice – Female
        </div>
        <div className="mb-4">
          We add a third contributor label: Synthesized Voice. This is a
          specific requirement for AppleBooks.
        </div>
        <div className="mb-4">
          In the Contributor Statement field we add the following text: [Book
          title] is digitally narrated using the voice of [narrator name].
        </div>
        <div className="mb-1">
          In the Main Description or Publisher’s Notice fields we add the
          following statement: 
        </div>
        <div className="mb-4 italic">
          'This recording is presented by [Publisher] and
          has been digitally produced by DeepZen Limited, using a synthesized
          version of an audiobook narrator’s voice under license. DeepZen uses
          Emotive Speech Technology to create digital narrations that offer a
          similar listening experience to human narration.'
        </div>
        <div className="mb-4">
          We include the following in the audio introduction and credits:
        </div>
        <ul className="ml-8 list-disc">
          <li>
            Opening credits:
          </li>
        </ul>
        <div className="mb-4 ml-6 italic">
          '[Publisher]presents [Title by Author], digitally narrated using the
          voice of [Narrator’s pseudonym].'
        </div>
        <ul className="ml-8 list-disc">
          <li>
            Closing credits:
          </li>
        </ul>

        <div className="mb-4 ml-6 italic">
          'This recording of [Book by Author], was presented by
          [publisher] and produced by DeepZen Limited. Sound recording copyright
          xxxxx and Produced by DeepZen Limited 2020.'
        </div>
        <div className="mb-4">
          Most resellers have approved our content on this basis.
        </div>
        <div className="mb-4">
          We hope these suggestions are helpful and please do contact {" "} 
          <a href="mailto:hello@deepzen.io" className="font-bold underline">hello@deepzen.io</a> {" "}
          if you have any questions.   
        </div>
        
      </div>
    );
    }
  },
  {
    title: "Does Onix have a contributor code for digital voices? ",
    content: (onClickDistributionContract) => { 
    return (
      <>
        <div className="mb-4">Yes, they are on List 19</div>
        05 – Synthesized Voice Female
        <br />
        06 - Synthesized Voice Male
        <br />
        07 - Synthesized Voice Unspecified
        <br />
        <div className="mt-4">To be used with contributor code E07.</div>
      </>
    );
    }
  },
  
  {
    title: "How do I identify the use of a digital voice in Onix metadata? ",
    content: (onClickDistributionContract) => { 
    return (
      <>
        DeepZen recommends including the following:
        <ul className="ml-8 list-disc">
          <li>
            Contributor Field - The <span className="underline">narrator</span> name as 
            indicated in the DeepZen library.{" "}
          </li>
          <li>
            Description field - Add either “Synthesized Voice – Male” or
            “Synthesized Voice – Female”
          </li>
          <li>
            We add a third contributor label - “Synthesized Voice”. 
            This is a specific requirement for AppleBooks. 
          </li>
          <li>
            Contributor Statement - Add the following text: [Book title] is
            digitally narrated using the voice of [narrator name].
          </li>
          <li>
            Main Description or Publisher’s Notice fields add the following
            statement: <br/>This recording is presented by [Publisher] and has been
            digitally produced by DeepZen Limited, using a synthesized version
            of an audiobook narrator’s voice under license. DeepZen uses{" "}
            <em>Emotive Speech Technology</em> to create digital narrations that
            offer a similar listening experience to human narration.{" "}
          </li>
        </ul>
      </>
    );
    }
  },
  {
    title: "Do you offer a distribution service? ",
    content: (onClickDistributionContract) => { 
    return (
        <>
          <p className="mb-4">
            Yes, DeepZen can help you to set up global distribution to the majority 
            of vendors and digital service providers globally. 
            We have relationships with trusted partners that enable you to sell 
            your audiobooks on more than 50 channels globally. 
            These include Apple Books, Spotify, Deezer, Google Play, 
            Kobo, Kobo, Scribd, Storytel and Bibliotheca.  
          </p>

          <p className="mb-4">
            We charge an initial fee of $69/ £49 for set up, 
            and a yearly fee of $24.99/ £19.99. Most importantly, 
            you retain 100% of net receipts. We manage delivery of assets and metadata 
            to distributors, and manage all reporting and payments to you twice yearly. 
            For full terms and conditions, 
            click <a className="underline font-bold text-blue-500" onClick={(e) => onClickDistributionContract(true)}>here</a>.
          </p>

          <p className="mb-4">
            Don’t forget to check the distribution setup box when you’re selecting your
            preferred service option in the project setup page. We can currently only enable 
            distribution setup at this stage of the process. 
          </p>
        </>
    );
    }
  },
  {
    title: "Why should I setup distribution with DeepZen? ",
    content: (onClickDistributionContract) => { 
    return (
        <>
          <ul className="list-disc ml-8">
            <li>
              <span className="font-bold">Ease</span>
              <br/>
              Setting up distribution can be time consuming and, with so many different 
              business models emerging, complex. With DeepZen, simply upload your metadata 
              and cover image in the portal and we’ll do the rest when your audiobook is 
              ready for distribution. 
            </li>
            <br/>
            <li>
              <span className="font-bold">Transparency</span>
              <br/>
              We’ll provide you with regular sales reports that detail sales, 
              territory, vendor name and type, business model, 
              distributor fees and your share of revenue. 
            </li>
            <br/>
            <li>
              <span className="font-bold">Best Practice </span>
              <br/>
              DeepZen’s audiobooks are quality approved on over 50 vendors. 
              We have developed best practice guidance for identifying a digital 
              voice in metadata to ensure your audiobooks are accepted by 
              the majority of global vendors. 
            </li>
          </ul>
        </>
    );
    }
  },
  {
    title: "How do I add my metadata (bibliographic information)?",
    content: (onClickDistributionContract) => { 
    return (
        <>
          <p className="mb-4">
            You can use the metadata page to add or update metadata and assets for 
            your audiobook’s distribution. This metadata page can be accessed at any 
            time before downloading the final files for your audiobook. 
          </p>
          <p className="mb-4">
            Please follow these steps: 
          </p>
          <ul className="list-disc ml-8">
            <li>
              Select the project you’d like to add metadata for in your portal {" "}
              <span className="underline">dashboard;</span>
            </li>
            <br/>
            <li>
              Click on the add/ edit metadata button in the top right hand {" "}
              <span className="underline">corner;</span>
            </li>
            <br/>
            <li>
              Remember that everything you add here will be public so 
              please double check all your entries and make sure 
              there are no spelling or grammatical {" "}
              <span className="underline">errors;</span>
            </li>
            <br/>
            <li>
              Don’t forget to click 'Save changes' 
              at the top or bottom {" "} 
              <span className="underline">right hand</span> {" "}
              corner of the screen.
            </li>
          </ul>
          <p className="my-4 font-bold">
            To add a new book you’ll need to add the following information: 
          </p>
          <ol className="list-decimal ml-8 font-bold">
            <li>
              Cover image
              <br/>
              <span className="font-normal">Your cover image should meet the following specifications: </span>
              <ul className="list-disc list inside ml-8">
                <li>
                  <span className="font-normal">JPG file format</span>
                </li>
                <li>
                  <span className="font-normal">Square art required (1:1 ratio), without use of borders or letterboxing</span>
                </li>
                <li>
                  <span className="font-normal">
                    Minimum 72 dpi resolution, 24-bit colour, and a minimum of 2400 x 2400 pixels
                    (preferably 3000 x 3000px at 300 dpi)
                  </span>
                </li>
              </ul>
            </li>
            <li>
              Title and subtitle
              <br/>
              <span className="font-normal">
                Add your book’s title and subtitle as per your print book or eBook. 
              </span>
            </li>
            <li>
              Publisher and Imprint
              <br/>
              <span className="font-normal">
                Add the name of your publishing company name (Publisher) and 
                the imprint for the title you are uploading. If you are an author 
                and don’t have these, simply enter your name or pen name 
                (as it appears on the cover image). 
              </span>
            </li>
            <li>
              Publication Date
              <br/>
              <span className="font-normal">
                This is the date your title was first published.  
                It should be the same as the date on the copyright page of your physical book or eBook. 
              </span>
            </li>
            <li>
              On-Sale Date
              <br/>
              <span className="font-normal">
                This is the first date your title can be listed for sale. 
                Please put in today’s date if the date is not in the future. 
              </span>     
            </li>
            <li>
              Grade Level
              <br/>
              <span className="font-normal">
                If an adult title please select ‘Adult’, if a children’s title please select the appropriate 
                US grade level from the <u>drop down</u> menu. 
              </span>
              <br/>
              E.g.: <br/>
              <p className="font-normal">Grades K - 3</p>
              <p className="font-normal">Grades 3 - 6</p>
              <p className="font-normal">Grades 5 - 8</p>
              <p className="font-normal">Pre - K</p>
              <p className="font-normal">Young Adult</p>
              <p className="font-normal">Adult</p>
            </li>
            <li>
              Country Of Publication
              <br/>
              <span className="font-normal">
                This is the Country of publication as per your copyright page.        
              </span>
            </li>
            <li>
              Short Description
              <br/>
              <span className="font-normal">
                The short description is the type of blurb that might go on the back cover of a physical book. 
              </span>
            </li>
            <li>
              Main Description            
              <br/>
              <span className="font-normal">
                <u>The long description of the book</u>, is also referred to as the blurb, 
                which can include any awards, nominations or media quotes you’d like to include. 
              </span>
            </li>
            <li>
              Author name             
              <br/>
              <span className="font-normal">
                The name or pen name of the author as it appears on the cover image or the copyright page. 
              </span>
            </li>
            <li>
              Genre Codes  
              <br/>
              <span className="font-normal">
                Genre codes help retailers identify the correct category in which to list your title. 
                You can select up to three. We prefer you to supply BISAC codes. 
                You can find a list of codes here: <u>https://bisg.org/page/bisacedition</u>
              </span>
            </li>
            <li>
              Keywords 
              <br/>
              <span className="font-normal">
                Please enter search terms that are pertinent or relevant to helping customers find your title. 
              </span>
            </li>
            <li>
              Copyright statement (Edition Type Code)  
              <br/>
              <span className="font-normal">
                  This is the copyright statement for the audiobook, 
                  you need to add the copyright holder and year and the producer 
                  and production year.
                  This is the correct format: © Rights Holder, Year ℗ Audio Producer, Year. 
                  E.g. © Robert Kee, 1984 ℗ Lume Books, 2020
              </span>
            </li>
            <li>
              Audience Type   
              <br/>
              <p className="font-normal">
                  What audience are you targeting with your title? 
              </p>
              <p className="font-normal">
                Please select the most appropriate type from the drop down menu. 
              </p>
              <p className="font-bold">E.g.:</p>
              <p className="font-normal">General/ Trade</p>
              <p className="font-normal">Adult education</p>
              <p className="font-normal">Children/ Juvenile</p>
              <p className="font-normal">College/ higher education</p>
              <p className="font-normal">ELT/ ESL</p>
              <p className="font-normal">Elementary/ high school</p>
              <p className="font-normal">Young Adult</p>
            </li>
            <li>
              Rights Type        
              <br/>
              <span className="font-normal">
                  Select 'For Sale with exclusive Rights in the specified countries/ territories' 
                  if you are the rights holder. Select 'For Sale with non-exclusive Rights in the specified 
                  countries/ territories' if you are publishing an out of copyright title. 
              </span>
            </li>
            <li>
              Territory
              <br/>
              <span className="font-normal">
                  Please enter the territory your title can be sold in. 
                  For example, if you have worldwide rights, enter 'WORLD', 
                  if you only have rights to sell in certain enter those territories. 
                  E.g. World excluding United States & Canada or UK and Commonwealth. 
              </span>
            </li>
            <li>
              ISBN 13
              <br/>
              <span className="font-normal">
                This is the 13 digit ISBN assigned to the downloadable audio edition. 
              </span>
            </li>
            <li>
              Publisher Audio DD Retail Price 
              <br/>
              <span className="font-normal">
                This is the retail a la carte price of your audiobook. 
                Please enter the price in USD and exclusive of any sales taxes or VAT. 
              </span>
            </li>
            <li>
              Publisher Audio DD Library Price
              <br/>
              <span className="font-normal">
                This is the library a la carte price of your audiobook. 
                It is generally set at 2.5 or 3 times the a la carte retail price. 
                Please enter the price in USD and exclusive of any sales taxes or VAT.
              </span>
            </li>
          </ol>
        </>
    );
    }
  }
];

const Help = () => {
  const [showContract, setShowContract] = useState(false);
  const [showDistributionInfo, setShowDistributionInfo] = useState(false);

  const onClickDistributionInfo = (e) => setShowDistributionInfo(e);
  const onClickDistributionContract = (e) => setShowContract(e);
  
  return (
    <div className="container">
      <PageHeader
        title="How can we help?"
        subTitle="Are you new to the portal? Follow this easy step-by-step guide to set-up your account and create your first project. "
        extra={!existAccessToken() && (
          <SignupLink className="relative inline-flex items-center rounded-md focus:outline-none focus:border-gray-300 transition ease-in-out duration-150 px-4 py-1.5 text-md font-medium leading-6 bg-cool-gray-600 hover:bg-cool-gray-500 focus:bg-cool-gray-700 active:bg-cool-gray-700 text-white focus:shadow-outline-gray"/>
        )}
      />

      <TrainingVideos 
          title="Training Videos"
          trainingType={TRAINING_TYPE_ALL}
          layoutType="grid"
      />
      
      <DistributionInfo
        show={showDistributionInfo}
        onClose={(result) => {
          onClickDistributionInfo(false);
        }}
        onClickSetupAgreement={(result) => {
          onClickDistributionInfo(false)
          setTimeout(() => {
              onClickDistributionContract(true);
          }, 200);
        }}
      />

      <Contract
        show={showContract}
        onComplete={(result) => {
          onClickDistributionContract(false);
        }}
      />

      <div className="flex">
        <div className="w-2/3">

           <div className="rounded-lg bg-gray-700 shadow mb-4">
              <div className="p-4 sm:px-6">
                  <div className="lg:flex lg:items-center lg:justify-between">
                      <div className="flex-1 min-w-0">
                        <h2 className="font-medium leading-7 text-gray-100 text-lg">
                          SETTING UP YOUR ACCOUNT
                        </h2>
                      </div>
                  </div>
              </div>
            </div>

          {faqAccount.map((q) => (
            <Card key={q.title} title={q.title} className="mb-4">
              <CardBody>{q.content(onClickDistributionInfo)}</CardBody>
            </Card>
          ))}

          <div className="rounded-lg bg-gray-700 shadow mb-4">
              <div className="p-4 sm:px-6">
                  <div className="lg:flex lg:items-center lg:justify-between">
                      <div className="flex-1 min-w-0">
                        <h2 className="font-medium leading-7 text-gray-100 text-lg">
                          CREATING A PROJECT
                        </h2>
                      </div>
                  </div>
              </div>
            </div>

          {faqProject.map((q) => (
            <Card key={q.title} title={q.title} className="mb-4">
              <CardBody>{q.content}</CardBody>
            </Card>
          ))}

          <div className="rounded-lg bg-gray-700 shadow mb-4">
              <div className="p-4 sm:px-6">
                  <div className="lg:flex lg:items-center lg:justify-between">
                      <div className="flex-1 min-w-0">
                        <h2 className="font-medium leading-7 text-gray-100 text-lg">
                          ONCE YOUR FILES ARE READY
                        </h2>
                      </div>
                  </div>
              </div>
            </div>

          {faqReady.map((q) => (
            <Card key={q.title} title={q.title} className="mb-4">
              <CardBody>{q.content(onClickDistributionContract)}</CardBody>
            </Card>
          ))}
        </div>

        <div className="flex-grow pl-4">
          <Card title="Contact us">
            <CardBody>
              151 Wardour Street <br />
              Soho London <br />
              W1F 8WE <br />
              <br />
              <svg
                className="w-4 h-4 inline mr-1"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                ></path>
              </svg>{" "}
              hello@deepzen.io
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Help;
