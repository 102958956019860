import axios from "axios";
import { useEffect, useState } from "react";

const usePricing = () => {
  const [prices, setPrices] = useState(null);

  useEffect(() => {
    axios.get("portal/pricing/").then((res) => setPrices(res.data));
  }, []);

  return prices;
};

export default usePricing;
