import React from "react";
import Card from "../../components/Card";
import PageHeader from "../../components/PageHeader";
import Table from "./Table";
import classNames from "classnames";
import SignupLink from "../../components/SignupLink";
import HelpLink from "../../components/HelpLink";
import { existAccessToken } from "../Auth/modules/auth";
import VoiceCatalog from "./VoiceCatalog";
import { Link } from "react-router";

const Voice = () => {
  
  return (
    <div className={classNames("container")}>
      <PageHeader
        title="Voice Library"
        subTitle="Listen to our voices and choose."
        extra={
          <div className="flex justify-center text-gray-600 my-6 text-center">
            <HelpLink className="font-bold"/>
            {!existAccessToken() && (
              <>
                <div className="flex-inline h-4 w-px mx-3 mt-1 bg-gray-500"></div>
                <SignupLink className="mb-2 underline font-bold"/> 
              </>
            )}
            <div className="flex-inline h-4 w-px mx-3 mt-1 bg-gray-500"></div>
            <Link to="https://deepzen.io" target="_blank" className="flex-inline items-center mb-2 font-bold underline">
              Home
            </Link>  
          </div>
        }
      />

      <Card className="mt-5 overflow-x-auto bg-cool-gray-100 rounded-none shadow-none lg:mx-2">
        <VoiceCatalog />
      </Card>

      <div className="flex justify-center text-gray-600 my-6 text-center">
          <HelpLink />
          {!existAccessToken() && (
            <>
              <div className="flex-inline h-4 w-px mx-3 mt-1 bg-gray-500"></div>
              <SignupLink className="mb-1"/> 
            </>
          )}
          <div className="flex-inline h-4 w-px mx-3 mt-1 bg-gray-500"></div>
          <Link to="https://deepzen.io" target="_blank" className="flex-inline items-center">
            Home
          </Link> 
      </div>
    </div>
  );
};

export default Voice;
