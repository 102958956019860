import React, { useEffect, useState, Fragment } from "react";
import axios from "axios";
import Loading from "../../components/Loading";
import ApiError from "../../components/ApiError";
import MaleIcon from "../../components/Icons/MaleIcon";
import FemaleIcon from "../../components/Icons/FemaleIcon";
import classNames from "classnames";
import MiniPlayer from "../../components/MiniPlayer";
import MultiSelectBox from "../../components/MultiSelectBox";
import SelectBox from "../../components/SelectBox";

const defaultSelectedGender = "All";
const defaultSelectedVoiceType = "All";

const VoiceCatalog = () => {
  
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const [playingAudioId, setPlayingAudioId] = useState(null);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [languages, setLanguages] = useState(null);
  const [selectedGender, setSelectedGender] = useState(defaultSelectedGender);
  const [genders, setGenders] = useState(null);
  const [selectedVoiceType, setSelectedVoiceType] = useState(defaultSelectedVoiceType);
  const [voiceTypes, setVoiceTypes] = useState(null);
  const [filteredVoices, setFilteredVoices] = useState([]);

  const onFilterVoices = ( _selectedLanguages, _selectedGender, _selectedVoiceType ) => {
    let filteredData = data;
    if(_selectedGender !== "All"){
      filteredData = filteredData.filter(
        (item) => _selectedGender === "Female" ? item.gender === "f" : item.gender === "m");
    }
    if(_selectedVoiceType !== "All"){
      filteredData = filteredData.filter((item) => _selectedVoiceType === item.voice_type);
    }

    filteredData = filteredData.filter((item) => _selectedLanguages.indexOf(item.language) > -1);
    setFilteredVoices(filteredData);
  };
  
  useEffect(() => {
    axios
      .get("portal/voices")
      .then((res) => {
        setLoading(false);
        setData(res.data);
      })
      .catch((e) => {
        setError(e);
        console.log(e);
      });
  }, []);

  useEffect(() => {
    if(data){
      const _languages = {};
        const _genders = {
          "All": "All"
        };
        const _voiceTypes = {
          "All": "All"
        };
        data.map((item) => {
          _languages[item.language] = item.language;
          const gender = item.gender === "f" ? "Female" : "Male";
          _genders[gender] = gender;
          _voiceTypes[item.voice_type] = item.voice_type;
        });
        
        const languageArr = Array.from(Object.keys(_languages));
        setLanguages(languageArr);
        setSelectedLanguages(languageArr);
        setGenders(Array.from(Object.keys(_genders)));
        setVoiceTypes(Array.from(Object.keys(_voiceTypes)));
        onFilterVoices(languageArr, selectedGender, selectedVoiceType);
    }
  }, [data]);

  if (loading) return <Loading />;
  if (error) return <ApiError error={error} />;
  if (!data) return null;

  const onPlayAudio = (id) => {
    if (id !== playingAudioId) {
        let audioEl = document.getElementById(playingAudioId);
        if (audioEl) {
            audioEl.pause();
        }
    }
    setPlayingAudioId(id);
  };

  return (
    <>
    
    <div className="lg:flex lg:space-x-2 mb-3 shadow-md">
      <MultiSelectBox 
        label="Language"
        options={languages} 
        onChange={(e) => {
          const values = e.length > 0 ? e : ["English (UK)"]
          setSelectedLanguages(values);
          onFilterVoices(values, selectedGender, selectedVoiceType);
        }} 
        selected={selectedLanguages}
      />
      <SelectBox 
        label="Gender" 
        options={genders}
        onChange={(e) => {
          setSelectedGender(e);
          onFilterVoices(selectedLanguages, e, selectedVoiceType);
        }}
        selected={selectedGender}
      />
      <SelectBox 
        label="Voice Type" 
        options={voiceTypes}
        onChange={(e) => {
          setSelectedVoiceType(e);
          onFilterVoices(selectedLanguages, selectedGender, e);
        }}
        selected={selectedVoiceType}
      />
      
      <div className="flex md:items-end justify-between px-2 lg:pl-5 lg:px-0 mb-2">
         <button 
            className="h-10 text-blue-700 font-medium focus:outline-none"
            onClick={(e) => {
              setSelectedGender(defaultSelectedGender);
              setSelectedVoiceType(defaultSelectedVoiceType);
              setSelectedLanguages(languages);
              onFilterVoices(languages, defaultSelectedGender, defaultSelectedVoiceType);
            }}
          >
            Reset selection
         </button>
         <div className="hidden md:h-10 md:mx-3 md:flex md:items-center">
          <span>|</span>
         </div>
         <div className="h-10 flex items-center">
            <span className="text-gray-900 font-medium">
               {(filteredVoices.length === 0 || filteredVoices.length === 1) 
                ? `Total ${filteredVoices.length} voice` 
                : `Total ${filteredVoices.length} voices`
              }
            </span>
         </div>
         
      </div>
    </div>

    {filteredVoices.length === 0 && (
      <div className="flex items-center justify-center w-full h-48">
        <span className="text-lg font-bold">No Data</span>
      </div>
    )}

    <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {filteredVoices.map((row) => (
            <li key={row.id} className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
            <div className="flex w-full items-center justify-between space-x-6 p-6">
              <div className="flex-1 truncate">
                <div className="flex items-center space-x-3">
                  <h3 className="truncate text-sm font-bold text-gray-900">
                    {row.name_of_narrator}
                  </h3>
                  <span className={classNames("inline-flex flex-shrink-0 items-center rounded-full px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset",
                       row.gender === "f" ? "bg-red-50 text-red-700 ring-red-600/20" : "bg-green-50 text-green-700 ring-green-600/20"
                    )}
                  >
                    {row.gender === "f" ? "Female" : "Male"}
                  </span>
                  <span className="text-xs font-medium">{row.language}</span>
                </div>
                <p className="mt-1 truncate text-sm text-gray-500">
                    <span className="mr-2 text-gray-900 font-medium">Voice type:</span>
                    <span>{row.voice_type}</span>
                </p>
                <p className="mt-1 truncate text-sm text-gray-500">
                    <span className="mr-2 text-gray-900 font-medium">Recommended genres:</span>
                    <span>{row.recommended_genre}</span>
                </p>
              </div>
              {row.gender === "f" ? (<FemaleIcon />) : (<MaleIcon />)}

            </div>
            <div>
              <div className="-mt-px flex divide-x divide-gray-200">
                <div className={classNames(
                    "flex w-0 flex-1 justify-center items-center",
                    `${row.id}-fiction` === playingAudioId ? "bg-cool-gray-900" : "bg-cool-gray-500"
                    )}
                >
                    <span className="text-gray-100 font-medium mr-1">Fiction Sample</span>
                    <MiniPlayer
					            playerId={`${row.id}-fiction`}
					            src={row.fiction_sample}
					            onPlayAudio={onPlayAudio}
					            playingAudioId={playingAudioId}
                      className="text-gray-100"
				            />
                </div>
                <div className={classNames(
                    "-ml-px flex w-0 flex-1 justify-center items-center",
                     `${row.id}-non-fiction` === playingAudioId ? "bg-cool-gray-900" : "bg-cool-gray-500"
                    )}
                >
                    <span className="text-gray-100 font-medium mr-1 truncate">Non-Fiction Sample</span>
                    <MiniPlayer
						          playerId={`${row.id}-non-fiction`}
						          src={row.non_fiction_sample}
						          onPlayAudio={onPlayAudio}
						          playingAudioId={playingAudioId}
                      className="text-gray-100"
					          />
                </div>
              </div>
            </div>
          </li>
        ))}
    </ul>
    </>
  );
};

export default VoiceCatalog;
