import React from "react";
import Card, { CardBody } from "../../../../components/Card";
import TrainingVideos, { TRAINING_TYPE_DISTRIBUTION_METADATA } from "../../../../components/TrainingVideos";

export function InitialLinguisticsReview({ project }) {
  return (
    <>
    <Card title="Manuscript review">
      <CardBody>
        <div>
          In order to ensure that all words are pronounced correctly in your audiobook, 
          we check the entire text for uncommon words. Should we require your input, 
          you’ll receive an email requesting pronunciation guidance within 1 to 3 working days.
        </div>

        <div className="mt-2">
          You have successfully submitted your project. Part of the review process is to identify uncommon words, 
          such as names, places, and foreign words, where we will require your guidance on pronunciation. 
          If you do not wish to provide pronunciation guidance, we will use our audio dictionary to predict 
          the pronunciation of these words. Rest assured, our editors review all pronunciations during the editing process. 
          The review should take 1-3 days. You will receive an email notification asking you to add pronunciations. 
          Once you have completed this stage, you’ll receive your completed audiobook within an estimated 
          3 days for the automated service and within an estimated two weeks for the managed service. 
        </div>
      </CardBody>
    </Card>
    
    {project && project.distribution && (
      <TrainingVideos 
          className="container mt-6"
          trainingType={TRAINING_TYPE_DISTRIBUTION_METADATA}
      />
    )}
    
    </>
  );
}

export default InitialLinguisticsReview;
