import React from "react";
import Button from "../Button";

const Header = ({ buttons = [] }) => {
  return (
    <div className="border-t bg-gray-50 px-4 py-3 sm:flex sm:items-end sm:flex-row-reverse">
      {buttons.map((button, i) => (
        <Button
          key={i}
          {...button}
          className={`w-full text-base sm:text-sm sm:w-auto ${
            i === 0 ? "sm:ml-3" : "mt-3 sm:ml-3 sm:mt-0"
          }`}
        />
      ))}
    </div>
  );
};

export default Header;
