import React, { useEffect, useState } from "react";

const useRecorder = (defaultAudioURL) => {
  const [audioURL, setAudioURL] = useState(defaultAudioURL);
  const [base64Data, setBase64Data] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [recorded, setRecorded] = useState(false);
  const [recorder, setRecorder] = useState(null);

  useEffect(() => {
    if (recorder === null) {
      if (isRecording) {
        requestRecorder().then(setRecorder, console.error);
      }
      return;
    }

    if (isRecording) {
        recorder.start();
    } else {
        recorder.stop();
    }

    const handleData = e => {
        var reader = new FileReader();
        reader.readAsDataURL(e.data); 
        reader.onloadend = function() {
            var base64data = reader.result;                
            setBase64Data(base64data);
            setAudioURL(URL.createObjectURL(e.data));
        }
    };

    recorder.addEventListener("dataavailable", handleData);
    
    return () => recorder.removeEventListener("dataavailable", handleData);
  
  }, [recorder, isRecording]);

  useEffect(() => {
    setRecorded(audioURL ? true: false);
  },[audioURL])

  const startRecording = () => {
    setIsRecording(true);
    setRecorded(false);
  };

  const stopRecording = (e) => {
    setIsRecording(false);
    setRecorded(e);
  };

  const reset = () => {
    setIsRecording(false);
    setRecorded(false);
    setRecorder(null);
    setBase64Data(null);
    setAudioURL(null);
  }

  return [audioURL, base64Data, isRecording, recorded, startRecording, stopRecording, reset];
};

async function requestRecorder() {
  const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  return new MediaRecorder(stream);
}

export default useRecorder;