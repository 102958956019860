import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { browserHistory } from "react-router";

export function notLoggedIn(Comp) {
  return (props) => {
    const { verified, user, activeRoute, protectRedirect } = useSelector(
      (state) => ({
        verified: state.auth.verified,
        user: state.auth.user,
        activeRoute: state.location.pathname,
        protectRedirect: state.auth.protectRedirect,
      })
    );

    useEffect(() => {
      if (verified && user !== null) {
        browserHistory.push(protectRedirect);
      }
    }, [user, activeRoute]);

    if (!verified) return null;

    if (user !== null) {
      return null;
    }

    return <Comp {...props} />;
  };
}

export default notLoggedIn;
