import Epub from "epubjs";
import React, { useEffect, useRef, useState } from "react";
import Book from "./Book";
import PreviewNav from "./PreviewNav";
import PreviewRemoveBar from "./PreviewRemoveBar";
import { globRemovedClasses } from "./index";
import classNames from "classnames";

const Preview = ({
  step,
  data,
  book,
  removedXhtmls,
  setRemovedXhtmls,
  removedChapters,
  onRemoveChapter,
  onUndoChapter,
  onBookReady,
  removedClasses,
  onAddClasses,
}) => {
  const iframe = useRef();
  const rendition = useRef();
  const epub = useRef();
  const [navItems, setNavItems] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const highlightRemovedClasses = (doc, globRemovedClasses) => {
    if (globRemovedClasses.length > 0) {
      const items = Array.prototype.slice.call(
        doc.querySelectorAll(globRemovedClasses.map((c) => `.${c}`).join(","))
      );

      items.forEach((item) => {
        item.classList.add("dz-epub-removed");
        item.style.textDecoration = "line-through";
        item.style.opacity = 0.5;
      });
    }
  };

  useEffect(() => {
    if (rendition.current) {
      const ifr = rendition.current.manager.container.querySelector("iframe")
        .contentDocument;

      let oldElements = ifr.querySelectorAll(".dz-epub-removed");
      let oldItems = Array.prototype.slice.call(oldElements);
      oldItems.forEach((item) => {
        item.classList.remove("dz-epub-removed");
        item.style.textDecoration = "";
        item.style.opacity = "";
      });

      highlightRemovedClasses(ifr, removedClasses);
    }
  }, [removedClasses]);

  useEffect(() => setActiveIndex(0), [step]);

  useEffect(() => {
    async function render() {
      epub.current = Epub(data);

      rendition.current = epub.current.renderTo(iframe.current, {
        render: "default",
        flow: "scrolled-doc",
        width: "100%",
      });

      await rendition.current.display();

      onBookReady(new Book(epub.current));

      rendition.current.hooks.content.register((contents, view) => {
        var elements = contents.document.querySelectorAll(
          "body [class]:not(img)"
        );
        var items = Array.prototype.slice.call(elements);

        items.forEach((item) => {
          item.addEventListener("mouseover", (e) => {
            e.stopPropagation();

            // Parents might be highlighted as well
            const q = contents.document.querySelectorAll(".dz-epub-highlight");

            Array.prototype.slice.call(q).forEach((item) => {
              item.style.backgroundColor = "";
              item.style.cursor = "";
            });

            item.style.backgroundColor = "rgba(255, 0, 0, .5)";
            item.style.cursor = "pointer";
            item.classList.add("dz-epub-highlight");
          });

          item.addEventListener("mouseleave", (e) => {
            item.style.backgroundColor = "";
            item.classList.remove("dz-epub-highlight");
          });

          item.addEventListener("click", (e) => {
            e.stopPropagation();
            e.preventDefault();

            onAddClasses([
              ...[...e.currentTarget.classList].filter(
                (c) => c !== "dz-epub-highlight" && c !== "dz-epub-removed"
              ),
            ]);
          });
        });

        highlightRemovedClasses(contents.document, globRemovedClasses);
      });
    }

    render();
  }, []);

  useEffect(() => {
    setNavItems(
      book &&
        book.getNavItems(
          step === 0 ? "chapter" : "page",
          removedChapters,
          step === 2 ? removedXhtmls : []
        )
    );
  }, [book, step]);

  useEffect(() => {
    async function display() {
      await rendition.current.display(
        navItems[activeIndex].href &&
          navItems[activeIndex].href.replace(/\.\.\//g, "")
      );
    }

    if (book) {
      display();
    }
  }, [activeIndex]);

  return (
    <div
      className={classNames(
        "w-full h-full flex flex-col",
        step === 3 && "hidden"
      )}
    >
      {navItems && (
        <>
          <PreviewNav
            items={navItems}
            activeIndex={activeIndex}
            onChange={setActiveIndex}
          />

          {step < 2 && (
            <PreviewRemoveBar
              removed={
                step === 0
                  ? removedChapters.indexOf(activeIndex) > -1
                  : removedXhtmls.indexOf(navItems[activeIndex].href) > -1
              }
              type={step === 0 ? "chapter" : "page"}
              onUndo={
                step === 0
                  ? () => onUndoChapter(activeIndex)
                  : () =>
                      setRemovedXhtmls(
                        removedXhtmls.filter(
                          (h) => h !== navItems[activeIndex].href
                        )
                      )
              }
              onRemove={
                step === 0
                  ? () => onRemoveChapter(activeIndex)
                  : () =>
                      setRemovedXhtmls([
                        ...removedXhtmls,
                        navItems[activeIndex].href,
                      ])
              }
            />
          )}
        </>
      )}

      <div className="flex-grow overflow-x-hidden overflow-y-auto h-full">
        <div
          className={classNames(
            "p-4 w-full h-full",
            step !== 2 && "pointer-events-none"
          )}
          ref={iframe}
        />
      </div>
    </div>
  );
};

export default Preview;
