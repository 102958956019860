import React from "react";
import { Link } from "react-router";
import logoIcon from "../../assets/logo-icon.png";

const NotFoundPage = () => (
  <div className="flex w-screen h-screen justify-center items-center">
    <div className="text-center">
      <img className="w-24 h-auto m-auto" src={logoIcon} alt="Workflow" />
      <h2 className="text-3xl leading-9 font-extrabold mt-5 tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
        Oops! That page can’t be found.
      </h2>
      <p className="text-xl leading-3 pt-5 font-bold tracking-tight text-black-900 sm:text-xl sm:leading-10">
        <Link to="/">Go to the Home Page </Link>
      </p>
    </div>
  </div>
);

export default NotFoundPage;
