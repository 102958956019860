import { message } from "antd";
import axios from "axios";
import React from "react";
import { useUser } from "../routes/Auth/modules/auth";
import Button from "./Button";

const DemoEmulator = ({ project }) => {
  const user = useUser();

  const handleSubmit = () => {
    if (window.confirm("Are you sure?")) {
      axios
        .post(`portal/projects/${project.id}/demo/`)
        .then(() => window.location.reload())
        .catch((e) => message.error("An error occurred"));
    }
  };

  if (!user?.is_demo) return null;

  return (
    <div className="bg-blue-100 rounded-lg p-4 mt-4">
      <div>
        <strong>Demo Actions</strong>
      </div>

      <div className="mt-2">
        This section is only for demo purposes. You can emulate the editing part
        of each project here, normally DeepZen would do these steps.
      </div>

      <div className="mt-2">
        <Button onClick={handleSubmit}>Move to next step</Button>
      </div>
    </div>
  );
};

export default DemoEmulator;
