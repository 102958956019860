import axios from "axios";
import React, { useState } from "react";
import ApiError from "../../../components/ApiError";
import { ModalFooter } from "../../../components/Modal";

const ContractAgree = ({ onComplete }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    axios
      .post("portal/business-contract/accept/")
      .then((res) => onComplete())
      .catch((e) => setError(e))
      .finally(() => setLoading(false));
  };

  return (
    <>
      <div className="p-4">
        <div>I accept the terms of the production agreement.</div>
        {error && <ApiError error={error} />}
      </div>
      <form onSubmit={handleSubmit}>
        <ModalFooter
          buttons={[{ loading, type: "success", children: ["Accept"] }]}
        />
      </form>
    </>
  );
};

export default ContractAgree;
