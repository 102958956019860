import React, { useState, useEffect } from "react";
import { Menu, Dropdown, Icon } from 'antd';
import classNames from "classnames";

const SortBox = ({
                     className,
                     data,
                     defaultFieldIndex,
                     defaultSort,
                     onChange,
                     sort
                 }) => {
    const [selectedOptionIndex, setSelectedOptionIndex] = useState(defaultFieldIndex || 0);
    const [selectedSortType, setSelectedSortType] = useState(defaultSort || "desc");

    useEffect(() => {
        if (sort) {
            const fieldIndex = data.findIndex(option => option.field === sort.sort);
            if (fieldIndex !== -1) {
                setSelectedOptionIndex(fieldIndex);
                setSelectedSortType(sort.sortDir);
            }
        }
    }, [sort, data]);

    const handleOnClickOption = (e, index) => {
        e.stopPropagation();
        setSelectedOptionIndex(index);
        onChange({
            sort: data[index]["field"],
            sortDir: selectedSortType
        });
    };

    const handleOnClickSort = (e, value) => {
        e.stopPropagation();
        setSelectedSortType(value);
        onChange({
            sort: data[selectedOptionIndex]["field"],
            sortDir: value
        });
    };

    if (!data) return null;

    const menu = (
        <Menu>
            <Menu.Item key="title" disabled className="cursor-default">
                <div className="text-sm font-medium text-gray-900">
                    Sort by
                </div>
            </Menu.Item>
            {data && data.map((option, i) => (
                <Menu.Item key={i}>
                    <div
                        className="flex items-center cursor-pointer"
                        role="button"
                        onClick={(e) => handleOnClickOption(e, i)}>
                        <input
                            className="w-4 h-4 cursor-pointer text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            id={`radio-${i}`}
                            type="radio"
                            name="radio"
                            checked={selectedOptionIndex === i}
                            onClick={(e) => e.stopPropagation()}
                        />
                        <label htmlFor={`radio-${i}`} className="ml-2 cursor-pointer text-sm font-medium text-gray-900">
                            {option.title}
                        </label>
                    </div>
                </Menu.Item>
            ))}
            <Menu.Divider className="my-3" />
            <Menu.Item key="sort-asc">
                <div
                    className={classNames(
                        "flex items-center cursor-pointer text-sm font-medium",
                        selectedSortType === "asc" ? "bg-blue-50 text-blue-500" : "text-gray-900"
                    )}
                    role="button"
                    onClick={(e) => handleOnClickSort(e, "asc")}
                >
                    <Icon type="arrow-up" className="mr-2" />
                    {
                        data[selectedOptionIndex]["type"] === "date"
                            ? "Oldest first"
                            : data[selectedOptionIndex]["type"] === "text"
                                ? "A-Z"
                                : data[selectedOptionIndex]["type"] === "number"
                                    ? "Low " + data[selectedOptionIndex]["title"]
                                    : ""
                    }
                </div>
            </Menu.Item>
            <Menu.Item key="sort-desc">
                <div
                    className={classNames(
                        "flex items-center cursor-pointer text-sm font-medium",
                        selectedSortType === "desc" ? "bg-blue-50 text-blue-500" : "text-gray-900"
                    )}
                    role="button"
                    onClick={(e) => handleOnClickSort(e, "desc")}
                >
                    <Icon type="arrow-down" className="mr-2" />
                    {
                        data[selectedOptionIndex]["type"] === "date"
                            ? "Newest first"
                            : data[selectedOptionIndex]["type"] === "text"
                                ? "Z-A"
                                : data[selectedOptionIndex]["type"] === "number"
                                    ? "High " + data[selectedOptionIndex]["title"]
                                    : ""
                    }
                </div>
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={menu} trigger={['click']}>
            <div role="button"
                 className={classNames(
                     "flex items-center border-2 border-gray-300 rounded-lg px-3",
                     className
                 )}
                 onClick={e => e.preventDefault()}>
                <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    role="img"
                    focusable="false"
                    aria-hidden="true"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.293 1.293a.997.997 0 0 1 1.414 0l3 3a1 1 0 0 1-1.414 1.414L6 4.414V9a1 1 0 1 1-2 0V4.414L2.707 5.707a1 1 0 0 1-1.414-1.414l3-3ZM10 7a1 1 0 0 1 2 0v4.585l1.293-1.292a.999.999 0 1 1 1.414 1.414l-3 3a.997.997 0 0 1-1.414 0l-3-3a.997.997 0 0 1 0-1.414.999.999 0 0 1 1.414 0L10 11.585V7Z">
                    </path>
                </svg>
            </div>
        </Dropdown>
    );
};

export default SortBox;