import React, { useState } from "react";
import Link from "react-router";
import { Slider, Icon } from "antd";
import { Transition } from "@headlessui/react";
import logoIcon from "../../assets/logo-icon.png";

const Footer = () => {

const [industriesMenu, setIndustriesMenu] = useState(false);
const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

return ( 
<div className="pb-10">    
<div className="flex w-full bg-gray-800">    
    <div className="max-w-screen-lg block lg:flex mx-auto w-full">
        <div className="block lg:flex bg-gray-800 px-2 pt-10 lg:pt-10 lg:px-4 w-full lg:w-1/3">
           <nav className="text-center lg:text-left">
                <p className="text-white text-base px-3 py-2 font-bold">Company</p> 
                <a href="#" className="block hover:bg-gray-700 text-white px-3 py-2 rounded-md text-sm font-medium">Home</a>
                <a href="javascript:void(0)" onClick={() => setIndustriesMenu(!industriesMenu)} className="flex justify-center lg:justify-start items-center text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Industries
                    <Icon type={industriesMenu ? "up" : "down"} style={{fontSize: 12, paddingLeft: 2}}/>
                </a>
                {industriesMenu && (<div className="bg-gray-600 pl-5" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
                        <a href="#" className="block px-4 py-2 text-sm text-white hover:bg-gray-500" role="menuitem">Advertising</a>
                        <a href="#" className="block px-4 py-2 text-sm text-white hover:bg-gray-500" role="menuitem">Corporate</a>
                        <a href="#" className="block px-4 py-2 text-sm text-white hover:bg-gray-500" role="menuitem">Gaming</a>
                        <a href="#" className="block px-4 py-2 text-sm text-white hover:bg-gray-500" role="menuitem">E-Learning</a>
                        <a href="#" className="block px-4 py-2 text-sm text-white hover:bg-gray-500" role="menuitem">Narration</a>
                        <a href="#" className="block px-4 py-2 text-sm text-white hover:bg-gray-500" role="menuitem">Publishing</a>
                        <a href="#" className="block px-4 py-2 text-sm text-white hover:bg-gray-500" role="menuitem">Voiceover</a>
                    </div>
                )}
                <a href="#" className="block text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Product & Services</a>
                <a href="#" className="block text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">About</a>
                <a href="#" className="block text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Contact</a>
           </nav>
        </div>

        <div className="block lg:flex justify-center bg-gray-800 px-2 pt-10 lg:pt-10 lg:px-4 w-full lg:w-1/3">
            <div className="text-center">
                <p className="block text-white text-base px-3 py-2 font-bold">Legals</p> 
                <a href="#" className="block text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Ethical Statement</a>
                <a href="#" className="block text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Terms of Use</a>
                <a href="#" className="block text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Privacy Policy</a>
            </div>
        </div>

        <div className="block lg:flex justify-center lg:justify-end bg-gray-800 px-2 pt-10 lg:pt-10 lg:px-4 w-full lg:w-1/3">
            <div className="text-center lg:text-right">
                <div className="text-center lg:text-right">
                    <p className="block text-gray-300 text-base px-3 py-2 font-bold">Find Us</p> 
                    <p className="block text-gray-300 text-sm font-medium px-3 py-2">151 Wardour Street<br/>Soho London<br/>W1F 8WE</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div className="flex w-full bg-gray-800">   
    <div className="max-w-screen-lg flex mx-auto w-full">
        <div className="flex bg-gray-800 px-2 py-3 lg:py-3 lg:px-4 w-full">
            <div className="flex-shrink-0 flex items-center justify-start">
                <img className="block lg:hidden w-auto" src="https://deepzen.io/wp-content/uploads/2020/05/brigtherlogo-300x64.png" alt="Workflow" />
                <img className="hidden lg:block w-auto" src="https://deepzen.io/wp-content/uploads/2020/05/brigtherlogo-300x64.png" alt="Workflow" />
            </div>
        </div>
    </div>
</div>

<div className="flex w-full bg-gray-800 pb-4">   
    <div className="max-w-screen-lg flex mx-auto w-full">
        <div className="block lg:flex bg-gray-800 px-2 py-2 lg:py-2 lg:px-4 w-full">
            <div className="flex items-center justify-center lg:justify-start w-full lg:w-1/2">
                <p className="block text-gray-300 px-3 text-sm font-medium">Copyright © DeepZen Limited 2020</p>
            </div>
            <div className="flex items-center justify-center py-2 lg:py-0 lg:justify-end w-full lg:w-1/2">
                <p className="block text-gray-300 px-3 text-sm font-medium">Company Registration number: 1108177</p>
            </div>
        </div>
    </div>
</div>
</div> 
);
}

export default Footer;