import axios from "axios";
import React, { useState } from "react";
import ApiError from "../../../components/ApiError";
import { ModalFooter } from "../../../components/Modal";

const ContractDecline = ({ onComplete }) => {
  const [explanation, setExplanation] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    setError(null);

    axios
      .post("portal/business-contract/decline/", { explanation })
      .then((res) => onComplete())
      .catch((e) => setError(e))
      .finally(() => setLoading(false));
  };

  return (
    <>
      <div className="bg-blue-100 text-blue-800 mb-2 p-4">
        We will reach out to you to understand why and how we can help. You can
        optionally explain why you decline.
      </div>

      {error && (
        <div className="pt-2 pb-3 px-4">
          <ApiError error={error} />
        </div>
      )}

      <form onSubmit={handleSubmit}>
        <div className="px-4 py-2">
          <textarea
            name="default_specs"
            onChange={(e) => setExplanation(e.target.value)}
            value={explanation}
            className="w-full form-textarea mr-2 h-40 mb-2"
            autoFocus
          />
        </div>

        <ModalFooter
          buttons={[{ loading, type: "success", children: ["Submit"] }]}
        />
      </form>
    </>
  );
};

export default ContractDecline;
