import React from "react";
import Button from "../../../components/Button";
import Card, { CardBody } from "../../../components/Card";
import VoiceCatalog from "../../../routes/Voices/VoiceCatalog";
import { Icon } from "antd";

const Voices = ({ onComplete, onBack }) => (
  <>
    <Card className="mb-4">
      <CardBody>
        Here you can listen to fiction and non-fiction samples of the digital
        voices available for your project.
      </CardBody>
    </Card>

    <Card className="overflow-x-auto bg-cool-gray-100 rounded-none shadow-none">
      <VoiceCatalog />
    </Card>

    <div className="flex justify-between">
      <Button onClick={onBack} className="mt-4" size="large">
        <Icon type="left" style={{ marginRight: 4 }} /> Back
      </Button>

      <Button onClick={onComplete} className="mt-4" size="large" type="success">
        Continue
      </Button>
    </div>
  </>
);

export default Voices;
