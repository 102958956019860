import React from "react";
import { Spin } from "antd";

export function Loading() {
	return (
		<div style={{ textAlign: "center", paddingTop: 20 }}>
			<Spin /> <span style={{ marginLeft: 10 }}>Loading...</span>
		</div>
	);
}

export default Loading;