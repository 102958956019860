import React, { useState, useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import { Field, reduxForm, getFormSyncErrors } from "redux-form";
import Button from "../../../components/Button";
import Card, { CardBody } from "../../../components/Card";
import TextField from "../../../components/Forms/TextField";
import CheckboxField from "../../../components/Forms/CheckboxField";
import SelectField from "../../../components/Forms/SelectField";
import ApiError from "../../../components/ApiError";
import { required } from "../../../services/validations";
import { updateUser, useUser } from "../../../routes/Auth/modules/auth";
import axios from "axios";
import classNames from "classnames";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const countryHandle = require("countrycitystatejson");

const Form = ({
  loading,
  handleSubmit,
  sameAddress,
  onSameAddressChange,
  countries,
  registeredBusinessStates,
  postalAddressStates,
  onChangeCountry,
  onChangeCustomerType,
  onChangePhoneNumber,
  phoneNumber,
  customerType,
  cta,
  isFormValid,
}) => {
  const [showFormValidation, setShowFormValidation] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  
return (
  <form onSubmit={handleSubmit}>
    <Card>
      <CardBody>
        <div className="px-2">
        <div className="mb-4">
            <Field
                name="customer_type"
                className="h-10 sm:h-9"
                label="Customer Type"
                component={SelectField}
                onChange={({ target }) =>
                  onChangeCustomerType(target.value)
                }
                validate={[required]}
                options={[
                  { value: "publisher", label: "Publisher" },
                  { value: "author", label: "Author"}
                ]}
            />
        </div>

          <div className="mb-4">
            <Field
              className="h-10 sm:h-9"
              component={TextField}
              validate={[required]}
              label="Publisher / Company / Author Name"
              name="publisher_name"
            />
          </div>

          <div className="mb-4">
            <Field
              className="h-10 sm:h-9"
              component={TextField}
              label="Registered Business Name (if applicable)"
              name="registered_business_name"
            />
          </div>

          <div>
            <div className="mb-4">
              <Field
                component={TextField}
                validate={[required]}
                label="Company Registration Number"
                name="company_registration_id"
                className={classNames("h-10 sm:h-9",
                  customerType === "author" ? "bg-gray-100" : ""
                )}
                disabled={customerType === "author"}
              />
            </div>
              
            <div className="mb-4">
              <Field
                component={TextField}
                validate={[required]}
                label="Company Tax ID"
                name="company_tax_id"
                className={classNames("h-10 sm:h-9",
                  customerType === "author" ? "bg-gray-100" : ""
                )}
                disabled={customerType === "author"}
              />
            </div>
          </div>
          
          <div className="mb-4">
            <Field
              className="h-10 sm:h-9"
              component={TextField}
              label="VAT Number (if applicable)"
              name="vat_number"
            />
          </div>

          <div className="md:flex mb-4 md:justify-center">
            <div className="flex md:flex-1 mb-4">
              <div className="relative w-full">
                <label class="text-gray-500 font-medium mb-0.5 flex items-center text-sm">
                  Telephone Number
                </label>
                <PhoneInput
                  inputClass="block h-10 sm:h-9 rounded-md block bg-white outline-none sm:text-sm text-sm shadow-sm form-input w-full sm:leading-5 focus:shadow-outline-blue"
                  country={'gb'}
                  value={phoneNumber}
                  enableSearch
                  onChange={phone => onChangePhoneNumber(phone)}
                  isValid={(inputNumber, selectedCountry, countries) => {
                    const validNumberCount = (selectedCountry.format.match(/\./g) || []).length;
                    const mismatchedCount = inputNumber.length !== validNumberCount;
                    setPhoneNumberError(mismatchedCount);
                    return !mismatchedCount;
                  }}
                />
                {phoneNumberError && (<span class="text-red-900 text-xs">Invalid phone number</span>)}
              </div>
            </div>

            <div className="flex mb-4 md:mb-0 md:ml-4 md:flex-1">
              <Field
                component={TextField}
                className="bg-gray-100 h-10 sm:h-9"
                disabled={true}
                label="Email"
                name="email"
              />
            </div>
          </div>      
          
          <div className="bg-gray-100 rounded-md mb-4 px-4 pb-4 overflow-hidden shadow-sm">
            <div className="p-4 font-semibold text-xs tracking-wider bg-gray-200 -mx-4 mb-4">
              REGISTERED BUSINESS / AUTHOR ADDRESS
            </div>

            <div className="mb-4">
              <Field
                className="h-10 sm:h-9"
                component={TextField}
                validate={[required]}
                label="Street address line 1"
                name="registered_business_address_street_1"
              />
            </div>

            <div className="mb-4">
              <Field
                className="h-10 sm:h-9"
                component={TextField}
                validate={[required]}
                label="Street address line 2"
                name="registered_business_address_street_2"
              />
            </div>

            <div className="md:-ml-4 md:flex">
              <div className="flex mb-4 md:flex-1 md:ml-4">
                <Field
                  className="h-10 sm:h-9"
                  name="registered_business_address_country"
                  label="Country"
                  component={SelectField}
                  info=""
                  onChange={({ target }) =>
                    onChangeCountry("registeredBusinessAddress", target.value)
                  }
                  validate={[required]}
                  options={[
                    { value: "", label: "-- Choose Country --" },
                    ...countries.map((c) => ({ value: c.name, label: c.name })),
                  ]}
                />
              </div>

              <div className="flex mb-4 md:flex-1 md:ml-4">
                <Field
                  className="h-10 sm:h-9"
                  name="registered_business_address_state"
                  label="State / Province"
                  component={SelectField}
                  info=""
                  validate={[required]}
                  options={[
                    { value: "", label: "-- Choose States --" },
                    ...registeredBusinessStates.map((s) => ({
                      value: s,
                      label: s,
                    })),
                  ]}
                />
              </div>

              <div className="flex mb-4 md:flex-1 md:ml-4">
                <Field
                  className="h-10 sm:h-9"
                  component={TextField}
                  validate={[required]}
                  label="City"
                  name="registered_business_address_city"
                />
              </div>

              <div className="flex mb-4 md:flex-1 md:ml-4">
                <Field
                  className="h-10 sm:h-9"
                  component={TextField}
                  validate={[required]}
                  label="ZIP / Postal Code"
                  name="registered_business_address_zip"
                />
              </div>
            </div>
          </div>

          <div className="bg-gray-100 rounded-md mb-4 px-4 pb-4 overflow-hidden shadow-sm">
            <div className="p-4 font-semibold text-xs tracking-wider bg-gray-200 -mx-4 mb-4">
              POSTAL ADDRESS
            </div>

            <Field
              component={CheckboxField}
              defaultValue={sameAddress}
              label="Same as Business / Author Registration Address"
              name="postal_address_same_as_registered_business_address"
              onChange={({ target }) => onSameAddressChange(target.value)}
            />

            <div className={classNames(sameAddress ? "hidden" : "block")}>
              <div className="my-4 pt-4">
                <Field
                  className="h-10 sm:h-9"
                  component={TextField}
                  validate={!sameAddress ? [required] : null}
                  label="Street address line 1"
                  name="postal_address_street_1"
                />
              </div>

              <div className="my-4 pt-4">
                <Field
                  className="h-10 sm:h-9"
                  component={TextField}
                  validate={!sameAddress ? [required] : null}
                  label="Street address line 2"
                  name="postal_address_street_2"
                />
              </div>

              <div className="md:-ml-4 md:flex">
                <div className="flex mb-4 md:flex-1 md:ml-4">
                  <Field
                    className="h-10 sm:h-9"
                    name="postal_address_country"
                    label="Country"
                    component={SelectField}
                    info=""
                    onChange={({ target }) =>
                      onChangeCountry("postalAddress", target.value)
                    }
                    validate={!sameAddress ? [required] : null}
                    options={[
                      { value: "", label: "-- Choose Country --" },
                      ...countries.map((c) => ({
                        value: c.name,
                        label: c.name,
                      })),
                    ]}
                  />
                </div>

                <div className="flex mb-4 md:flex-1 md:ml-4">
                  <Field
                    className="h-10 sm:h-9"
                    name="postal_address_state"
                    label="State / Province"
                    component={SelectField}
                    info=""
                    validate={!sameAddress ? [required] : null}
                    options={[
                      { value: "", label: "-- Choose States --" },
                      ...postalAddressStates.map((s) => ({
                        value: s,
                        label: s,
                      })),
                    ]}
                  />
                </div>

                <div className="flex mb-4 md:flex-1 md:ml-4">
                  <Field
                    className="h-10 sm:h-9"
                    component={TextField}
                    validate={!sameAddress ? [required] : null}
                    label="City"
                    name="postal_address_city"
                  />
                </div>

                <div className="flex mb-4 md:flex-1 md:ml-4">
                  <Field
                    className="h-10 sm:h-9"
                    component={TextField}
                    validate={!sameAddress ? [required] : null}
                    label="ZIP / Postal Code"
                    name="postal_address_zip"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        
        
        <div className={classNames("flex",
          showFormValidation ? "justify-between" : "justify-end"
        )}>
        {showFormValidation && (
          <div className="flex w-full items-center border border-red-400 rounded bg-red-100 mx-2 px-4 text-red-700">
            <svg className="fill-current h-5 w-5 text-red-700 mr-2" viewBox="0 0 20 20">
              <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/>
            </svg>
            <p>Please fill required fields.</p>
          </div>
        )}
        <div className="flex justify-center items-center px-2">
          <Button 
            loading={loading} 
            size="large" 
            type="success"
            onClick={(e) => setShowFormValidation(!isFormValid)}
          >
            {cta || "Continue"}
          </Button>
        </div>
        </div>
      </CardBody>
    </Card>
  </form>
  );
};

const formName = "setup-details";
const mapStateToProps = (state, props) => {
  const formErrors = getFormSyncErrors(formName)(state);
  const isFormValid = Object.keys(formErrors).length === 0;
  return {
    formErrors,
    isFormValid,
  };
};

const ConnectedForm = connect(mapStateToProps)(reduxForm({
  form: formName,
  enableReinitialize: true,
})(Form));

const Details = ({ onComplete, cta }) => {
  const [loading, setLoading] = useState(false);
  const [sameAddress, setSameAddress] = useState(true);

  const [registeredBusinessCountry, setRegisteredBusinessCountry] = useState(
    null
  );
  const [registeredBusinessStates, setRegisteredBusinessStates] = useState([]);

  const [postalAddressCountry, setPostalAddressCountry] = useState(null);
  const [postalAddressStates, setPostalAddressStates] = useState([]);
  const [customerType, setCustomerType] = useState("publisher");
  const [companyRegistrationId, setCompanyRegistrationId] = useState("");
  const [companyTaxId, setCompanyTaxId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(null);

  const [error, setError] = useState(null);
  const user = useUser();
  const dispatch = useDispatch();

  const rawCountries = countryHandle.getCountries();
  const countries = [
    ...rawCountries.filter((a) => a.shortName === "GB" || a.shortName === "US"),
    ...rawCountries
      .filter((a) => a.shortName !== "GB" && a.shortName !== "US")
      .sort((a, b) => a.name.localeCompare(b.name)),
  ];

  useEffect(() => {
    
    if (user.business) {
      let _registeredBusinessCountry = countries.find(
        (c) => c.name === user.business.registered_business_address_country
      );
      setRegisteredBusinessCountry(
        _registeredBusinessCountry ? _registeredBusinessCountry : []
      );

      if (_registeredBusinessCountry) {
        let _registeredBusinessStates = countryHandle.getStatesByShort(
          _registeredBusinessCountry.shortName
        );
        setRegisteredBusinessStates(
          _registeredBusinessStates ? _registeredBusinessStates : []
        );
      }

      let _postalAddressCountry = countries.find(
        (c) => c.name === user.business.postal_address_country
      );
      setPostalAddressCountry(
        _postalAddressCountry ? _postalAddressCountry : []
      );
      if (_postalAddressCountry) {
        let _postalAddressStates = countryHandle.getStatesByShort(
          _postalAddressCountry.shortName
        );
        setPostalAddressStates(
          _postalAddressStates ? _postalAddressStates : []
        );
      }

      if(user.business.customer_type){
        setCustomerType(user.business.customer_type);
      }
      if(user.business.company_registration_id){
        setCompanyRegistrationId(user.business.company_registration_id);
      }
      if(user.business.company_tax_id){
        setCompanyTaxId(user.business.company_tax_id);
      }
      if(user.business.telephone_number){
        setPhoneNumber(user.business.telephone_number);
      }
    }
  
  }, []);

  const onChangeCountry = (type, countryName) => {
    let c = countries.find((c) => c.name === countryName);

    if (type === "registeredBusinessAddress") {
      setRegisteredBusinessCountry(c);
      setRegisteredBusinessStates(countryHandle.getStatesByShort(c.shortName));
    } else {
      setPostalAddressCountry(c);
      setPostalAddressStates(countryHandle.getStatesByShort(c.shortName));
    }
  };

  const onChangeCustomerType = (e) => {
    setCustomerType(e);
    if(e === "author"){
      setCompanyRegistrationId("NA");
      setCompanyTaxId("NA");
    }else{
      setCompanyRegistrationId(user?.business?.company_registration_id ?? "");
      setCompanyTaxId(user?.business?.company_tax_id ?? "");
    }
  };

  const handleSubmit = (values) => {
    
    setLoading(true);

    if (
      !values.hasOwnProperty(
        "postal_address_same_as_registered_business_address"
      ) ||
      values.postal_address_same_as_registered_business_address
    ) {
      values = {
        ...values,
        postal_address_same_as_registered_business_address: true,
        postal_address_street_1: values.registered_business_address_street_1,
        postal_address_street_2: values.registered_business_address_street_2,
        postal_address_city: values.registered_business_address_city,
        postal_address_state: values.registered_business_address_state,
        postal_address_zip: values.registered_business_address_zip,
        postal_address_country: values.registered_business_address_country,
        telephone_number: phoneNumber,
      };
    }

    const customerTrackingCode = localStorage.getItem("customerTrackingCode");
    let url = "portal/business/";
    if(customerTrackingCode){
      url += `?customer_tracking_code=${customerTrackingCode}`;
    }

    axios
      .post(url, values)
      .then((res) => {
        dispatch(updateUser(res.data));
        onComplete();
      })
      .catch((e) => setError(e))
      .finally(() => setLoading(false));
  };

  return (
    <>
      {error && (
        <div className="mb-4">
          <ApiError error={error} />
        </div>
      )}

      <ConnectedForm
        cta={cta}
        initialValues={{
          ...user.business, 
          email: user.email, 
          customer_type: customerType,
          company_registration_id: companyRegistrationId,
          company_tax_id: companyTaxId
        }}
        onSubmit={handleSubmit}
        loading={loading}
        sameAddress={sameAddress}
        onSameAddressChange={(e) => setSameAddress(!sameAddress)}
        countries={countries}
        registeredBusinessStates={registeredBusinessStates}
        postalAddressStates={postalAddressStates}
        onChangeCountry={onChangeCountry}
        onChangeCustomerType={(e) => onChangeCustomerType(e)}
        customerType={customerType}
        phoneNumber={phoneNumber}
        onChangePhoneNumber={(e) => setPhoneNumber(e)}
      />
    </>
  );
};

export default Details;
