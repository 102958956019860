import React, { useState } from "react";
import Button from "../../../components/Button";
import Card, { CardBody } from "../../../components/Card";
import Modal, { ModalHeader } from "../../../components/Modal";
import ContractAccept from "./ContractAccept";
import moment from "moment";
import ContractDecline from "./ContractDecline";
import { Icon } from "antd";
import contractContents from "./contractContents";

const Contract = ({ user, onComplete, onBack }) => {
  const [showAccept, setShowAccept] = useState(false);
  const [showDecline, setShowDecline] = useState(false);
  const [declined, setDeclined] = useState(false);

  const handleAccept = () => setShowAccept(true);
  const handleDecline = () => setShowDecline(true);
  const handleModalClose = () => {
    setShowDecline(false);
    setShowAccept(false);
  };
  const handleDeclineComplete = () => {
    setDeclined(true);
    handleModalClose();
  };

  return (
    <>
      <Modal show={showAccept} onClose={handleModalClose}>
        <ModalHeader onClose={handleModalClose}>Accept</ModalHeader>
        <ContractAccept onComplete={onComplete} />
      </Modal>

      <Modal show={showDecline} onClose={handleModalClose}>
        <ModalHeader onClose={handleModalClose}>Questions</ModalHeader>
        <ContractDecline onComplete={handleDeclineComplete} />
      </Modal>

      <div className="flex">
        <Card
          className="flex-grow"
          title="TERMS AND CONDITIONS
          FOR TEXT-TO-SPEECH PRODUCTION SERVICES AND
          USE OF DEEPZEN CUSTOMER PORTALS"
          headerActions={
            user.business_contract && (
              <div className="text-right italic text-gray-700 text-sm">
                Accepted at{" "}
                {moment(user.business_contract.created_at).format(
                  "YYYY-MM-DD HH:mm:ss"
                )}
              </div>
            )
          }
        >
          <CardBody>
            <div className="overflow-auto -m-4 px-5" style={{ maxHeight: 500 }}>
              {user.business_contract
                ? user.business_contract.contract
                : contractContents}
            </div>
          </CardBody>
        </Card>

        {user.business_contract && user.business_contract.specs.length > 0 && (
          <Card title="Specs" className="w-1/4 ml-4">
            <div className="border-b">
              {user.business_contract.specs.map((spec) => (
                <div key={spec.title} className="border-t flex px-6 py-2">
                  <strong className="block w-1/2">{spec.title}</strong>
                  <span className="block flex-grow text-right">
                    {spec.value}
                  </span>
                </div>
              ))}
            </div>
          </Card>
        )}
      </div>

      {!declined && !user.business_contract && (
        <div className="flex justify-between py-4">
          <Button onClick={onBack} size="large">
            <Icon type="left" style={{ marginRight: 4 }} /> Back
          </Button>

          <div>
            <Button size="large" className="mx-3" onClick={handleDecline}>
              I have questions
            </Button>
            <Button
              size="large"
              type="success"
              className="mx-3"
              onClick={handleAccept}
            >
              I accept
            </Button>
          </div>
        </div>
      )}

      {declined && (
        <div className="rounded-md bg-green-100 p-4 text-green-800 shadow-sm text-base mt-4">
          We will reach to you to as soon as possible to discuss further.
        </div>
      )}
    </>
  );
};

export default Contract;
