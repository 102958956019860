import React from "react";
import { browserHistory, Router } from "react-router";
import { Provider } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_KEY } from "./config";

const stripePromise = loadStripe(STRIPE_KEY);

class App extends React.Component {
  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const ref = urlParams.get("ref");
    if (ref) {
      localStorage.setItem("ref", ref);
    }

    const customerTrackingCode = urlParams.get("src");
    if (customerTrackingCode) {
      localStorage.setItem("customerTrackingCode", customerTrackingCode);
    }

  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <Elements stripe={stripePromise}>
        <Provider store={this.props.store}>
          <div className="min-h-screen bg-cool-gray-100">
            <Router history={browserHistory}>{this.props.routes()}</Router>
          </div>
        </Provider>
      </Elements>
    );
  }
}

export default App;
