import React, { useEffect } from "react";
import { AUTH_CLIENT_ID, AUTH_URL } from "../../../config";

const Auth = () => {
  useEffect(() => {
    const redirectUri = encodeURIComponent(
      `${window.location.origin}/auth/login/finish`
    );
    
    window.location = `${AUTH_URL}/oauth/authorize?client_id=${AUTH_CLIENT_ID}&redirect_uri=${redirectUri}&scope=read%2Cwrite&response_type=code`;
  }, []);

  return null;
};

export default Auth;
