import React, { useEffect, useState } from "react";
import PageHeader from "../../../components/PageHeader";
import Card, { CardBody } from "../../../components/Card";
import BasicsForm from "./BasicsForm";
import PaymentForm from "./PaymentForm";
import Epub from "../../../components/Epub";
import axios from "axios";
import { message } from "antd";
import { browserHistory } from "react-router";
import { Link } from "react-router";
import TextForm from "./TextForm";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useUser } from "../../Auth/modules/auth";
import Payment3D from "./Payment3D";
import Modal from "../../../components/Modal";
import { useDispatch } from "react-redux";
import { submit } from "redux-form";
import Contract from "./DistributionContract";
import DistributionInfo from "./DistributionInfo";
import TrainingVideos, { TRAINING_TYPE_CREATE_AUDIO_BOOK } from "../../../components/TrainingVideos";

const Create = () => {
  const [data, setData] = useState(null);
  const [epubData, setEpubData] = useState(null);
  const [textData, setTextData] = useState("");
  const [voices, setVoices] = useState([]);
  const [basicsLoading, setBasicsLoading] = useState(false);
  const [step, setStep] = useState("basics");
  const [paymentMethod, setPaymentMethod] = useState("credit_card");
  const [submitting, setSubmitting] = useState(false);
  const [cleanedText, setCleanedText] = useState(null);
  const [realWordCount, setRealWordCount] = useState(0);
  const [paymentIntentId, setPaymentIntentId] = useState(null);
  const [show3ds, setShow3ds] = useState(false);
  const [src3ds, setSrc3ds] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const user = useUser();
  const dispatch = useDispatch();
  const [showContract, setShowContract] = useState(false);
  const [showDistributionInfo, setShowDistributionInfo] = useState(false);
  const [projectId, setProjectId] = useState(null);

  useEffect(() => {
    axios
      .get("portal/voices/")
      .then((res) => setVoices(res.data))
      .catch((e) =>
        message.error("An error occurred while fetching voice options.")
      );
  }, []);

  const handleBasicsSubmit = (values) => {
    const file = values.file[0];

    setData(values);

    if (file.path.toLowerCase().endsWith(".epub")) {
      setStep("epub");
      setEpubData(values.file[0]);
    } else {
      setBasicsLoading(true);

      let formData = new FormData();
      formData.append("file", file);

      axios
        .post("portal/converter/", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          setBasicsLoading(false);
          setStep("text");

          let text = res.data.text.trim();

          if (values.opening_credits.length > 0) {
            text =
              `{{ Opening Credits }}\n\n${values.opening_credits}\n\n` + text;
          }

          if (values.closing_credits.length > 0) {
            text += `\n\n{{ Closing Credits }}\n\n${values.closing_credits}`;
          }

          setTextData(text);
        })
        .catch((e) => {
          setBasicsLoading(false);
          alert("An error occurred while converting your file.");
        });
    }
  };

  const handleTextReady = (cleanedText) => {
    let formData = new FormData();
    formData.append("title", data.title);
    formData.append("voice", data.voice);
    formData.append("isbn", data.isbn);
    formData.append("genre", data.genre);
    formData.append("edit_mode", data.edit_mode);
    formData.append("distribution", data.distribution ? "1" : "0");
    formData.append(
      "word_count",
      data.word_count.replace(",", "").replace(".", "")
    );
    formData.append("publication_date", data.publication_date);
    formData.append("opening_credits", data.opening_credits);
    formData.append("closing_credits", data.closing_credits);
    formData.append("original_file", data.file[0]);
    formData.append("text", cleanedText);

    function wordCount(text = "") {
      const words = text.trim().split(/\s+/g);

      if (!words[0].length) {
        return 0;
      }

      return words.length;
    }

    setCleanedText(cleanedText);
    setRealWordCount(wordCount(cleanedText));
    setData(formData);
    setStep("payment");
  };

  const handle3dSuccess = () => {
    setShow3ds(false);
    setSrc3ds(null);
    
    data.append("payment_intent_id", paymentIntentId);
    axios
      .post(`portal/projects/${projectId}/3d-approve/`, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        browserHistory.push(`/projects/${projectId}`);
      })
      .catch((e) => {
        setSubmitting(false);
        const err = e?.response?.data?.error?.non_field_errors;
        console.error(err);
        message.error(err ?? "An error occurred");
      });
  };

  const handlePaymentSubmit = async (values) => {
    data.append("customer_po_number", values.customer_po_number);
    data.append("payment_method", paymentMethod);

    if (paymentIntentId) {
      data.append("payment_intent_id", paymentIntentId);
    } else {
      data.delete("payment_intent_id");
    }

    if (!user.business.manual_billing && paymentMethod === "credit_card") {
      if (!stripe || !elements) {
        return;
      }

      const cardElement = elements.getElement(CardElement);

      // Use your card Element with other Stripe.js APIs
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      if (error) {
        message.error(`Error: ${error.message}`, 5);
        return;
      } else {
        data.append("stripe_source_id", paymentMethod.id);
      }
    }
    
    setSubmitting(true);
    
    axios
      .post("portal/projects/", data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        const data = res.data;
        if(res.status === 201){
          browserHistory.push(`/projects/${res.data.id}`);
        
        }else if (res.status === 200){
          setSubmitting(false);
          
          let errorMessage = "An error occurred.";
          try {
            if (data.non_field_errors) {
              errorMessage = data.non_field_errors;
            }

            if (data.payment_intent) {
              setPaymentIntentId(data.payment_intent.payment_intent_id);
              setSrc3ds(data.payment_intent.next_action.redirect_to_url.url);
              setProjectId(data.project_id);
              setShow3ds(true);
              return;
            }

            setPaymentIntentId(null);
          } catch (e) {
            setPaymentIntentId(null);
            console.error(e);
          }

          message.error(errorMessage);
        }
      })
      .catch((e) => {
        setSubmitting(false);
        const err = e?.response?.data?.error?.non_field_errors;
        console.error(err);
        message.error(err ?? "An error occurred");
        
      });
  };

  return (
    <div className="container">
      <Modal show={show3ds} contentClassName="bg-white m-8 w-full md:w-1/2">
        <Payment3D src={src3ds} onSuccess={handle3dSuccess} />
      </Modal>

       <Contract
        show={showContract}
        onComplete={(result) => {
          setShowContract(false);
        }}
      />

      <DistributionInfo
        show={showDistributionInfo}
        onClose={(result) => {
          setShowDistributionInfo(false);
        }}
        onClickSetupAgreement={(result) => {
          setShowDistributionInfo(false);
          setTimeout(() => {
              setShowContract(true);
          }, 200);
        }}
      />

      <PageHeader
        title="Create New Project"
        subTitle={
          <>
            <div className="text-lg font-medium my-2">
              How to create a new audio project?{" "}
            </div>
            <p className="mb-2">
                Please provide the information requested in the fields below. If this is the first project you are creating, you might find it helpful to review our production process {" "}
                <Link to="/help" className="text-blue-500 underline font-bold">
                here.
                </Link>
            </p>

            <p className="mb-2">
              Firstly, decide on the service option you’d like to use. You have two options: 
            </p>

            {step === "basics" && (
              <div>
                  <h3 className="font-medium leading-7 text-gray-900 text-lg">Managed Service</h3>
                  <p className="mt-2">
                      The managed service is more suitable for fiction. This is an end to end service which includes a pronunciation check, proofing review by DZ editors, customer quality review and 2 corrections stages, post-processing and mastering. The turn-around for this service is 1 to 2 weeks. The cost per finished hour is $129.00/ £99.00. 
                  </p>

                  <h3 className="font-medium leading-7 text-gray-900 text-lg mt-2">Automated Service</h3>
                  <div>
                      <p className="mt-2">
                        The automated service is more appropriate for non-fiction genres. After you’ve uploaded your ePub, we provide a full pronunciation check, make any necessary pronunciation corrections and then convert your ePub into audio. We post-process and master your audiobook to industry standard specifications so the title is ready for distribution. The cost is $69.00/ £49.00 per finished hour and we deliver files within 3 to 5 days. 
                      </p>
                      <p className="mt-2">
                        You’ll receive a price for your audio project which we calculate based on the number of words in your manuscript.
                      </p>
                      <p className="mt-2">
                        Don’t forget to add our distribution set up service to your project. You can find out more about the service {" "} 
                        <a className="underline font-bold text-blue-500" onClick={(e) => setShowDistributionInfo(true)}>here</a>. We charge an initial fee of $69/£49, then a yearly fee of $25/ £19.99 thereafter. 
                      </p>
                      <p className="mt-2">
                        To confirm the project, the balance is payable in advance by credit card or by bank transfer.
                      </p>
                      <p className="mt-2">
                        We’ll start production once the payment has been received. Generally we deliver books within a 5 day period for the automated service and 2 weeks for the managed service subject to you providing any information requested within the specified timelines given. 
                      </p>
                  </div>
              </div>

            )}
          </>
        }
      />

      {step === "payment" && (
        <Card>
          <CardBody>
            <PaymentForm
              text={cleanedText}
              editMode={data.get("edit_mode")}
              distribution={data.get("distribution")}
              wordCount={realWordCount}
              onSubmit={handlePaymentSubmit}
              paymentMethod={paymentMethod}
              setPaymentMethod={setPaymentMethod}
              loading={submitting}
            />
          </CardBody>
        </Card>
      )}

      {step === "basics" && (
        <Card>
          <CardBody>
            <BasicsForm
              loading={basicsLoading}
              voices={voices}
              onSubmit={handleBasicsSubmit}
            />
          </CardBody>
        </Card>
      )}

      {step === "epub" && (
        <div className="fixed left-0 right-0 top-0 bottom-0 bg-gray-50">
          <Epub
            openingCredits={data.opening_credits}
            closingCredits={data.closing_credits}
            data={epubData}
            onSuccess={handleTextReady}
          />
        </div>
      )}

      {step === "text" && (
        <div className="fixed left-0 right-0 top-0 bottom-0 bg-gray-50">
          <TextForm
            data={textData}
            loading={basicsLoading}
            onSuccess={handleTextReady}
          />
        </div>
      )}

      {step === "basics" && (
        <TrainingVideos 
          className="container mt-6"
          trainingType={TRAINING_TYPE_CREATE_AUDIO_BOOK}
        />
      )}
       

    </div>
  );
};

export default Create;
