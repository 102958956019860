import React, { Component } from 'react';
import { Icon } from "antd";

class RightArrow extends Component {
  render() {
    return(
      <div className="cursor-pointer" onClick={this.props.goToNextSlide}>
        <Icon type="caret-right" style={{fontSize: 32}} />
      </div>
    )
  }
}

export default RightArrow;