import React from "react";
import { reduxForm, Field } from "redux-form";
import { required } from "../../../../services/validations";
import TextAreaField from "../../../../components/Forms/TextAreaField";
import Button from "../../../../components/Button";
import Card, { CardBody } from "../../../../components/Card";
import RawText from "../../../../components/RawText";

const CustomerSampleReview = ({ project, handleSubmit }) => (
  <>
    <Card title="Sample" className="mb-5">
      <audio src={project.sample_wav} controls className="w-full" />
      <CardBody>
        <RawText>{project.sample_text}</RawText>
      </CardBody>
    </Card>
    <Card title="Send your review">
      <CardBody>
        <form onSubmit={handleSubmit}>
          <Field
            name="review"
            label="Review notes"
            component={TextAreaField}
            rows={5}
            validate={[required]}
          />

          <div className="clearfix mt-4">
            <Button
              htmlType="submit"
              size="large"
              type="primary"
              block
              className="mt-4"
            >
              Submit
            </Button>
          </div>
        </form>
      </CardBody>
    </Card>
  </>
);

export default reduxForm({
  form: "customer-sample-review",
})(CustomerSampleReview);
