import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { loginVerify } from "../modules/auth";

const Token = () => {
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");

  useEffect(() => dispatch(loginVerify(token)), []);

  return <div>Logging in with token...</div>;
};

export default Token;
