import React, { useState, useEffect } from "react";
import Search from "./Icons/Search";
import { useDebounce } from 'use-debounce';

const SearchBox = ({ placeholder, onChange, value }) => {
    const [text, setText] = useState(value || "");
    const [debouncedValue] = useDebounce(text, 500);

    useEffect(() => {
        onChange({ target: { value: debouncedValue } });
    }, [debouncedValue]);

    const handleChange = (e) => {
        setText(e.target.value);
    };

    return (
        <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <Search />
            </div>
            <input
                type="text"
                className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder={placeholder || "Search"}
                value={text}
                onChange={handleChange}
            />
        </div>
    );
};

export default SearchBox;
