import React from "react";
import { Field, reduxForm } from "redux-form";
import Button from "../../components/Button";
import TextField from "../../components/Forms/TextField";
import { email } from "../../services/validations";

const InviteForm = ({ handleSubmit }) => (
  <form className="p-4" onSubmit={handleSubmit}>
    <Field
      component={TextField}
      validate={[email]}
      label="E-mail address"
      name="email"
      autoFocus
    />

    <Button block type="success" className="mt-2">
      Invite
    </Button>
  </form>
);

export default reduxForm({
  form: "invite-sub-users",
})(InviteForm);
