import browserHistory from "react-router/lib/browserHistory";
import { createSlice } from "@reduxjs/toolkit";

const currentLocation = browserHistory.getCurrentLocation();

const locationSlice = createSlice({
  name: "location",
  initialState: {
    pathname: currentLocation.pathname,
  },
  reducers: {
    locationChange: (state, action) => {
      state.pathname = action.payload;
    },
  },
});

export default locationSlice.reducer;

const { locationChange } = locationSlice.actions;

export const updateLocation = (nextLocation) => (dispatch) => {
  dispatch(locationChange(nextLocation.pathname));
};
