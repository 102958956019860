import React from "react";
import wrap from "./wrap";

const Checkmark = () => (
  <path
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    d="M5 13l4 4L19 7"
  ></path>
);

export default wrap(Checkmark, "stroke");
