import React from "react";
import Card, { CardBody } from "../../../../components/Card";

const EditingSample = () => (
  <Card title="Editing Sample">
    <CardBody>Editing sample description..</CardBody>
  </Card>
);

export default EditingSample;
