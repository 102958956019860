export const statuses = {
  initial_linguistics_review: "Manuscript Review",
  customer_linguistics_input: "Pronunciation Guidance",
  // TODO: Conditional sample state
  // editing_sample: "Editing Sample",
  // customer_sample_review: "Customer Sample Review",
  editing_book: "Audiobook Creation",
  customer_proofing: "Corrections",
  making_corrections: "We're Working on Corrections",
  customer_proofing_review: "Corrections Review",
  finalizing_book: "Post-Processing and Mastering",
  book_delivered: "Download Your Audiobook Files",
};

export default statuses;
