import React, { useEffect, useState } from "react";

const TextForm = ({ data, onSuccess }) => {
  const [text, setText] = useState("");

  useEffect(() => setText(data), [data]);

  return (
    <div className="h-full md:flex">
      <div className="flex md:flex-1">
        <div className="bg-white shadow-md h-full w-full">
          <textarea
            className="w-full h-full form-input"
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
        </div>
      </div>
      <div className="flex md:flex-1 py-3 px-4 overflow-y-auto">
        <div>
        <h1 className="text-2xl font-bold">
          Prepare your manuscript for conversion
        </h1>

        <p className="mt-2">
          Please review the text to ensure that all content that needs to be
          converted to audio is included.
        </p>
        <p className="mt-2">
          You can use the text editor to make any final changes.
        </p>
        <p className="mt-2">
          Please add curly braces around chapter titles to indicate the start of
          a new chapter e.g.{" "}
          <strong>
            <u>{`{{Chapter One}}`}</u>
          </strong>
        </p>
        <p className="mt-2">
          Extra line breaks will not affect the final audio and can also be left
          in.
        </p>
        <p className="my-2">
          Special characters will be converted automatically.
        </p>

        <button
          onClick={() => onSuccess(text)}
          className="rounded-md block mt-3 py-1 text-white text-base text-center bg-indigo-600 hover:bg-indigo-700 active:bg-indigo-400 w-full"
        >
          Continue
        </button>
      </div>
      </div>
    </div>
  );
};

export default TextForm;
