import React from "react";

const FemaleIcon = () => (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" focusable="false">
        <path d="M27.5611 18.0298C27.2011 18.7698 26.8811 19.1998 26.8711 19.2098V19.2198C26.8711 19.2198 27.2011 18.7798 27.5611 18.0398C28.1511 18.3798 28.5411 18.9998 28.5511 19.7298C28.5511 19.7298 28.5511 19.7298 28.5511 19.7198C28.5511 18.9898 28.1511 18.3598 27.5611 18.0298Z" fill="white"></path>
        <path d="M22.7913 25.9698C22.8613 25.9298 22.9213 25.8798 22.9813 25.8398C22.9113 25.8798 22.8513 25.9298 22.7913 25.9698C22.6913 26.0398 22.5813 26.1098 22.4713 26.1698C22.2513 26.2898 22.0212 26.3898 21.7812 26.4698C22.0212 26.3798 22.2513 26.2898 22.4713 26.1698C22.5813 26.1098 22.6913 26.0398 22.7913 25.9698Z" fill="white"></path>
        <path d="M24.6611 24.24C25.2311 23.5 25.7211 22.64 26.1111 21.69H26.5911C27.0511 21.69 27.4811 21.52 27.8211 21.25C27.8211 21.24 27.8311 21.24 27.8311 21.23C28.2111 20.92 28.4711 20.47 28.5311 19.96C29.1211 18.68 29.4611 17.26 29.4611 15.76C29.4611 15.76 29.4611 15.76 29.4611 15.75C29.4611 17.26 29.1211 18.68 28.5311 19.94V19.95C28.4611 20.45 28.2111 20.9 27.8311 21.22C27.4911 21.5 27.0511 21.68 26.5811 21.68H26.1011C25.7211 22.63 25.2211 23.5 24.6411 24.24C24.6311 24.25 24.6211 24.26 24.6211 24.27C24.6311 24.26 24.6411 24.25 24.6411 24.24C24.6411 24.25 24.6511 24.24 24.6611 24.24Z" fill="white"></path>
        <path d="M24.9492 14.8699C23.1192 13.7299 22.1292 12.3399 21.7392 11.1499C21.1292 12.0499 20.3292 13.0299 19.3292 13.9099C17.0392 15.9299 13.9092 16.3499 13.5592 19.2099C13.5192 19.5199 13.5092 19.8099 13.5192 20.0699C13.5092 19.8099 13.5192 19.5299 13.5592 19.2199C13.9092 16.3599 17.0392 15.9499 19.3292 13.9199C20.3292 13.0399 21.1192 12.0599 21.7392 11.1599C22.1292 12.3599 23.1192 13.7399 24.9492 14.8799C26.6292 15.9199 26.9092 17.5699 26.9192 18.5099C26.9192 17.5799 26.6492 15.9199 24.9492 14.8699Z" fill="white"></path>
        <path d="M38 11.33C37.05 9.35 35.79 7.55 34.27 6C30.64 2.3 25.59 0 20 0C14.41 0 9.36 2.3 5.73 6C3.6 8.17 1.97 10.82 1 13.78C0.36 15.74 0 17.83 0 20C0 22.17 0.36 24.26 1 26.22C2.3 30.19 4.81 33.6 8.1 36.04C9.77 34 11.67 32.4 13.74 31.38C13.88 31.3 14.04 31.26 14.18 31.23C14.19 31.23 14.2 31.22 14.21 31.22C14.28 31.18 14.36 31.15 14.43 31.11C15.22 30.7 17.17 29.56 17.17 28.24C17.17 27.73 17.17 26.84 17.17 25.84C17.17 25.84 17.16 25.84 17.16 25.83C16.61 25.46 16.1 24.98 15.64 24.43C15.63 24.42 15.63 24.42 15.62 24.41C15.25 24.2 14.9 23.99 14.56 23.74C14.6 24.4 14.44 25.18 13.84 26.3C13.84 26.3 14.19 25.49 12.79 23.56C11.38 21.62 7.01 16.98 9.8 11.64C10.94 9.46 12.67 8.34 14.51 7.8C16.06 6.68 17.94 6 19.99 6C21.25 6 22.44 6.26 23.54 6.71C25.97 6.08 29.16 6.14 31.47 9.46C35.86 15.75 25.84 25.02 31 28.38C31 28.38 24.77 30.71 22.8 26.91C22.8 26.9 22.79 26.9 22.79 26.89C22.79 27.45 22.79 27.92 22.79 28.24C22.79 29.9 25.87 31.28 25.87 31.28H25.83C26.15 31.46 26.48 31.66 26.79 31.87C28.56 32.92 30.18 34.42 31.61 36.27C34.33 34.32 36.54 31.72 38 28.68C39.27 26.05 40 23.12 40 20.01C40 16.9 39.27 13.95 38 11.33Z" fill="white"></path>
        <path d="M29.4591 15.7499C29.4591 15.7599 29.4591 15.7599 29.4591 15.7599C29.4591 17.2599 29.1191 18.6799 28.5291 19.9599C28.4591 20.4699 28.2091 20.9199 27.8291 21.2299C27.8291 21.2399 27.8191 21.2399 27.8191 21.2499C27.8291 21.2399 27.8391 21.2399 27.8391 21.2299C27.0191 22.4799 25.9191 23.5099 24.6491 24.2499L24.6591 24.2399C24.6491 24.2399 24.6491 24.2499 24.6391 24.2499C24.6291 24.2599 24.6191 24.2699 24.6191 24.2799C24.1391 24.8999 23.5891 25.4299 22.9791 25.8399C22.9191 25.8799 22.8491 25.9299 22.7891 25.9699C22.7891 26.2899 22.7891 26.5999 22.7891 26.8899C22.7891 26.8999 22.7991 26.8999 22.7991 26.9099C24.7691 30.7099 30.9991 28.3799 30.9991 28.3799C25.8391 25.0199 35.8591 15.7499 31.4691 9.45987C29.1591 6.13987 25.9691 6.07987 23.5391 6.70987C26.9991 8.15987 29.4591 11.6599 29.4591 15.7499Z" fill="#969696"></path>
        <path d="M22.7887 25.9702C22.6887 26.0402 22.5787 26.1102 22.4688 26.1702C22.5787 26.1102 22.6787 26.0402 22.7887 25.9702Z" fill="#959595"></path>
        <path d="M15.5897 30.5898C16.9497 30.0498 18.4197 29.7498 19.9697 29.7498C22.0997 29.7498 24.0797 30.2998 25.8097 31.2598C25.8197 31.2598 25.8197 31.2698 25.8297 31.2698H25.8697C25.8697 31.2698 22.7897 29.8898 22.7897 28.2298C22.7897 27.9098 22.7897 27.4398 22.7897 26.8798C22.6697 26.6398 22.5597 26.3998 22.4697 26.1598C22.2497 26.2798 22.0197 26.3798 21.7797 26.4598C21.2597 26.6498 20.6997 26.7598 20.1097 26.7698C19.0397 26.7698 18.0597 26.4198 17.1697 25.8398C17.1697 26.8398 17.1697 27.7298 17.1697 28.2398C17.1697 29.5598 15.2097 30.6998 14.4297 31.1098C14.6597 30.9898 14.9097 30.8898 15.1397 30.7898C15.2997 30.7298 15.4397 30.6498 15.5897 30.5898Z" fill="white"></path>
        <path d="M15.5897 30.5898C16.9497 30.0498 18.4197 29.7498 19.9697 29.7498C22.0997 29.7498 24.0797 30.2998 25.8097 31.2598C25.8197 31.2598 25.8197 31.2698 25.8297 31.2698H25.8697C25.8697 31.2698 22.7897 29.8898 22.7897 28.2298C22.7897 27.9098 22.7897 27.4398 22.7897 26.8798C22.6697 26.6398 22.5597 26.3998 22.4697 26.1598C22.2497 26.2798 22.0197 26.3798 21.7797 26.4598C21.2597 26.6498 20.6997 26.7598 20.1097 26.7698C19.0397 26.7698 18.0597 26.4198 17.1697 25.8398C17.1697 26.8398 17.1697 27.7298 17.1697 28.2398C17.1697 29.5598 15.2097 30.6998 14.4297 31.1098C14.6597 30.9898 14.9097 30.8898 15.1397 30.7898C15.2997 30.7298 15.4397 30.6498 15.5897 30.5898Z" fill="url(#paint0_radial_5621_376819)"></path>
        <path d="M22.4688 26.1702C22.5588 26.4102 22.6687 26.6502 22.7887 26.8902C22.7887 26.6102 22.7887 26.2902 22.7887 25.9702C22.6787 26.0402 22.5787 26.1102 22.4688 26.1702Z" fill="#959595"></path>
        <path d="M22.4688 26.1702C22.5588 26.4102 22.6687 26.6502 22.7887 26.8902C22.7887 26.6102 22.7887 26.2902 22.7887 25.9702C22.6787 26.0402 22.5787 26.1102 22.4688 26.1702Z" fill="url(#paint1_radial_5621_376819)"></path>
        <path d="M26.7816 31.8698C26.4716 31.6598 26.1516 31.4598 25.8216 31.2798C25.8116 31.2798 25.8116 31.2698 25.8016 31.2698C24.0716 30.3098 22.0916 29.7598 19.9616 29.7598C18.4116 29.7598 16.9416 30.0598 15.5816 30.5998C15.4316 30.6598 15.2916 30.7298 15.1416 30.7998C14.9016 30.9098 14.6616 30.9998 14.4316 31.1198C14.3616 31.1598 14.2816 31.1898 14.2116 31.2298C14.2016 31.2298 14.1916 31.2398 14.1816 31.2398C14.0416 31.2698 13.8816 31.3198 13.7416 31.3898C11.6716 32.4098 9.77156 34.0098 8.10156 36.0498C11.4316 38.5198 15.5416 39.9998 20.0016 39.9998C24.3316 39.9998 28.3316 38.6098 31.6116 36.2698C30.1716 34.4198 28.5516 32.9198 26.7816 31.8698Z" fill="#A0A0A0"></path>
        <path d="M26.1006 21.69C25.7106 22.64 25.2206 23.5 24.6506 24.24L24.6406 24.25C25.9106 23.51 27.0106 22.48 27.8306 21.23C27.8206 21.24 27.8106 21.24 27.8106 21.25C27.4706 21.52 27.0506 21.69 26.5806 21.69H26.1006Z" fill="#848484"></path>
        <path d="M21.7792 26.4699C22.0192 26.3799 22.2492 26.2899 22.4692 26.1699C22.5792 26.1199 22.6892 26.0399 22.7892 25.9699C22.8592 25.9299 22.9192 25.8799 22.9792 25.8399C23.5792 25.4299 24.1392 24.8999 24.6192 24.2799C24.6292 24.2699 24.6392 24.2599 24.6392 24.2499C25.2192 23.5099 25.7092 22.6399 26.0992 21.6899H26.5792C27.0492 21.6899 27.4892 21.5099 27.8292 21.2299C28.2092 20.9099 28.4692 20.4599 28.5292 19.9599V19.9499C28.5392 19.8799 28.5492 19.7999 28.5492 19.7299C28.5492 18.9999 28.1592 18.3799 27.5592 18.0399C27.1992 18.7799 26.8692 19.2199 26.8692 19.2199V19.2099C26.8692 19.2099 26.9192 18.9399 26.9192 18.5199C26.9092 17.5799 26.6392 15.9299 24.9492 14.8899C23.1192 13.7499 22.1292 12.3699 21.7392 11.1699C21.1192 12.0699 20.3292 13.0499 19.3292 13.9299C17.0392 15.9499 13.9092 16.3699 13.5592 19.2299C13.5192 19.5399 13.5092 19.8199 13.5192 20.0799C13.5292 20.3399 13.5592 20.5799 13.5992 20.7999C14.0892 22.1699 14.7792 23.4299 15.6392 24.4299C15.6292 24.4299 15.6292 24.4199 15.6192 24.4199C15.6292 24.4299 15.6292 24.4299 15.6392 24.4399C16.0992 24.9899 16.6092 25.4699 17.1592 25.8399C17.1592 25.8399 17.1692 25.8399 17.1692 25.8499C18.0492 26.4399 19.0392 26.7799 20.1092 26.7799C20.6992 26.7699 21.2592 26.6599 21.7792 26.4699Z" fill="#C6C6C6"></path>
        <path d="M13.6088 20.79C13.5688 20.57 13.5388 20.33 13.5288 20.07C13.5188 19.81 13.5288 19.52 13.5688 19.21C13.9188 16.35 17.0488 15.94 19.3388 13.91C20.3388 13.03 21.1388 12.05 21.7488 11.15C22.1388 12.34 23.1288 13.73 24.9588 14.87C26.6488 15.91 26.9188 17.58 26.9288 18.51C26.9288 18.93 26.8788 19.2 26.8788 19.2C26.8888 19.19 27.2088 18.76 27.5688 18.02C28.1588 18.36 28.5588 18.98 28.5588 19.72C28.5588 19.72 28.5588 19.72 28.5588 19.73C28.5588 19.8 28.5488 19.88 28.5388 19.95C29.1288 18.68 29.4688 17.26 29.4688 15.76C29.4688 11.67 27.0188 8.17 23.5588 6.72C22.4488 6.26 21.2488 6 19.9888 6C17.9388 6 16.0588 6.68 14.5088 7.82C12.6688 8.36 10.9388 9.48 9.79881 11.66C7.00881 17 11.3788 21.64 12.7888 23.58C14.1888 25.51 13.8388 26.32 13.8388 26.32C14.4388 25.2 14.6088 24.42 14.5588 23.76C14.8888 24.01 15.2488 24.22 15.6188 24.43C15.6288 24.43 15.6288 24.44 15.6388 24.44C14.7888 23.42 14.0988 22.16 13.6088 20.79Z" fill="#8B8B8B"></path>
        <circle cx="20" cy="20" r="19" stroke="#C7E0F4" strokeWidth="2"></circle>
        <defs>
            <radialGradient id="paint0_radial_5621_376819" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(20.0195 28.5534) scale(4.55682 4.55222)">
                <stop stopColor="#B5B5B5"></stop>
                <stop offset="0.4479" stopColor="#B8B8B8"></stop>
                <stop offset="1" stopColor="#C2C2C2"></stop>
            </radialGradient>
            <radialGradient id="paint1_radial_5621_376819" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(20.0185 28.5538) scale(4.55682 4.55222)">
                <stop stopColor="#B5B5B5"></stop>
                <stop offset="0.4479" stopColor="#B8B8B8"></stop>
                <stop offset="1" stopColor="#C2C2C2"></stop>
            </radialGradient>
        </defs>
    </svg>
);

export default FemaleIcon;
