import React, { useState } from "react";
import Link from "react-router";
import logoIcon from "../../assets/logo-icon.png";
import { Transition } from "@headlessui/react";
import { Icon } from "antd";

const Navbar = () => {
    
    const [industriesMenu, setIndustriesMenu] = useState(false);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    
return ( 
    <div>
    <nav className="flex bg-gray-800">
        <div className="px-2 py-3 lg:px-32 w-full">
            <div className="relative flex items-center justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                    {/* Mobile menu button */}
                    <button onClick={()=> setMobileMenuOpen(!mobileMenuOpen)} className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-expanded="false">
                        <span className="sr-only">Open main menu</span>
                        {/* Icon when menu is closed. */}
                        {/*
                            Heroicon name: menu

                            Menu open: "hidden", Menu closed: "block"
                        */}
                        {!mobileMenuOpen && (<svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                                            </svg>
                        )}
                        {/* Icon when menu is open. */}
                        {/*
                            Heroicon name: x

                            Menu open: "block", Menu closed: "hidden"
                        */}
                        {mobileMenuOpen && (<svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                               <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                        )}
                    </button>
                </div>
                
                <div className="flex-1 flex items-center justify-center sm:justify-start">
                    <div className="flex-shrink-0 flex items-center justify-start w-1/4">
                        <img className="block lg:hidden w-auto" src="https://deepzen.io/wp-content/uploads/2020/05/brigtherlogo-300x64.png" alt="Workflow" />
                        <img className="hidden lg:block w-auto" src="https://deepzen.io/wp-content/uploads/2020/05/brigtherlogo-300x64.png" alt="Workflow" />
                    </div>
                    <div className="hidden sm:block sm:ml-6 w-3/4 flex justify-center items-center">
                        <div className="flex space-x-4 justify-center">
                            <a href="#" className="bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium">Home</a>
                            <div className="flex relative" onMouseEnter={() => setIndustriesMenu(true)} onMouseLeave={() => setIndustriesMenu(false)}>
                                <a href="javascript:void(0)" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium flex justify-center items-center">Industries
                                    <Icon type={industriesMenu ? "up" : "down"} style={{fontSize: 12, paddingLeft: 2}}/>
                                </a>
                                <Transition
                                    enter-active-class="transition ease-out duration-100 transform"
                                    enter-class="opacity-0 scale-95"
                                    enter-to-class="opacity-100 scale-100"
                                    leave-active-class="transition ease-in duration-75 transform"
                                    leave-class="opacity-100 scale-100"
                                    leave-to-class="opacity-0 scale-95"
                                    show={industriesMenu}
                                >

                                    <div  className="origin-top-right absolute left-0 mt-10 w-48 rounded-0 shadow-lg py-1 bg-gray-700 ring-1 ring-black ring-opacity-5" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
                                        <a href="#" className="block px-4 py-2 text-sm text-white hover:bg-gray-600" role="menuitem">Advertising</a>
                                        <a href="#" className="block px-4 py-2 text-sm text-white hover:bg-gray-600" role="menuitem">Corporate</a>
                                        <a href="#" className="block px-4 py-2 text-sm text-white hover:bg-gray-600" role="menuitem">Gaming</a>
                                        <a href="#" className="block px-4 py-2 text-sm text-white hover:bg-gray-600" role="menuitem">E-Learning</a>
                                        <a href="#" className="block px-4 py-2 text-sm text-white hover:bg-gray-600" role="menuitem">Narration</a>
                                        <a href="#" className="block px-4 py-2 text-sm text-white hover:bg-gray-600" role="menuitem">Publishing</a>
                                        <a href="#" className="block px-4 py-2 text-sm text-white hover:bg-gray-600" role="menuitem">Voiceover</a>
                                    </div>
                                </Transition>
                            </div>
                            <a href="#" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Product & Services</a>
                            <a href="#" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">About</a>
                            <a href="#" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Contact</a>
                            <div className="flex justify-center px-10">
                                <a href="#" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Sign In</a>
                                <a href="#" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Sign Up</a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        {/*
            Mobile menu, toggle classNames based on menu state.

            Menu open: "block", Menu closed: "hidden"
        */}
        <Transition
                enter-active-class="transition ease-out duration-100 transform"
                enter-class="opacity-0 scale-95"
                enter-to-class="opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75 transform"
                leave-class="opacity-100 scale-100"
                leave-to-class="opacity-0 scale-95"
                show={mobileMenuOpen}
        >
                <div  className="block sm:hidden origin-top-right absolute left-0 mt-11 w-full rounded-0 shadow-lg py-1 bg-gray-700 ring-1 ring-black ring-opacity-5" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
                    <a href="#" className="flex bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium">Home</a>
                    <a href="javascript:void(0)" onClick={() => setIndustriesMenu(!industriesMenu)} className="flex text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium items-center">
                        Industries<Icon type={industriesMenu ? "up" : "down"} style={{fontSize: 12, paddingLeft: 2}}/>
                    </a>
                     {industriesMenu && (<div  className="w-full" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
                        <a href="#" className="flex block px-8 py-2 text-sm text-white hover:bg-gray-600" role="menuitem">Advertising</a>
                        <a href="#" className="flex block px-8 py-2 text-sm text-white hover:bg-gray-600" role="menuitem">Corporate</a>
                        <a href="#" className="flex block px-8 py-2 text-sm text-white hover:bg-gray-600" role="menuitem">Gaming</a>
                        <a href="#" className="flex block px-8 py-2 text-sm text-white hover:bg-gray-600" role="menuitem">E-Learning</a>
                        <a href="#" className="flex block px-8 py-2 text-sm text-white hover:bg-gray-600" role="menuitem">Narration</a>
                        <a href="#" className="flex block px-8 py-2 text-sm text-white hover:bg-gray-600" role="menuitem">Publishing</a>
                        <a href="#" className="flex block px-8 py-2 text-sm text-white hover:bg-gray-600" role="menuitem">Voiceover</a>
                    </div>
                    )}
                    <a href="#" className="flex text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Product & Services</a>
                    <a href="#" className="flex text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">About</a>
                    <a href="#" className="flex text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Contact</a>
                    <a href="#" className="flex text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Sign In</a>
                    <a href="#" className="flex text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Sign Up</a>
                </div>            
                                    
        </Transition>
    </nav>

</div>
);
}

export default Navbar;