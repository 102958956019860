import React, { useRef, useState, useEffect } from "react";
import Play from "./Icons/Play";
import Pause from "./Icons/Pause";

const MiniPlayer = ({playerId, onPlayAudio, playingAudioId, src, className }) => {
	const ref = useRef();
	const [isPlaying, setIsPlaying] = useState(false);

	useEffect(() => {
		if(playerId !== playingAudioId){
			setIsPlaying(false);
		}
	},[playingAudioId]);

	const onPlay = () => { 
		setIsPlaying(true);
		if(onPlayAudio){
			onPlayAudio(playerId);
		}
	}

	return (
		<>
			<audio
				id={playerId}
				ref={ref}
				onPause={() => setIsPlaying(false)}
				onPlay={() => onPlay()}
				src={src}
            />
			<a
				className="text-cool-gray-700 mr-2 hover:cool-gray-500"
				onClick={(e) => {
					e.stopPropagation();
					ref.current[isPlaying ? "pause" : "play"]();
				}}
			>
				{!isPlaying && <Play size={5} className={className}/>}
				{isPlaying && <Pause size={5} className={className}/>}
			</a>
		</>
	);
};

export default MiniPlayer;
