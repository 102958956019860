import React from "react";
import wrap from "./wrap";

const X = () => (
  <path
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    d="M6 18L18 6M6 6l12 12"
  />
);

export default wrap(X, "stroke");
