import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { useSelector } from "react-redux";

export const AUTH_ACCESS_TOKEN_KEY = "AUTH_ACCESS_TOKEN";

export const useUser = () => {
  const user = useSelector((state) => state.auth.user);

  return user;
};

export const isSetupComplete = (user) => {
  return !!user.business_contract;
};

const authSlice = createSlice({
  name: "auth",
  initialState: {
    verified: false,
    user: null,
    loginLoading: false,
    loginError: null,
    protectRedirect: "/",
  },
  reducers: {
    authLogin: (state, action) => {
      state.loginLoading = true;
      state.loginError = null;
    },
    loginVerifySuccess: (state, action) => {
      state.verified = true;
      state.loginLoading = false;
      state.user = action.payload;
    },
    loginFail: (state, action) => {
      state.loginError = action.payload;
      state.loginLoading = false;
    },
    authLogout: (state, action) => {
      state.verified = true;
      state.user = null;
    },
    protectRedirect: (state, action) => {
      state.protectRedirect = action.payload;
    },
    updateUser: (state, action) => {
      state.user = { ...action.payload, profile: state.user.profile };
    },
  },
});

export default authSlice.reducer;
export const {
  authLogin,
  loginVerifySuccess,
  loginFail,
  authLogout,
  protectRedirect,
  updateUser,
} = authSlice.actions;

//Action Creaters
export const login = (code, customerTrackingCode) => (dispatch) => {
  dispatch(authLogin());
  const payload = {
    code,
    ref: localStorage.getItem("ref")
  };
  
  if(customerTrackingCode){
    payload["customerTrackingCode"] = customerTrackingCode;
  }

  axios
    .post("auth/token_oauth/", payload)
    .then((res) => {
      dispatch(loginVerify(res.data.access));
    })
    .catch((e) => dispatch(loginFail(e)));
};

export const loginVerify = (token) => (dispatch) => {
  const authHeader = "Bearer " + token;
  axios
    .get("portal/auth/check/", { headers: { Authorization: authHeader } })
    .then((res) => {
      localStorage.setItem(AUTH_ACCESS_TOKEN_KEY, token);
      axios.defaults.headers.common["Authorization"] = authHeader;
      dispatch(loginVerifySuccess(res.data));
    })
    .catch((e) => {
      // We got a unauthorized, which means user has been deleted
      if (e.response && e.response.status === 401) {
        dispatch(logout());
      }
    });
};

export const logout = () => (dispatch) => {
  localStorage.removeItem(AUTH_ACCESS_TOKEN_KEY);
  axios.defaults.headers.common["Authorization"] = null;
  dispatch(authLogout());
};

export const setProtectRedirect = (pathname) => (dispatch) => {
  dispatch(protectRedirect(pathname));
};

export const existAccessToken = () => {
  return localStorage.getItem(AUTH_ACCESS_TOKEN_KEY);
};
