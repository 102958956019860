import React, { useEffect, useState } from "react";
import { render } from "react-dom";
import useRecorder from "./useRecorder";
import Button from "../../components/Button";

const Recorder = ({forceRecord, defaultAudioURL, onAudioChange}) => {
  const [audioURL, base64Data, isRecording, recorded, startRecording, stopRecording, reset] = useRecorder(defaultAudioURL);
  
  useEffect(() => {
    onAudioChange(audioURL, base64Data);
  }, [audioURL]);

  useEffect(() => {
    if (forceRecord) {
        if(isRecording){
            stopRecording(true);
        }
    }
  }, [forceRecord]);
  

  return (
    <div className="w-72">
        {audioURL && (
            <div className="flex justify-center items center h-8">
                <audio src={audioURL} controls className="h-6"/>
            </div>
        )}

        {!audioURL && (
            <div className="flex justify-center items center h-8">
                <span className="">{isRecording ? "Recording..." : "Please repeat the word 3 times."}</span>
            </div>
        )}
        
        {!recorded && (
            <div className="flex justify-between mt-5 px-2">
                <Button onClick={startRecording} disabled={isRecording}>
                    Start recording
                </Button>
                <Button onClick={stopRecording} disabled={!isRecording}>
                    Stop recording
                </Button>
            </div>        
        )}
        
        {recorded && (
            <div className="flex justify-end mt-5 px-2">
                <Button onClick={reset}>
                    Reset
                </Button>
            </div>
        )}
    </div>
  );
}

export default Recorder;

