import { Icon } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { storedJwt } from "../../../..";
import ApiError from "../../../../components/ApiError";
import Button from "../../../../components/Button";
import Card from "../../../../components/Card";
import Loading from "../../../../components/Loading";
import { API_URL } from "../../../../config";

const NeedsPayment = ({ project }) => (
  <Card>
    <div className="text-center py-12">
      <h1 className="text-lg">
        This project will be activated after payment. Invoice can be downloaded
        below.
      </h1>

      <div className="mt-4">
        <a
          href={`${API_URL}/portal/projects/${project.id}/invoice/?token=${storedJwt}`}
          target="_blank"
        >
          <Button>
            <Icon type="download" />
            <span className="ml-3">Download invoice</span>
          </Button>
        </a>
      </div>
    </div>
  </Card>
);

export default NeedsPayment;
