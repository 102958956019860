import React from "react";
import loggedIn from "../../containers/loggedIn";
import Dashboard from "./Dashboard";
import Voices from "../Voices";
import Help from "../Help";
import Users from "../Users";
import ProjectsRoute from "../Projects";
import Header from "../../components/Header";
import Settings from "../Settings";
import Pricing from "../Pricing";
import ProfileSettings from "../ProfileSettings";
import PasswordReset from "../PasswordReset";

const Layout = ({ children }) => (
  <>
    <Header />
    <div className="flex flex-col flex-1 overflow-y-auto">{children}</div>
  </>
);

export default {
  path: "",
  component: Layout,
  indexRoute: { component: loggedIn(Dashboard) },
  childRoutes: [
    ProjectsRoute,
    { path: "voices", component: loggedIn(Voices) },
    { path: "settings", component: loggedIn(Settings)},
    { path: "users", component: loggedIn(Users) },
    { path: "help", component: Help },
    { path: "pricing", component: loggedIn(Pricing)},
    { path: "profile-settings", component: loggedIn(ProfileSettings)},
    { path: "password-reset", component: PasswordReset},
  ],
};
