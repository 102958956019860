import React from "react";
import Button from "../../../components/Button";
import Modal, { ModalHeader } from "../../../components/Modal";

const DistributionInfo = ({ show, onClose, onClickSetupAgreement }) => {
  const handleClose = () => onClose(true);
  const handleSetupAgreement = () => onClickSetupAgreement(true);
  
  return (
    <>
      <Modal
        show={show}
        onClose={handleClose}
        contentClassName="bg-white m-8 w-3/4"
      >
        <ModalHeader onClose={handleClose}>
          Distribution Setup Service
        </ModalHeader>
        <div className="flex flex-col">
          <div
            className="p-4 border-b"
            style={{ maxHeight: 400, overflowX: "hidden", overflowY: "auto" }}
            itemScope
          >

          <p className="mb-2">
            In addition to audiobook production, DeepZen can help you to set up global distribution to the majority of vendors and digital service providers globally. 
            <br/>We have relationships with trusted partners that enable you to sell your audiobooks on more than 50 channels globally. These include Apple Books, Spotify, Deezer, Google Play, Kobo, Kobo, Scribd, Storytel and Bibliotheca.  
          </p>

          <p className="mb-2">
            We charge an initial fee of $69/ £49 for set up, and a yearly fee of $24.99/ £19.99. Most importantly, you retain 100% of net receipts. We manage delivery of assets and metadata to distributors, and manage all reporting and payments to you twice yearly. For full terms and conditions, 
            click {" "}<a className="underline text-blue-500 font-bold" onClick={handleSetupAgreement}>here</a>. 
          </p>

          <h3 className="text-md pr-10 mb-2 leading-6 font-medium text-gray-900">
            Why set up your distribution with DeepZen? 
          </h3>

          <ol className="list-disc ml-8 mb-2">
            <li>
                <span className="underline text-gray-900">Ease</span>
                <br/>
                <p className="mb-2">
                    Setting up distribution can be time consuming and, with so many different business models emerging, complex. With DeepZen, simply upload your metadata and cover image in the portal and we’ll do the rest when your audiobook is ready for distribution. 
                </p>
            </li>
            <li>
                <span className="underline text-gray-900">Transparency</span>
                <br/>
                <p className="mb-2">
                    We’ll provide you with regular sales reports that detail sales,  territory, vendor name and type, business model, distributor fees and your share of revenue.                     
                </p>
            </li>
            <li>
                <span className="underline text-gray-900">Best Practice</span>
                <br/>
                <p className="mb-2">
                    DeepZen’s audiobooks are quality approved on over 50 vendors. We have developed best practice guidance for identifying a digital voice in metadata to ensure your audiobooks are accepted by the majority of global vendors.
                </p>
            </li>
          </ol>

          <p className="mb-2 underline text-gray-900">
            Don’t forget to check the distribution setup box when you’re selecting your preferred service option in the project setup page. We can currently only enable distribution setup at this stage of the process. 
          </p>
            
          </div>

          <div className="flex flex-row-reverse justify-between py-4">
            <div>
              <Button
                size="large"
                type="danger"
                className="mx-3"
                onClick={handleClose}
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DistributionInfo;
