import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { AUTH_URL } from "../../config";
import { logout } from "./modules/auth";

const Logout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout());
    window.location.href = `${AUTH_URL}/accounts/logout?next=${window.location.origin}/`;
  }, []);

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="bg-white shadow rounded flex flex-col w-96 py-6 items-center">
        <svg
          className="w-14 h-14 text-green-500"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
          ></path>
        </svg>
        <div className="text-xl mt-2">You have successfully logged out.</div>
      </div>
    </div>
  );
};

export default Logout;
