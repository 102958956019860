import React from "react";

const checkIcon = (
  <svg
    className="w-6 h-6 text-white"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
      clipRule="evenodd"
    />
  </svg>
);

const HorizontalSteps = ({ steps, activeStepIndex, onChange }) => {
  return (
    <nav className="border-b border-t bg-white shadow-sm">
      <div className="container">
        <ol className="border-gray-300 divide-y divide-gray-300 md:flex md:divide-y-0 -ml-6">
          {steps.map((step, i) => {
            const canOpenTab =
              step.complete || activeStepIndex === i || steps[i - 1].complete;

            return (
              <li key={step.title} className="relative md:flex-1 md:flex">
                {activeStepIndex !== i &&
                  (activeStepIndex > i || step.complete) && (
                    <>
                      {/*<!-- Completed Step -->*/}
                      <a
                        onClick={() => onChange(step)}
                        className="group flex items-center w-full"
                      >
                        <span className="px-6 py-4 flex items-center text-sm font-medium">
                          <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-blue-600 rounded-full group-hover:bg-blue-800">
                            {checkIcon}
                          </span>
                          <span className="ml-4 text-sm font-medium text-gray-900">
                            {step.title}
                          </span>
                        </span>
                      </a>

                      <div className="hidden md:block absolute top-0 right-0 h-full w-5">
                        <svg
                          className="h-full w-full text-gray-300"
                          viewBox="0 0 22 80"
                          fill="none"
                          preserveAspectRatio="none"
                        >
                          <path
                            d="M0 -2L20 40L0 82"
                            vectorEffect="non-scaling-stroke"
                            stroke="currentcolor"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </>
                  )}

                {i === activeStepIndex && (
                  <>
                    {/*<!-- Current Step -->*/}
                    <a
                      onClick={() => onChange(step)}
                      className="px-6 py-4 flex items-center text-sm font-medium"
                    >
                      <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-blue-600 rounded-full">
                        <span className="text-blue-600">0{i + 1}</span>
                      </span>
                      <span className="ml-4 text-sm font-medium text-blue-600">
                        {step.title}
                      </span>
                    </a>

                    <div className="hidden md:block absolute top-0 right-0 h-full w-5">
                      <svg
                        className="h-full w-full text-gray-300"
                        viewBox="0 0 22 80"
                        fill="none"
                        preserveAspectRatio="none"
                      >
                        <path
                          d="M0 -2L20 40L0 82"
                          vectorEffect="non-scaling-stroke"
                          stroke="currentcolor"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </>
                )}

                {!step.complete && activeStepIndex < i && (
                  <>
                    {/*<!-- Upcoming Step -->*/}
                    <a
                      onClick={canOpenTab && (() => onChange(step))}
                      className="flex items-center"
                    >
                      <span className="px-6 py-4 flex items-center text-sm font-medium">
                        <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full">
                          <span className="text-gray-500">0{i + 1}</span>
                        </span>
                        <span className="ml-4 text-sm font-medium text-gray-500">
                          {step.title}
                        </span>
                      </span>
                    </a>
                  </>
                )}
              </li>
            );
          })}
        </ol>
      </div>
    </nav>
  );
};

export default HorizontalSteps;
