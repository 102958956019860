import React, { useEffect, useCallback } from "react";
import ReactDOM from "react-dom";
import { Transition } from "@headlessui/react";

const emptyObj = {};

const getPositionClassNames = (position) => {
  let classNames;
  switch (position) {
    case "top":
      classNames = "items-start";
      break;
    case "center":
      classNames = "items-center";
      break;
    case "bottom":
      classNames = "items-end";
      break;

    default:
      classNames = "items-center";
      break;
  }

  return classNames;
};

const Modal = ({
  children,
  onClose = () => {},
  show = true,
  contentClassName = "bg-white m-8 w-full md:w-1/2 lg:w-1/3 xl:w-1/4",
  position = "center",
  contentStyle = emptyObj,
  contentOverflowClass = "overflow-hidden",
  ...rest
}) => {
  const onKeyDown = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown, false);

    return () => {
      document.removeEventListener("keydown", onKeyDown, false);
    };
  }, [onKeyDown]);

  return ReactDOM.createPortal(
    <Transition
      {...rest}
      className="fixed z-30 inset-0 overflow-y-auto"
      show={show}
    >
      <div
        className={`flex justify-center min-h-screen text-center ${getPositionClassNames(
          position
        )}`}
      >
        <Transition.Child
          onClick={onClose}
          className="fixed inset-0 transition-opacity"
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </Transition.Child>
        {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
        &#8203;
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          className={`inline-block rounded-lg text-left ${contentOverflowClass} shadow-xl transform transition-all align-middle ${contentClassName}`}
          style={contentStyle}
          role="dialog"
          aria-modal={true}
        >
          {children}
        </Transition.Child>
      </div>
    </Transition>,
    document.body
  );
};

export default Modal;
