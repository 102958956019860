import React, { Component } from "react";

class AudioPlayer extends Component {
  audioEl = null;

  componentDidMount() {
    this.audioEl.addEventListener(
      "ended",
      () => this.props.onEndAudio && this.props.onEndAudio()
    );
    this.audioEl.addEventListener(
      "play",
      () => this.props.onPlayAudio && this.props.onPlayAudio()
    );
    this.audioEl.addEventListener("pause", () => {
      if (this.audioEl && this.props.onPauseAudio) {
        this.props.onPauseAudio(this.audioEl.currentTime);
      }
    });
    this.setupPlayer();
  }

  componentDidUpdate(oldProps) {
    if (oldProps.wavUrl !== this.props.wavUrl) {
      this.setupPlayer();
    }
    if (this.props.pauseAudio) {
      this.audioEl.pause();
    }
  }

  setupPlayer() {
    this.audioEl.src = this.props.wavUrl;

    if (this.props.autoPlay) {
      this.audioEl.play();
    }
  }

  render() {
    return (
      <audio
        className="w-full h-8"
        preload="true"
        ref={(ref) => (this.audioEl = ref)}
        controls
      />
    );
  }
}

export default AudioPlayer;
