import React from "react";
import Button from "../../../components/Button";
import { Icon } from "antd";
import PricingCard from "../../Pricing/PricingCard";

const Pricing = ({ onComplete, onBack }) => {
  return (
    <>
      <PricingCard />

      <div className="flex justify-between mt-4">
        <Button onClick={onBack} className="mt-4" size="large">
          <Icon type="left" style={{ marginRight: 4 }} /> Back
        </Button>

        <Button
          onClick={onComplete}
          className="mt-4"
          size="large"
          type="success"
        >
          Continue
        </Button>
      </div>
    </>
  );
};

export default Pricing;
