import React, { useState } from "react";
import Link from "react-router";
import { Slider } from "antd";
import "./styles.scss";

const Content = () => {
    
const [ sliderValue, setSliderValue ] = useState(50000);  

let prices = {};
for (let i = 1000; i <= 150000; i = i + 1000){
    prices[i] = "$"+ (i + (i * 0.25));
} 

return ( 
    <div>
        <div className="flex bg-gray-800 px-2 py-10 lg:py-20 lg:px-4 w-full justify-center">
            <div className="max-w-screen-lg">
                <h2 className="text-white text-center text-xl lg:text-6xl">How much does it cost to make an audiobook?</h2>
                <p className="text-white text-center text-base pt-5">An average audiobook created with Deepzen has about 50,000 words and cost between $1,000. We can estimate the cost of your audiobook by multiplying a per finished hour narrator rate with the estimated length of your finished recording. The longer the book, the higher the estimate will be.</p>
            </div>
        </div>
        <div className="flex w-full justify-center">
            <div className="max-w-screen-lg px-2 py-10 lg:px-20">
                <p className="text-center text-base">A professional narrator will be contracted for your audiobook
                    production. After learning a bit about your book, our casting team will provide you with a list of recommended
                    performers at a range of different prices. You get to choose the talent that best fits your needs.</p>
                <p className="text-center text-base pt-5">Play with the numbers now.</p>    
            </div>
        </div>
        <div className="max-w-screen-lg px-2 py-3 lg:px-20 w-full mx-auto">
            <p className="text-left pl-1 text-lg">Number of words in your manuscript : <span className="font-bold">{sliderValue}</span></p>
            <Slider 
                defaultValue={50000}
                min={1000} 
                max={150000} 
                step={1000}
                tooltipVisible={false} 
                onChange={(value) => setSliderValue(value)}
                className="custom-slider" 
            />
            <p className="text-right text-lg">Your estimate: <span className="font-bold">{prices[sliderValue]}</span></p>
        </div>
    
        <div className="max-w-screen-lg px-2 py-15 lg:px-20 w-full mx-auto text-center">
            <button className="text-white lg:text-lg px-10 py-5 rounded-full bg-gray-800 border-none focus:border-none focus:outline-none">
                Create Your Audiobook
            </button>
        </div>

        <div className="flex w-full justify-center">
            <div className="max-w-screen-lg px-2 pb-10 lg:py-10 lg:px-20">
                <h3 className="text-center text-2xl">Once you have your audiobook, what's next?</h3>
                <p className="text-center text-base pt-5">We take complexity out of distribution and sales. 
                    <a href="" className="text-red-600 ml-2 underline">Learn more here</a>
                </p>    
            </div>
        </div>

        <div className="flex w-full justify-center bg-gray-200">
            <div className="max-w-screen-lg px-2 py-10 lg:py-15 lg:px-20 text-center">
                <h1 className="text-3xl lg:text-5xl">Get Started Today</h1>
                <p className="text-base lg:text-lg pt-5">Sign up now to be matched with a narrator, or to upload your audiobook for distribution.</p>    
                <button className="text-white mt-10 lg:text-lg px-10 py-5 rounded-full bg-gray-800 border-none focus:border-none focus:outline-none">
                    Sign Up Now
                </button>
            </div>
        </div>

    </div>
);
}

export default Content;