import React from "react";

const MaleIcon = () => (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" focusable="false">
        <path d="M20 0C8.95 0 0 8.95 0 20C0 20.7 0.04 21.4 0.11 22.08C0.13 22.24 0.16 22.4 0.18 22.56C0.25 23.08 0.32 23.6 0.42 24.11C0.45 24.25 0.49 24.38 0.53 24.52C0.65 25.04 0.78 25.57 0.94 26.07C0.97 26.17 1.01 26.26 1.05 26.36C1.23 26.9 1.43 27.44 1.66 27.96C1.68 28.01 1.71 28.07 1.74 28.12C1.99 28.68 2.26 29.23 2.56 29.76C2.57 29.78 2.58 29.79 2.59 29.81C3.56 31.52 4.78 33.08 6.19 34.44C8.16 32.64 9.96 31.75 10.76 31.43C11.93 30.72 13.26 30.14 14.68 29.71C14.68 29.7 14.68 29.69 14.68 29.69H14.69L14.68 29.68C14.68 29.68 14.8 29.65 14.98 29.58C15.29 29.41 16.44 28.69 16.5 26.67L17.02 26.8C17.02 26.37 17.02 25.67 17.02 24.88C15.47 23.85 14.23 22.2 13.83 20.25H13.28C12.04 20.25 11.04 19.25 11.04 18.04C11.04 17.18 11.52 16.46 12.24 16.08C12.24 16.07 12.24 16.07 12.24 16.06C12.24 16.03 12.24 15.99 12.24 15.97C12.23 15.92 12.23 15.86 12.22 15.81C11.28 15.45 10.51 14.84 10.13 14.07C10.13 14.07 10.96 14.51 11.59 13.33C12.21 12.15 12.11 8.07 16.46 6.88C16.64 6.84 16.82 6.8 17 6.78C18.04 6.27 19.21 6 20.46 6C25.09 6 28.82 9.73 28.8 14.34V14.56C28.8 15.04 28.76 15.5 28.68 15.95C28.68 15.98 28.68 16.02 28.68 16.04C28.68 16.05 28.68 16.05 28.68 16.06C28.48 17.24 27.63 18.19 27.57 18.25V18.26C27.57 18.26 28.49 17.27 28.69 16.06C29.4 16.43 29.91 17.18 29.92 18.03C29.92 19.25 28.91 20.24 27.68 20.24H27.12C26.73 22.11 25.58 23.72 24.11 24.75C24.11 25.58 24.11 26.32 24.11 26.77L24.55 26.66C24.61 28.43 25.49 29.2 25.92 29.47C26.22 29.6 26.45 29.67 26.45 29.67C26.45 29.67 26.42 29.7 26.4 29.71C27.82 30.14 29.14 30.73 30.32 31.43C31.07 31.75 32.59 32.5 34.3 33.96C35.62 32.61 36.75 31.07 37.65 29.39C37.66 29.37 37.66 29.36 37.67 29.34C37.96 28.8 38.23 28.24 38.46 27.67C38.49 27.59 38.51 27.51 38.55 27.44C38.76 26.92 38.95 26.4 39.12 25.87C39.17 25.7 39.2 25.53 39.25 25.36C39.38 24.9 39.51 24.45 39.6 23.98C39.66 23.67 39.7 23.34 39.75 23.03C39.8 22.69 39.87 22.36 39.9 22.02C39.96 21.36 40 20.68 40 20C40 8.95 31.05 0 20 0Z" fill="white"></path>
        <path d="M18.0094 14.7399C16.8994 15.8099 15.4694 16.2499 14.1194 16.2099C13.3794 17.2599 13.3594 18.1899 13.3594 18.2599L13.3694 18.2699C13.3694 18.2699 13.3594 17.3099 14.1294 16.2099C15.4794 16.2499 16.8994 15.8299 18.0194 14.7399C20.5194 12.3099 23.1494 11.1299 23.7394 10.8799C23.1594 11.1099 20.5194 12.2999 18.0094 14.7399Z" fill="white"></path>
        <path d="M26.3819 29.6802V29.7002C26.3919 29.7002 26.3919 29.7002 26.4019 29.7102C26.4219 29.7002 26.4519 29.6702 26.4519 29.6702C26.4519 29.6702 26.2219 29.6102 25.9219 29.4702C26.0519 29.5602 26.1419 29.6002 26.1519 29.6002L26.3819 29.6802Z" fill="white"></path>
        <path d="M26.3819 29.6802V29.7002C26.3919 29.7002 26.3919 29.7002 26.4019 29.7102C26.4219 29.7002 26.4519 29.6702 26.4519 29.6702C26.4519 29.6702 26.2219 29.6102 25.9219 29.4702C26.0519 29.5602 26.1419 29.6002 26.1519 29.6002L26.3819 29.6802Z" fill="url(#paint0_radial_5621_376820)"></path>
        <path d="M20.5117 27.73L20.5317 27.72V27.71L24.1117 26.77C24.1117 26.32 24.1117 25.58 24.1117 24.75C23.0117 25.52 21.7417 25.98 20.4717 25.98C19.2717 25.98 18.0617 25.57 17.0117 24.87C17.0117 25.66 17.0117 26.36 17.0117 26.79L20.5217 27.71L20.5117 27.73Z" fill="white"></path>
        <path d="M20.5117 27.73L20.5317 27.72V27.71L24.1117 26.77C24.1117 26.32 24.1117 25.58 24.1117 24.75C23.0117 25.52 21.7417 25.98 20.4717 25.98C19.2717 25.98 18.0617 25.57 17.0117 24.87C17.0117 25.66 17.0117 26.36 17.0117 26.79L20.5217 27.71L20.5117 27.73Z" fill="url(#paint1_radial_5621_376820)"></path>
        <path d="M14.9719 29.5698C14.8019 29.6298 14.6719 29.6698 14.6719 29.6698L14.6819 29.6798L14.9219 29.5998C14.9419 29.5898 14.9519 29.5898 14.9719 29.5698Z" fill="white"></path>
        <path d="M14.9719 29.5698C14.8019 29.6298 14.6719 29.6698 14.6719 29.6698L14.6819 29.6798L14.9219 29.5998C14.9419 29.5898 14.9519 29.5898 14.9719 29.5698Z" fill="url(#paint2_radial_5621_376820)"></path>
        <path d="M24.1095 24.7601C25.5795 23.7301 26.7395 22.1201 27.1195 20.2501H27.6795C28.9095 20.2501 29.9195 19.2501 29.9195 18.0401C29.9095 17.1901 29.3995 16.4401 28.6895 16.0701C28.4895 17.2901 27.5695 18.2701 27.5695 18.2701V18.2601L27.5595 18.2701C27.5595 18.2701 27.5795 17.1901 26.6695 16.0301C26.1195 15.3301 25.1795 14.7901 24.3795 13.2201C23.8995 12.2801 23.7695 11.4301 23.7295 10.8901C23.1295 11.1401 20.5095 12.3201 18.0095 14.7501C16.8895 15.8301 15.4695 16.2601 14.1195 16.2201C13.3495 17.3201 13.3595 18.2801 13.3595 18.2801L13.3495 18.2701V18.2801C13.3495 18.2801 12.4395 17.3101 12.2195 16.0901C11.5095 16.4701 11.0195 17.1901 11.0195 18.0501C11.0195 19.2701 12.0195 20.2601 13.2595 20.2601H13.8095C14.2095 22.2101 15.4495 23.8601 16.9995 24.8901C18.0595 25.5901 19.2595 26.0001 20.4595 26.0001C21.7395 25.9801 23.0095 25.5301 24.1095 24.7601Z" fill="#C6C6C6"></path>
        <path d="M13.3616 18.25C13.3616 18.19 13.3716 17.25 14.1216 16.2C15.4716 16.24 16.9016 15.81 18.0116 14.73C20.5216 12.29 23.1616 11.11 23.7416 10.86C23.7716 11.4 23.9116 12.25 24.3916 13.19C25.1916 14.77 26.1316 15.3 26.6816 16C27.5916 17.16 27.5716 18.24 27.5716 18.24L27.5816 18.23C27.6416 18.17 28.4916 17.23 28.6916 16.04C28.6916 16.03 28.6916 16.03 28.6916 16.02C28.6916 16 28.6916 15.97 28.6916 15.93C28.7716 15.48 28.8116 15.02 28.8116 14.54V14.32C28.8216 9.73 25.0916 6 20.4616 6C19.2116 6 18.0416 6.27 16.9716 6.77C16.7916 6.79 16.6116 6.82 16.4316 6.87C12.0816 8.06 12.1916 12.14 11.5616 13.32C10.9416 14.5 10.1016 14.06 10.1016 14.06C10.4816 14.83 11.2516 15.44 12.1916 15.8C12.2016 15.85 12.2016 15.91 12.2116 15.96C12.2116 15.98 12.2116 16.01 12.2116 16.05C12.2116 16.06 12.2116 16.06 12.2116 16.07C12.4316 17.28 13.3416 18.26 13.3416 18.26L13.3616 18.25Z" fill="#8B8B8B"></path>
        <path d="M26.3997 29.7102C26.3897 29.7102 26.3897 29.7102 26.3797 29.7002V29.6802L26.1497 29.6002L21.7497 31.0602L20.5297 27.7202L20.5097 27.7302L19.2897 31.0802L14.8897 29.6202C14.8897 29.6202 14.9097 29.6102 14.9197 29.6002L14.6797 29.6802H14.6697C14.6697 29.6802 14.6697 29.6902 14.6697 29.7002C13.2497 30.1302 11.9197 30.7102 10.7497 31.4202C9.94969 31.7402 8.15969 32.6302 6.17969 34.4302C9.77969 37.8802 14.6397 40.0002 19.9997 40.0002C25.6097 40.0002 30.6697 37.6902 34.2997 33.9702C32.5797 32.5102 31.0597 31.7502 30.3197 31.4402C29.1397 30.7302 27.8197 30.1402 26.3997 29.7102Z" fill="#A0A0A0"></path>
        <path d="M20.5312 27.7299L21.7512 31.0699L26.1512 29.6099C26.1512 29.6099 26.0612 29.5699 25.9212 29.4799C25.4912 29.1999 24.6112 28.4299 24.5512 26.6699L24.1112 26.7799L20.5312 27.7199V27.7299Z" fill="#DADADA"></path>
        <path d="M14.8906 29.6202L19.2906 31.0802L20.5106 27.7302L20.5206 27.7102L17.0106 26.7902L16.4906 26.6602C16.4206 28.6802 15.2706 29.4002 14.9706 29.5702C14.9406 29.5802 14.9406 29.5902 14.9306 29.6002C14.9206 29.6102 14.8906 29.6202 14.8906 29.6202Z" fill="#DADADA"></path>
        <circle cx="20" cy="20" r="19" stroke="#C7E0F4" strokeWidth="2"></circle>
        <defs>
            <radialGradient id="paint0_radial_5621_376820" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(20.5627 25.8546) scale(5.82048 5.81457)">
                <stop stopColor="#9F9F9F"></stop>
                <stop offset="0.5885" stopColor="#B8B8B8"></stop>
                <stop offset="1" stopColor="#C2C2C2"></stop>
            </radialGradient>
            <radialGradient id="paint1_radial_5621_376820" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(20.5625 25.8543) scale(5.82047 5.81457)">
                <stop stopColor="#9F9F9F"></stop>
                <stop offset="0.5885" stopColor="#B8B8B8"></stop>
                <stop offset="1" stopColor="#C2C2C2"></stop>
            </radialGradient>
            <radialGradient id="paint2_radial_5621_376820" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(20.5627 25.8541) scale(5.82047 5.81461)">
                <stop stopColor="#9F9F9F"></stop>
                <stop offset="0.5885" stopColor="#B8B8B8"></stop>
                <stop offset="1" stopColor="#C2C2C2"></stop>
            </radialGradient>
        </defs>
    </svg>
);

export default MaleIcon;
