import React from "react";
import classNames from "classnames";
import { Icon } from "antd";
import DropDownMenu from "./DropDownMenu";
import { Menu } from "@headlessui/react";

const sizeUnitMap = {
  large: 10,
  default: 8,
};

const generateSize = (category) => {
  const size = (category && sizeUnitMap[category]) || sizeUnitMap.default;

  return {width: 4 * size, height: 4 * size};
};

const menuOptions = [
  { name: "Business Settings", link: "/settings" },
  { name: "Logout", link: "/auth/logout" },
];

const Avatar = ({ user, size }) => {
  return user.avatar_url ? (
    <DropDownMenu
      menuItems={menuOptions}
      menuButton={
        <Menu.Button
          as="img"
          src={user.avatar_url}
          alt={user.first_name}
          className={classNames(
            "inline-block rounded-full text-white shadow-solid",
          )}
          style={generateSize(size)}
        ></Menu.Button>
      }
    />
  ) : (
    <DropDownMenu
      menuItems={menuOptions}
      menuButton={
        <Menu.Button
          as="span"
          className="inline-flex items-center cursor-pointer"
        >
          <div className="text-gray-200 font-medium mr-3 text-right leading-tight">
            {user.profile.first_name} {user.profile.last_name}
            <div className="text-gray-500 text-xs">{user.profile.email}</div>
          </div>
          <button
            className={classNames(
              "rounded-full text-gray-800 bg-cool-gray-400 font-medium shadow-solid flex justify-center items-center",
            )}
            style={generateSize(size)}
          >
            <Icon type="user" />
          </button>
        </Menu.Button>
      }
    />
  );
};

export default Avatar;
