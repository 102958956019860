export default [
  "Accounting",
  "Action Suspense",
  "Art, Architecture & Design",
  "Artists, Architects & Photographers",
  "BAME (Black, Asian & minority ethnic) ",
  "Business",
  "Business Reference",
  "Career Planning & Job Hunting",
  "Children's",
  "Classics",
  "Coming of Age",
  "Composers & Musicians ",
  "Computers",
  "Contemporary",
  "Contemporary Women",
  "Cozy Mysteries",
  "Crime",
  "Crime & Mystery",
  "Cultural Heritage",
  "Cultural, Ethnic & Regional",
  "Dystopia",
  "Economics",
  "Education",
  "Entertainment",
  "Entertainment & Performing Arts",
  "Entrepreneurship & Small Business",
  "Erotica",
  "Family & Relationships",
  "Family Life",
  "Fantasy",
  "Finance & Investing",
  "Food & Drink",
  "Games & Activities",
  "General",
  "Genre Fiction",
  "Hard-Boiled",
  "Health & Well Being",
  "Historical",
  "Historical Mystery",
  "Historical Romance",
  "History",
  "Home & Garden",
  "Horror",
  "Human Resources & Personnel Management",
  "Humorous",
  "Industries & Professions",
  "International",
  "Legal",
  "LGBT",
  "Literary",
  "Literary Theory & Criticism",
  "Management & Leadership",
  "Manga",
  "Marketing & Sales",
  "Media Tie-In",
  "Military",
  "Movie & Television Tie-Ins",
  "Native American & Aboriginal",
  "New Adult",
  "Non-Fiction",
  "Paranormal",
  "Personal Finance",
  "Philosophers",
  "Poetry",
  "Police Procedural",
  "Political ",
  "Psychological",
  "Reference",
  "Reference & Language",
  "Religion & Spirituality",
  "Religious",
  "Rich & Famous",
  "Romance",
  "Romantic Suspense",
  "Royalty",
  "Saga",
  "Science & Nature",
  "Science Fiction",
  "Science Fiction & Fantasy",
  "Short Stories & Anthologies",
  "Social & Cultural Studies",
  "Social Issues",
  "Space Opera",
  "Sports",
  "Steampunk",
  "Superheroes",
  "Thrillers",
  "Traditional",
  "Travel",
  "Westerns",
  "Women Sleuths",
  "YA",
];
