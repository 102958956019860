import React, { useEffect, useState } from "react";
import Card, { CardBody } from "./Card";
import { Icon, Tooltip } from "antd";
import Modal, { ModalHeader } from "./Modal";
import classNames from "classnames";

export const TRAINING_TYPE_ALL = 0;
export const TRAINING_TYPE_DISTRIBUTION_METADATA = 1;
export const TRAINING_TYPE_PRONUNCIATION_GUIDANCE = 2;
export const TRAINING_TYPE_INTRODUCTION = 3;
export const TRAINING_TYPE_CREATE_ACCOUNT = 4;
export const TRAINING_TYPE_CREATE_AUDIO_BOOK = 5;

const videoList = [
    {
      id: 0,
      type: TRAINING_TYPE_PRONUNCIATION_GUIDANCE,
      active: true,
      title: "Pronunciation Guidance",
      info: "",
      src: "https://dzaks.blob.core.windows.net/studio-prod/portal-tutorials/pronunciation_guidance_v1.mp4",
    },
    {
      id: 1,
      type: TRAINING_TYPE_DISTRIBUTION_METADATA,
      active: true,
      title: "Distribution Metadata",
      info: "",
      src: "https://dzaks.blob.core.windows.net/studio-prod/portal-tutorials/distribution_metadata_v1.mp4",
    },
    {
      id: 2,
      type: TRAINING_TYPE_CREATE_ACCOUNT,
      active: true,
      title: "Creating Your Account",
      info: "",
      src: "https://dzaks.blob.core.windows.net/studio-prod/portal-tutorials/create_account_v1.mp4",
    },
    {
      id: 3,
      type: TRAINING_TYPE_INTRODUCTION,
      active: true,
      title: "Introduction to Deepzen Portal",
      info: "",
      src: "https://dzaks.blob.core.windows.net/studio-prod/portal-tutorials/introduction_v1.mp4",
    },
    {
      id: 4,
      type: TRAINING_TYPE_CREATE_AUDIO_BOOK,
      active: true,
      title: "Creating Your Audiobook",
      info: "",
      src: "https://dzaks.blob.core.windows.net/studio-prod/portal-tutorials/create_audio_book_v1.mp4",
    }
  ];

const TrainingVideos = ({ trainingType, layoutType, className}) => {

  const [activeVideo, setActiveVideo] = useState(null);
  const [data, setData] = useState(null);
  
  useEffect(() => {
      if(trainingType === TRAINING_TYPE_ALL){
        setData(videoList);
      }else{
        setData(videoList.filter((video) => video.type === trainingType));
      }
  }, []);

  if(!data || data.length === 0) return null;

  return(
    <Card className={classNames("mb-6", className)} title={trainingType === TRAINING_TYPE_ALL ? 'Training Videos' : data[0]['title']}>
      <CardBody>
      {(layoutType === "grid") ? (
          <div className="grid grid-cols-1 gap-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
          {data && data.map((item, index) => (
            <div key={index} className="flex-1">
                
                {item.title && (
                  <label className="flex text-gray-500 font-medium mb-0.5 block px-2 items-center text-sm">
                    <span className="overflow-hidden truncate nowrap">{item.title}</span>
                    {item.info && (
                      <Tooltip placement="top" title={item.info} trigger={["hover"]}>
                          <Icon
                              type="info-circle-o"
                              style={{ paddingLeft: 5, cursor: "pointer" }}
                          />
                      </Tooltip>
                    )}
                  </label>
                )}

                <button className="relative z-0" onClick={(e) => setActiveVideo(item)}>
                    <video 
                        key={item.id} 
                        className="px-2" 
                        src={item.src}>
                    </video>
                    <div className="absolute inset-0 flex justify-center items-center z-10">
                        <Icon 
                            className="bg-white rounded-full" 
                            type="play-circle-o" 
                            style={{fontSize: 30}} 
                          />    
                    </div>
                </button>
                
            </div>
          ))}
        </div> 
      ): (
        <div>
          {data && data.map((item, index) => (
            <div key={index} className={classNames(
              "sm:flex",
              (data.length > 1) && "mb-4 border-b border-gray-200"
            )}>
                <div className="pb-3 flex-shrink-0 sm:mb-0 sm:mr-4">
                  <button className="relative z-0" onClick={(e) => setActiveVideo(item)}>
                    <video 
                        key={item.id} 
                        className="px-2 w-48" 
                        src={item.src}>
                    </video>
                    <div className="absolute inset-0 flex justify-center items-center z-10">
                        <Icon 
                            className="bg-white rounded-full" 
                            type="play-circle-o" 
                            style={{fontSize: 30}} 
                          />    
                    </div>
                </button>
                </div>
            </div>
          ))}
        </div>

      )}
        
        <Modal
            show={activeVideo ? true : false}
            onClose={(e) => setActiveVideo(null)}
            contentClassName="bg-white m-8 w-3/4"
        >
          <ModalHeader onClose={(e) => setActiveVideo(null)}>
            {activeVideo?.title}
          </ModalHeader>
          
          <div className="flex">
            <video className="w-full" controls autoPlay>
              <source src={activeVideo?.src} type="video/mp4" />
            </video>
          </div>

        </Modal>  

      </CardBody>
    </Card>
  );
}

export default TrainingVideos;
