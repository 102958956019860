import React from "react";

const Emp = ({ children }) => (
  <span className="text-red-600 font-bold underline">{children}</span>
);

const rows = [
  [
    [
      <>
        <Emp>p</Emp>ad
      </>,
      "p",
    ],
    [
      <>
        <Emp>h</Emp>ad
      </>,
      "h",
    ],
    [
      <>
        alph<Emp>a</Emp>
      </>,
      "ə",
    ],
  ],
  [
    [
      <>
        <Emp>b</Emp>ad
      </>,
      "b",
    ],
    [
      <>
        <Emp>m</Emp>ail
      </>,
      "mail",
    ],
    [
      <>
        str<Emp>u</Emp>t
      </>,
      "ʌ",
    ],
  ],
  [
    [
      <>
        <Emp>t</Emp>ad
      </>,
      "t",
    ],
    [
      <>
        <Emp>n</Emp>ail
      </>,
      "nail",
    ],
    [
      <>
        f<Emp>oo</Emp>t
      </>,
      "ʊ",
    ],
  ],
  [
    [
      <>
        <Emp>d</Emp>ad
      </>,
      "d",
    ],
    [
      <>
        so<Emp>ng</Emp>
      </>,
      "ŋ",
    ],
    [
      <>
        b<Emp>oo</Emp>t
      </>,
      "u|uː",
    ],
  ],
  [
    [
      <>
        <Emp>c</Emp>ap
      </>,
      "k",
    ],
    [
      <>
        <Emp>l</Emp>ed
      </>,
      "l",
    ],
    [
      <>
        s<Emp>ay</Emp>
      </>,
      "eɪ",
    ],
  ],
  [
    [
      <>
        <Emp>g</Emp>ap
      </>,
      "g",
    ],
    [
      <>
        <Emp>r</Emp>ed
      </>,
      "r",
    ],
    [
      <>
        s<Emp>igh</Emp>
      </>,
      "aɪ",
    ],
  ],
  [
    [
      <>
        <Emp>f</Emp>ail
      </>,
      "f",
    ],
    [
      <>
        <Emp>y</Emp>es
      </>,
      "j",
    ],
    [
      <>
        t<Emp>oy</Emp>
      </>,
      "ɔɪ",
    ],
  ],
  [
    [
      <>
        <Emp>v</Emp>eil
      </>,
      "v",
    ],
    [
      <>
        <Emp>w</Emp>est
      </>,
      "w",
    ],
    [
      <>
        c<Emp>ow</Emp>
      </>,
      "aʊ",
    ],
  ],
  [
    [
      <>
        <Emp>th</Emp>eif
      </>,
      "θ",
    ],
    [
      <>
        <div>US Only</div>A<Emp>d</Emp>am/a<Emp>t</Emp>om
      </>,
      "r",
    ],
    [
      <>
        p<Emp>ie</Emp>r
      </>,
      "ɪə",
    ],
  ],
  [
    [
      <>
        <Emp>th</Emp>ee
      </>,
      "ð",
    ],
    [
      <>
        dr<Emp>e</Emp>ss
      </>,
      "Ɛ|e",
    ],
    [
      <>
        h<Emp>u</Emp>rt
      </>,
      "əː",
    ],
  ],
  [
    [
      <>
        <Emp>s</Emp>eal
      </>,
      "s",
    ],
    [
      <>
        tr<Emp>a</Emp>p
      </>,
      "æ|a",
    ],
    [
      <>
        p<Emp>ea</Emp>r
      </>,
      "ɛə",
    ],
  ],
  [
    [
      <>
        <Emp>z</Emp>eal
      </>,
      "z",
    ],
    [
      <>
        f<Emp>a</Emp>ther
      </>,
      "ɑ|ɑ",
    ],
    [
      <>
        c<Emp>u</Emp>re
      </>,
      "ʊə",
    ],
  ],
  [
    [
      <>
        fi<Emp>sh</Emp>
      </>,
      "ʃ",
    ],
    [
      <>
        t<Emp>oe</Emp>
      </>,
      "oʊ|əʊ",
    ],
    [
      <>
        (French) b<Emp>on</Emp> mot
      </>,
      "ɑ̃|ɔ̃",
    ],
  ],
  [
    [
      <>
        mea<Emp>s</Emp>ure
      </>,
      "ʒ",
    ],
    [
      <>
        t<Emp>a</Emp>lk
      </>,
      "ɔ|ɔː",
    ],
    [
      <>
        (French) f<Emp>in</Emp>
      </>,
      "ɛ̃",
    ],
  ],
  [
    [
      <>
        <Emp>j</Emp>et
      </>,
      "dʒ",
    ],
    [
      <>
        happ<Emp>y</Emp>
      </>,
      "i|i:",
    ],
    [
      <>
        UK Only{" "}
        <div>
          l<Emp>o</Emp>t
        </div>
      </>,
      "ɛ̃",
    ],
  ],
  [
    [
      <>
        <Emp>ch</Emp>ip
      </>,
      "tʃ",
    ],
    [
      <>
        k<Emp>i</Emp>t
      </>,
      "ɪ",
    ],
    [null, null],
  ],
];

const Alphabet = () => (
  <table className="w-full text-center divide-y divide-x divide-gray-300">
    <thead>
      <tr>
        <th className="text-xs font-bold text-gray-500 uppercase tracking-wider">
          Keyword
        </th>
        <th className="text-xs font-bold text-gray-500 uppercase tracking-wider border-r-2">
          IPA Symbol
        </th>
        <th className="text-xs font-bold text-gray-500 uppercase tracking-wider">
          Keyword
        </th>
        <th className="text-xs font-bold text-gray-500 uppercase tracking-wider border-r-2">
          IPA Symbol
        </th>
        <th className="text-xs font-bold text-gray-500 uppercase tracking-wider">
          Keyword
        </th>
        <th className="text-xs font-bold text-gray-500 uppercase tracking-wider">
          IPA Symbol
        </th>
      </tr>
    </thead>
    <tbody
      className="bg-white divide-y divide-gray-200 text-gray-900"
      style={{ borderLeft: "none" }}
    >
      {rows.map((row, i) => (
        <tr key={`${i}`}>
          <td>{row[0][0]}</td>
          <td className="border-r-2">{row[0][1]}</td>
          <td>{row[1][0]}</td>
          <td className="border-r-2">{row[1][1]}</td>
          <td>{row[2][0]}</td>
          <td>{row[2][1]}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default Alphabet;
