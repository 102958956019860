import React from "react";
import Card, { CardBody } from "../../../../components/Card";

export function FinalizingBook() {
  return (
    <Card title="Post-Processing and Mastering">
      <CardBody>
        Having received your approval to corrections, we’ll post-process and
        format your audiobook ready for distribution to your preferred
        distributor or vendors. This stage generally takes up to 2 working days.
      </CardBody>
    </Card>
  );
}

export default FinalizingBook;
