import React, { useState } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, getFormSyncErrors } from "redux-form";
import Button from "../../components/Button";
import Card, { CardBody } from "../../components/Card";
import TextField from "../../components/Forms/TextField";
import ApiError from "../../components/ApiError";
import { required } from "../../services/validations";
import { useUser } from "../Auth/modules/auth";
import axios from "axios";
import classNames from "classnames";
import { Link } from "react-router";

const Form = ({
  loading,
  handleSubmit,
  isFormValid,
}) => {
  const [showFormValidation, setShowFormValidation] = useState(false);
  
  const CustomLabel = ({text}) => (
    <label className="text-gray-500 font-bold mb-0.5 flex items-center text-sm">
        {text}
    </label>
  );

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <CardBody>
          <div className="px-2">
            <div className="md:flex mb-4 md:justify-center">
              <div className="flex md:flex-1 mb-4">
                <Field
                  className="h-10 sm:h-9"
                  component={TextField}
                  validate={[required]}
                  label={<CustomLabel text="First Name"/>}
                  name="first_name"
                />
              </div>
              <div className="flex mb-4 md:mb-0 md:ml-4 md:flex-1">
                <Field
                  className="h-10 sm:h-9"
                  component={TextField}
                  validate={[required]}
                  label={<CustomLabel text="Last Name"/>}
                  name="last_name"
                />
              </div>
            </div>

            <div className="md:flex mb-4 md:justify-center">
              <div className="flex md:flex-1 mb-4">
                <Field
                  className="h-10 sm:h-9"
                  component={TextField}
                  validate={[required]}
                  label={<CustomLabel text="Email"/>}
                  name="email"
                /> 
              </div>
              <div className="flex mb-4 md:mb-0 md:ml-4 md:flex-1">
                <Field
                  className="h-10 sm:h-9"
                  component={TextField}
                  validate={[required]}
                  label={<CustomLabel text="Job Title"/>}
                  name="job_title"
                />
              </div>
            </div> 

            <div class="mb-4">
              <CustomLabel text="Password" />
              <p class="text-md text-gray-500">
                Raw passwords are not stored, so there is no way to see this user's password, but you can change the password using {" "}
                <Link 
                  to="/password-reset"
                  className="text-blue-500 underline"
                >
                  this form
                </Link>
              </p>
            </div>

          </div>
        
        <div className={classNames("flex",
          showFormValidation && !isFormValid ? "justify-between" : "justify-end"
        )}>
        
        {showFormValidation && !isFormValid && (
          <div className="flex flex-1 items-center border border-red-400 rounded bg-red-100 mx-2 px-4 text-red-700">
            <svg className="fill-current h-5 w-5 text-red-700 mr-2" viewBox="0 0 20 20">
              <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/>
            </svg>
            <p>Please fill required fields.</p>
          </div>
        )}

        <div className="flex flex-5 justify-center items-center px-2">
          <Button 
            loading={loading} 
            size="large" 
            type="success"
            onClick={(e) => setShowFormValidation(!isFormValid)}
          >
            Edit Profile
          </Button>
        </div>
        </div>
      </CardBody>
    </Card>
  </form>
  );
};

const formName = "profile-info";
const mapStateToProps = (state, props) => {
  const formErrors = getFormSyncErrors(formName)(state);
  const isFormValid = Object.keys(formErrors).length === 0;
  return {
    formErrors,
    isFormValid,
  };
};

const ConnectedForm = connect(mapStateToProps)(reduxForm({
  form: formName,
  enableReinitialize: true,
})(Form));

const ProfileInfo = ({ onComplete }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const user = useUser();
  
  const handleSubmit = (values) => {
    setLoading(true);
    axios
      .post(`portal/profile-settings/`, values)
      .then((res) => {
        onComplete();
      })
      .catch((e) => setError(e))
      .finally(() => setLoading(false));
  };

  return (
    <>
      {error && (
        <div className="mb-4">
          <ApiError error={error} />
        </div>
      )}

      <ConnectedForm
        initialValues={{
          first_name: user?.profile?.first_name,
          last_name: user?.profile?.last_name,
          email: user?.profile?.email, 
          job_title: user?.profile?.job_title
        }}
        onSubmit={handleSubmit}
        loading={loading}
      />
    </>
  );
};

export default ProfileInfo;
