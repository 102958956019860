import React, { useState } from "react";
import logoIcon from "../assets/logo-icon.png";
import { IndexLink, Link } from "react-router";
import Avatar from "./Avatar";
import { useUser } from "../routes/Auth/modules/auth";
import classNames from "classnames";

export function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const user = useUser();

  if (!user) return (
    <>
      <nav className="bg-gray-900">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <div className="flex-shrink-0">
            <Link to="https://deepzen.io" target="_blank" className="flex-inline items-center">
              <img className="h-8 ml-6 w-auto" src={logoIcon} alt="DeepZen" />
            </Link>  
            </div>
          </div>
        </div>
      </nav>
    </>
  ); 

  return (
    <nav className="bg-gray-900">
      <div className="container px-4 md:px-0">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <Link to="https://deepzen.io" target="_blank" className="flex-inline items-center">
                <img className="h-8 w-auto" src={logoIcon} alt="DeepZen" />
              </Link>
            </div>
            <div className="hidden lg:block md:ml-6">
              <div className="flex space-x-4">
                <IndexLink
                  activeClassName="bg-gray-900 text-white"
                  exact="true"
                  to="/"
                  className="bg-gray-700 text-gray-300 px-3 py-2 rounded-md text-sm font-medium"
                >
                  Dashboard
                </IndexLink>
                <Link
                  activeClassName="bg-gray-900 text-white"
                  to="/voices"
                  className="bg-gray-700 text-gray-300 px-3 py-2 rounded-md text-sm font-medium"
                >
                  Voice Library
                </Link>
                {user.is_business_admin && (
                  <Link
                    activeClassName="bg-gray-900 text-white"
                    to="/users"
                    className="bg-gray-700 text-gray-300 px-3 py-2 rounded-md text-sm font-medium"
                  >
                    User Admin
                  </Link>
                )}
                <Link
                  activeClassName="bg-gray-900 text-white"
                  to="/pricing"
                  className="bg-gray-700 text-gray-300 px-3 py-2 rounded-md text-sm font-medium"
                >
                  Pricing
                </Link>
                <Link
                  activeClassName="bg-gray-900 text-white"
                  to="/help"
                  target="_blank"
                  className="bg-gray-700 text-gray-300 px-3 py-2 rounded-md text-sm font-medium"
                >
                  Help / FAQ
                </Link>
              </div>
            </div>
          </div>
          <div className="hidden lg:ml-6 lg:block">
            <div className="flex items-center">
              <div className="ml-3 relative">
                <div>
                  <Avatar user={user} />
                </div>
              </div>
            </div>
          </div>
          <div className="-mr-2 flex lg:hidden">
            <button
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-insest focus:ring-white"
              aria-expanded="false"
            >
              <svg
                className={classNames(
                  !mobileMenuOpen ? "block" : "hidden",
                  "h-6 w-6"
                )}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>

              <svg
                className={classNames(
                  mobileMenuOpen ? "block" : "hidden",
                  "h-6 w-6"
                )}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <div
        className={classNames(mobileMenuOpen ? "block" : "hidden", "lg:hidden")}
      >
        <div className="px-2 pt-2 pb-3 space-y-1">
          <IndexLink
            onClick={() => setMobileMenuOpen(false)}
            to="/"
            exact="true"
            activeClassName="bg-gray-900 text-white"
            className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
          >
            Dashboard
          </IndexLink>
          <Link
            onClick={() => setMobileMenuOpen(false)}
            to="/voices"
            activeClassName="bg-gray-900 text-white"
            className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
          >
            Voice Library
          </Link>
          {user.is_business_admin && (
            <Link
              onClick={() => setMobileMenuOpen(false)}
              activeClassName="bg-gray-900 text-white"
              to="/users"
              className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
            >
              User Admin
            </Link>
          )}
          <Link
            onClick={() => setMobileMenuOpen(false)}
            activeClassName="bg-gray-900 text-white"
            to="/pricing"
            className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
          >
            Pricing
          </Link>
          <Link
            onClick={() => setMobileMenuOpen(false)}
            to="/help"
            target="_blank"
            activeClassName="bg-gray-900 text-white"
            className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
          >
            Help / FAQ
          </Link>
        </div>
        <div className="pt-4 pb-3 border-t border-gray-700">
          <div className="flex items-center px-5">
            <div className="text-base font-medium text-white">{user.email}</div>
          </div>
          <div className="mt-3 px-2">
            <Link
              onClick={() => setMobileMenuOpen(false)}
              to="/settings"
              className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
            >
              Business Settings
            </Link>
            {/*
            <Link
              onClick={() => setMobileMenuOpen(false)}
              to="/profile-settings"
              className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
            >
              Profile Settings
            </Link>
            */}
            <Link
              onClick={() => setMobileMenuOpen(false)}
              to="/auth/logout"
              className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
            >
              Sign out
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Header;
