import { message } from "antd";
import React from "react";
import PageHeader from "../../components/PageHeader";
import ProfileInfo from "./ProfileInfo";

const ProfileSettings = () => {
  return (
    <div className="container pb-10">
      <PageHeader title="Profile Informations" />
      <ProfileInfo
        onComplete={() => message.success("Changes have been saved.")}
      />
    </div>
  );
};

export default ProfileSettings;
