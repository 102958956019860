import React, { useState } from "react";
import HorizontalSteps from "./HorizontalSteps";
import Details from "./Details";
import { useUser } from "../../Auth/modules/auth";
import Voices from "./Voices";
import Contract from "./Contract";
import Pricing from "./Pricing";
import TrainingVideos, { TRAINING_TYPE_CREATE_ACCOUNT } from "../../../components/TrainingVideos";

const getSteps = (user) => [
  { title: "Company Details", component: Details, complete: !!user.business },
  {
    title: "Voice Library",
    component: Voices,
    complete: !!user.business_contract,
  },
  {
    title: "Pricing",
    component: Pricing,
    complete: !!user.business_contract,
  },
  {
    title: "Review Production Agreement",
    component: Contract,
    complete: !!user.business_contract,
  },
];

const AccountSetup = () => {
  const user = useUser();
  const steps = getSteps(user);

  const firstIncompleteStepIndex = steps.findIndex((step) => !step.complete);
  const [activeStepIndex, setActiveStepIndex] = useState(
    firstIncompleteStepIndex === -1 ? 0 : firstIncompleteStepIndex
  );

  const ActiveStepComponent = steps[activeStepIndex].component;

  const handleComplete = () => {
    // Refresh the page if this was the last step
    if (activeStepIndex + 1 === steps.length) {
      window.location.reload();
    } else {
      setActiveStepIndex(activeStepIndex + 1);
    }
  };

  const handleBack = () => {
    setActiveStepIndex(activeStepIndex - 1);
  };

  return (
    <div>
      <HorizontalSteps
        steps={steps}
        activeStepIndex={activeStepIndex}
        onChange={(step) => setActiveStepIndex(steps.indexOf(step))}
      />

      <div className="container mt-5">
        <ActiveStepComponent
          user={user}
          onBack={handleBack}
          onComplete={handleComplete}
        />
        
        {activeStepIndex === 0 && (
          <TrainingVideos 
            className="mt-6"
            trainingType={TRAINING_TYPE_CREATE_ACCOUNT}
          />
        )}
      </div>

       
    </div>
  );
};

export default AccountSetup;
