import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const propTypes = {
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number,
  pageSize: PropTypes.number,
};

class Pagination extends Component {
  constructor(props) {
    super(props);
    this.state = { pager: {} };
  }

  componentWillMount() {
    // set page if items array isn't empty
    if (this.props.totalSize > 0) {
      this.setPage(this.props.page, false);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // reset page if items array has changed
    if (this.props.totalSize !== prevProps.totalSize || this.props.page !== prevProps.page) {
      this.setPage(this.props.page, false);
    }
  }

  setPage(page, triggerPage) {
    var { totalSize, pageSize } = this.props;
    var pager = this.state.pager;

    if (page < 1 || page > pager.totalPages) {
      return;
    }

    // get new pager object for specified page
    pager = this.getPager(totalSize, page, pageSize);

    // update state
    this.setState({ pager: pager });

    // call change page function in parent component
    if (triggerPage) {
      this.props.onChangePage({
        page: page,
        pageSize: pageSize,
        startIndex: pager.startIndex,
        endIndex: pager.endIndex,
      });
    }
  }

  getPager(totalItems, currentPage, pageSize) {
    // default to first page
    currentPage = currentPage || 1;

    // default page size is 10
    pageSize = pageSize || 10;

    // calculate total pages
    var totalPages = Math.ceil(totalItems / pageSize);

    var startPage, endPage;
    if (totalPages <= 4) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 3) {
        startPage = 1;
        endPage = 4;
      } else if (currentPage + 1 >= totalPages) {
        startPage = totalPages - 3;
        endPage = totalPages;
      } else {
        startPage = currentPage - 2;
        endPage = currentPage + 1;
      }
    }

    // calculate start and end item indexes
    var startIndex = (currentPage - 1) * pageSize;
    var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    var pages = [...Array(endPage + 1 - startPage).keys()].map((i) => startPage + i);

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    };
  }

  render() {
    const pager = this.state.pager;

    if (!pager.pages || pager.pages.length <= 1) {
      // don't display pager if there is only 1 page
      return null;
    }

    const buttonDefaultCss =
        "-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150";
    return (
        <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
          <div className="sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
              <p className="text-sm leading-5 text-gray-700">
                Showing<span>&nbsp;</span>
                <span className="font-medium">{pager.startIndex + 1}</span>
                <span>&nbsp;</span>to<span>&nbsp;</span>
                <span className="font-medium">{pager.endIndex + 1}</span>
                <span>&nbsp;</span>of<span>&nbsp;</span>
                <span className="font-medium">{pager.totalItems}</span>
                <span>&nbsp;</span>results
              </p>
            </div>
            <div>
              <ul className="relative z-0 inline-flex shadow-sm">
                <li>
                  <a
                      className={classNames(
                          buttonDefaultCss,
                          pager.currentPage === 1
                              ? "cursor-not-allowed pointer-events-none bg-white text-gray-700"
                              : "cursor-pointer pointer-events-auto bg-white text-gray-700"
                      )}
                      onClick={() => this.setPage(1, true)}
                  >
                    First
                  </a>
                </li>
                <li>
                  <a
                      className={classNames(
                          buttonDefaultCss,
                          pager.currentPage === 1
                              ? "cursor-not-allowed pointer-events-none bg-white text-gray-700"
                              : "cursor-pointer pointer-events-auto bg-white text-gray-700"
                      )}
                      onClick={() => this.setPage(pager.currentPage - 1, true)}
                  >
                    <svg className="mr-0 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                      <path
                          fillRule="evenodd"
                          d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                          clipRule="evenodd"
                      />
                    </svg>
                  </a>
                </li>
                {pager.pages.map((page, index) => (
                    <li key={index}>
                      <a
                          className={classNames(
                              buttonDefaultCss,
                              pager.currentPage === page
                                  ? "cursor-not-allowed pointer-events-none bg-blue-500 text-white"
                                  : "cursor-pointer pointer-events-auto bg-white text-gray-700"
                          )}
                          onClick={() => this.setPage(page, true)}
                      >
                        {page}
                      </a>
                    </li>
                ))}

                <li>
                  <a
                      className={classNames(
                          buttonDefaultCss,
                          pager.currentPage === pager.totalPages
                              ? "cursor-not-allowed pointer-events-none bg-white text-gray-700"
                              : "cursor-pointer pointer-events-auto bg-white text-gray-700"
                      )}
                      onClick={() => this.setPage(pager.currentPage + 1, true)}
                  >
                    <svg className="ml-0 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                      <path
                          fillRule="evenodd"
                          d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                          clipRule="evenodd"
                      />
                    </svg>
                  </a>
                </li>

                <li>
                  <a
                      className={classNames(
                          buttonDefaultCss,
                          pager.currentPage === pager.totalPages
                              ? "cursor-not-allowed pointer-events-none bg-white text-gray-700"
                              : "cursor-pointer pointer-events-auto bg-white text-gray-700"
                      )}
                      onClick={() => this.setPage(pager.totalPages, true)}
                  >
                    Last
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
    );
  }
}

Pagination.propTypes = propTypes;
export default Pagination;