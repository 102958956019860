import React from "react";
import statuses from "../modules/statuses";
import classNames from "classnames";

const descriptions = {
  initial_linguistics_review:
    "In order to ensure that all words are pronounced correctly in your audiobook, we check the entire text for uncommon words. Should we require your input, you’ll receive an email requesting pronunciation guidance within 3 working days.",
  customer_linguistics_input:
    "We ask that you provide an audio pronunciation or a written transcription. You can enter these directly into the portal.",
  editing_sample:
    "Customer has provided pronunciation information of the words. DeepZen is expected to complete linguistics work and get back to customer with a sample.",
  customer_sample_review:
    "Customer has received the sample. Book generation will begin after feedbacks about sample is provided.",
  editing_book:
    "We convert your manuscript into an audiobook and our team of editors review and make any necessary corrections.",
  customer_proofing:
    "You will receive an email notifying you that your audiobook is ready for a proofing review. This will include a secure link you can share with a colleague for review purposes. ",
  making_corrections:
    "We’ll make any corrections you request within 3 working days.",
  customer_proofing_review:
    "You will receive an email notification asking you to approve the corrections you requested.",
  finalizing_book:
    "Having received your approval of corrections, we’ll post-process and format your audiobook ready for distribution to your preferred distributor or vendors. This stage generally takes up to 2 working days. ",
  book_delivered: "Happy listening!",
};

export function States({ status, editMode, hasDistribution }) {
  // Some steps are opaque to user when they're using automated
  const steps =
    editMode === "managed"
      ? Object.keys(statuses)
      : [
          "initial_linguistics_review",
          "customer_linguistics_input",
          "editing_book",
          ...(hasDistribution ? ["finalizing_book"] : []),
          "book_delivered",
        ];

  const activeStepIndex = steps.indexOf(
    status.replace(/_post_review/g, "").replace(/_post/, "")
  );

  const checkLastItem = (type, index) => {
    if (steps.length != index + 1) {
      if (type == "draft")
        return (
          <div className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300"></div>
        );
      if (type == "finished")
        return (
          <div className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-indigo-600"></div>
        );
      if (type == "active")
        return (
          <div className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300"></div>
        );
    }
  };

  return (
    <>
      <nav>
        <ul className="overflow-hidden">
          {steps.map((step, i) => (
            <li 
              key={i}
              className={classNames(
                "relative",
                i !== steps.length - 1 && "pb-10"
              )}
            >
              {activeStepIndex > i && (
                <>
                  {checkLastItem("finished", i)}
                  <a className="relative flex items-start space-x-4 group focus:outline-none">
                    <div className="h-9 flex items-center">
                      <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-indigo-600 rounded-full">
                        <svg
                          className="w-5 h-5 text-white"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </span>
                    </div>
                    <div className="min-w-0">
                      <h3 className="text-xs leading-4 font-semibold uppercase tracking-wide">
                        {statuses[step]}
                      </h3>
                      <p className="text-sm leading-5 text-gray-500">
                        {descriptions[step]}
                      </p>
                    </div>
                  </a>
                </>
              )}

              {activeStepIndex === i && (
                <>
                  {checkLastItem("active", i)}
                  <div className="relative flex items-start space-x-4 group focus:outline-none">
                    <div className="h-9 flex items-center">
                      <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-indigo-600 rounded-full">
                        <span className="h-2.5 w-2.5 bg-indigo-600 rounded-full"></span>
                      </span>
                    </div>
                    <div className="min-w-0">
                      <h3 className="text-xs leading-4 font-semibold uppercase tracking-wide text-indigo-600">
                        {statuses[step]}
                      </h3>
                      <p className="text-sm leading-5 text-gray-500">
                        {descriptions[step]}
                      </p>
                    </div>
                  </div>
                </>
              )}

              {activeStepIndex < i && (
                <>
                  {checkLastItem("draft", i)}
                  <a className="relative flex items-start space-x-4 group focus:outline-none">
                    <div className="h-9 flex items-center">
                      <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full"></span>
                    </div>
                    <div className="min-w-0">
                      <h3 className="text-xs leading-4 font-semibold uppercase tracking-wide text-gray-500">
                        {statuses[step]}
                      </h3>
                      <p className="text-sm leading-5 text-gray-500">
                        {descriptions[step]}
                      </p>
                    </div>
                  </a>
                </>
              )}
            </li>
          ))}
        </ul>
      </nav>
    </>
  );
}

export default States;
