import React, { useEffect, useState } from "react";
import Card from "../../components/Card";
import { Slider } from "antd";
import usePricing from "./usePricing";
import Checkmark from "../../components/Icons/Checkmark";
import X from "../../components/Icons/X";
import { Link } from "react-router";

export const signs = {
  USD: "$",
  GBP: "£",
};

const features = [
  { title: "ePub Editor", managed: true, automated: true },
  { title: "Pronunciation Check", managed: true, automated: true },
  { title: "DZ Proofing Review", managed: true, automated: false },
  { title: "Publisher QC Review", managed: true, automated: false },
  { title: "Corrections", managed: true, automated: false },
  { title: "Post-processing & Formatting", managed: true, automated: true },
  { title: "Average Turn-around", managed: "1-2 weeks", automated: "3-5 days" },
  {
    title: "Distribution Set-up",
    managed: "$69/£49 initially, $24.99/£19.99 per year thereafter",
    automated: "$69/£49 initially, $24.99/£19.99 per year thereafter",
  },
];

const Current = () => (
  <span className="bg-orange-100 text-orange-600 px-2 rounded-full text-xs ml-2 self-center">
    Current plan
  </span>
);

const PricingCard = () => {
  const [editMode, setEditMode] = useState("managed");
  const [numOfWords, setNumOfWords] = useState(50000);
  const [managedPrice, setManagedPrice] = useState(0);
  const [automatedPrice, setAutomatedPrice] = useState(0);
  const prices = usePricing();

  const calculatePrice = () => {
    if(prices){
      let priceCoefficient = Math.max(
        numOfWords / prices.num_of_words_per_hour,
        1
      );

      const priceBuffer = Number((priceCoefficient % 1).toFixed(1));
      
      priceCoefficient = priceBuffer > 0.1 
        ? Math.ceil(priceCoefficient)
        : Math.floor(priceCoefficient);

      setAutomatedPrice(priceCoefficient * prices.tier1.automated);
      setManagedPrice(priceCoefficient * prices.tier1.managed);  
    }
  };
  
  useEffect(() => {
    calculatePrice();
  }, [numOfWords, prices]);

  if (!prices) return null;

  return (
    <>
      <Card className="mb-4">
        <div className="pt-3">
          <table className="w-full h-px table-fixed">
            <thead>
              <tr>
                <th className="w-1/4 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left"></th>
                <th className="w-1/4 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left">
                  Managed Service
                </th>
                <th className="w-1/4 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left">
                  Automated Service
                </th>
                <th className="w-1/4 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left">
                  Enterprise
                </th>
              </tr>
            </thead>
            <tbody className="border-t border-gray-200 divide-y divide-gray-200">
              <tr>
                <th
                  className="py-2 px-6 text-sm text-left align-middle"
                  scope="row"
                >
                  Pricing
                </th>
                <td className="py-2 px-6 align-top">
                  <span className="text-2xl font-extrabold text-gray-800">
                    {signs[prices.currency]}
                    {prices.tier1.managed}
                  </span>
                  <span className="text-base font-medium text-gray-500">
                    /hour
                  </span>
                </td>
                <td className="py-2 px-6 align-top">
                  <span className="text-2xl font-extrabold text-gray-800">
                    {signs[prices.currency]}
                    {prices.tier1.automated}
                  </span>
                  <span className="text-base font-medium text-gray-500">
                    /hour
                  </span>
                </td>
                <td
                  rowSpan={features.length + 2}
                  className="px-6 bg-gray-50 border-l border-gray-200"
                  valign="top"
                >
                  <div className="relative pt-4">
                    <span className="text-2xl font-extrabold text-gray-900">
                      Need a bigger plan?
                    </span>
                    <p className="mt-4 mb-12 text-sm text-gray-500">
                      Please contact us If you’d like to discuss volume discounts, we’ll work with you to create a custom package.
                    </p>
                    <Link
                      to="/help"
                      target="_blank"
                      className="flex-grow block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                    >
                      Contact sales
                    </Link>
                  </div>
                </td>
              </tr>

              {features.map((feature, i) => (
                <tr key={i}>
                  <th
                    className="py-2 px-6 text-sm text-left align-top"
                    scope="row"
                  >
                    {feature.title}
                  </th>

                  <td className="h-full py-2 px-6 align-top">
                    {typeof feature.managed === "boolean" &&
                      (feature.managed ? (
                        <Checkmark size={6} className="text-green-600" />
                      ) : (
                        <X size={6} className="text-red-600" />
                      ))}
                    {typeof feature.managed === "string" && feature.managed}
                  </td>
                  <td className="h-full py-2 px-6 align-top">
                    {typeof feature.automated === "boolean" &&
                      (feature.automated ? (
                        <Checkmark size={6} className="text-green-600" />
                      ) : (
                        <X size={6} className="text-red-600" />
                      ))}
                    {typeof feature.automated === "string" && feature.automated}
                  </td>
                </tr>
              ))}
              <tr className="bg-gray-100">
                <th
                  className="pt-8 pb-6 px-6 text-sm text-left align-top"
                  scope="row"
                >
                  <span className="font-medium text-gray-900 text-xl mb-1">
                    How much does it cost to make an audiobook?
                  </span>

                  <div className="rounded-lg bg-white mt-3 p-2 shadow-sm text-center">
                    <div className="text-sm font-normal">
                      Number of words in your manuscript
                    </div>
                    <Slider
                      tooltipVisible={false}
                      value={numOfWords}
                      onChange={setNumOfWords}
                      min={1000}
                      max={150000}
                      step={1000}
                      backg
                    />
                    <div className="text-center text-3xl font-medium text-gray-900">
                      {Number(numOfWords).toLocaleString()}
                    </div>
                  </div>
                </th>

                <td className="h-full py-8 px-6 align-top">
                  <div className="relative h-full table">
                    <p>
                      <span className="text-4xl font-extrabold text-gray-900">
                        ~{signs[prices.currency]}
                        {managedPrice}
                      </span>
                      <span className="text-base font-medium text-gray-500">
                        /estimate
                      </span>
                    </p>
                  </div>
                </td>

                <td className="h-full py-8 px-6 align-top">
                  <div className="relative h-full table">
                    <p>
                      <span className="text-4xl font-extrabold text-gray-900">
                        ~{signs[prices.currency]}
                        {automatedPrice}
                      </span>
                      <span className="text-base font-medium text-gray-500">
                        /estimate
                      </span>
                    </p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Card>
    </>
  );
};

export default PricingCard;
