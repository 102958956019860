import React, { useEffect, useState } from "react";
import Card from "../../../../components/Card";
import Chapters from "../components/Chapters";
import Issues from "../components/Issues";
import { useUser } from "../../../../routes/Auth/modules/auth";

const CustomerProofing = ({ project, chapters, onSubmit }) => {
  const [issues, setIssues] = useState([]);
  const [activeChapterIndex, setActiveChapterIndex] = useState(0);
  const user = useUser();

  useEffect(() => setIssues([...project.issues]), [project]);

  const handleAddIssue = (issue) => setIssues([...issues, {...issue, email: user?.profile.email ?? null}]);
  
  if (!chapters || chapters.length === 0) {
    return null;
  }
  
  return (
    <>
    <Card title="Corrections">
      <Chapters
        project={project}
        chapters={chapters}
        activeChapterIndex={activeChapterIndex}
        setActiveChapterIndex={setActiveChapterIndex}
        onAddIssue={handleAddIssue}
      >
        <Issues
          onSubmit={onSubmit}
          project={project}
          chapter={chapters[activeChapterIndex].chapter}
          issues={issues}
          setIssues={setIssues}
        />
      </Chapters>
    </Card>
    
    </>
  );
};

export default CustomerProofing;
