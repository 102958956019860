import React, { useEffect } from "react";

const Payment3D = ({ src, onSuccess }) => {

  useEffect(() => {
    const listener = function (ev) {
      if (ev.data.type === "stripe-3ds-fallback") {
        onSuccess();
      
      }else if (ev.data === "3ds-complete") {
        onSuccess();
      }
    };
    window.addEventListener("message", listener);

    return () => window.removeEventListener("message", listener);
  }, []);

  return (
    <div style={{ width: 500 }}>
      <iframe src={src} style={{ width: "50vw", height: "65vh" }} />
    </div>
  );
};

export default Payment3D;
