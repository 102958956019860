import React from "react";
import Dropzone from "react-dropzone";
import classNames from "classnames";
import Checkmark from "../Icons/Checkmark";

const DropzoneField = (field) => {
  const file = field.input.value;

  return (
    <div>
      {field.label && (
        <label
          className={classNames(
            "text-gray-500 font-medium mb-0.5 block",
            field.size === "small" ? "text-xs" : "text-sm"
          )}
        >
          {field.label}
        </label>
      )}

      <Dropzone
        name={field.name}
        accept={field.accept}
        onDrop={(filesToUpload, e) => field.input.onChange(filesToUpload)}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <div
              className={classNames(
                "text-center p-7 border border-dashed bg-gray-100 rounded-md",
                file ? "border-green-500" : "border-gray-500"
              )}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              {file ? (
                <p className="text-green-800">
                  <Checkmark size={6} className="-mt-2" /> File selected. You
                  can click to change the file
                </p>
              ) : (
                <p className="text-gray-400">
                  You can drop file here, or click to select
                </p>
              )}
            </div>
          </section>
        )}
      </Dropzone>

      {field.meta &&
        field.meta.touched &&
        ((field.meta.error && (
          <span className="text-red-900 text-xs">{field.meta.error}</span>
        )) ||
          (field.meta.warning && (
            <span className="text-red-900 text-xs">{field.meta.warning}</span>
          )))}
    </div>
  );
};

export default DropzoneField;
