import React from "react";

const RawText = ({ children }) =>
  children.split("\n").map((item, key) => (
    <React.Fragment key={key}>
      {item}
      <br />
    </React.Fragment>
  ));

export default RawText;
