export default [
	{
		type: "consonant",
		ipa: "p",
		deepzen: "p",
		combilex: "p",
		keywords: [
			{ text: "principle", highlight: [0, 1] },
			{ text: "pen", highlight: [0, 1] },
		],
		sample: ["principle", 'p r e" n s @ p @ l'],
	},
	{
		type: "consonant",
		ipa: "b",
		deepzen: "b",
		combilex: "b",
		keywords: [
			{ text: "bear", highlight: [0, 1] },
			{ text: "bring", highlight: [0, 1] },
		],
		sample: ["bring", 'b r e" N'],
	},
	{
		type: "consonant",
		ipa: "t",
		deepzen: "t",
		combilex: "t",
		keywords: [
			{ text: "tear", highlight: [0, 1] },
			{ text: "trend", highlight: [0, 1] },
			{ text: "cat", highlight: [2, 3] },
		],
		sample: ["trend", 't r E" n d'],
	},
	{
		type: "consonant",
		ipa: "d",
		deepzen: "d",
		combilex: "d",
		keywords: [
			{ text: "dentist", highlight: [0, 1] },
			{ text: "odd", highlight: [1, 3] },
		],
		sample: ["odd", 'aa" d'],
	},
	{
		type: "consonant",
		ipa: "k",
		deepzen: "k",
		combilex: "k",
		keywords: [
			{ text: "kill", highlight: [0, 1] },
			{ text: "cat", highlight: [0, 1] },
			{ text: "queen", highlight: [0, 1] },
			{ text: "thick", highlight: [3, 5] },
			{ text: "unique", highlight: [3, 4] },
			{ text: "skin", highlight: [1, 2] },
		],
		sample: ["cat", 'k a" t'],
	},
	{
		type: "consonant",
		ipa: "g",
		deepzen: "g",
		combilex: "g",
		keywords: [
			{ text: "goose", highlight: [0, 1] },
			{ text: "get", highlight: [0, 1] },
			{ text: "gang", highlight: [0, 1] },
		],
		sample: ["goose", 'g uu" s'],
	},
	{
		type: "consonant",
		ipa: "m",
		deepzen: "m",
		combilex: "m",
		keywords: [
			{ text: "moon", highlight: [0, 1] },
			{ text: "rhythm", highlight: [5, 6] },
			{ text: "arrythmia", highlight: [6, 7] },
			{ text: "men", highlight: [0, 1] },
			{ text: "flame", highlight: [3, 4] },
			{ text: "pump", highlight: [2, 3] },
		],
		sample: ["men", 'm E" n'],
	},
	// TEMP
	{
		type: "consonant",
		ipa: "m!",
		deepzen: "m!",
		combilex: "m!",
		keywords: [],
		sample: ["", ""],
	},
	{
		type: "consonant",
		ipa: "n",
		deepzen: "n",
		combilex: "n",
		keywords: [
			{ text: "new", highlight: [0, 1] },
			{ text: "end", highlight: [1, 2] },
			{ text: "men", highlight: [2, 3] },
			{ text: "parent", highlight: [4, 5] },
			{ text: "hens", highlight: [2, 3] },
			{ text: "honey", highlight: [2, 3] },
		],
		sample: ["parent", 'p E" r @ n t'],
	},
	// TEMP
	{
		type: "consonant",
		ipa: "n!",
		deepzen: "n!",
		combilex: "n!",
		keywords: [],
		sample: ["", ""],
	},
	{
		type: "consonant",
		ipa: "ŋ",
		deepzen: "N",
		combilex: "N",
		keywords: [
			{ text: "playing", highlight: [5, 7] },
			{ text: "drink", highlight: [3, 5] },
			{ text: "assumingly", highlight: [6, 8] },
			{ text: "finger", highlight: [2, 4] },
			{ text: "racing", highlight: [4, 6] },
			{ text: "amongst", highlight: [3, 5] },
			{ text: "articulatings", highlight: [10, 12] },
			{ text: "bank", highlight: [2, 3] },
			{ text: "langur", highlight: [2, 3] },
			{ text: "longer", highlight: [2, 3] },
			{ text: "tongue", highlight: [2, 4] },
		],
		sample: ["gang", 'g a" N'],
	},
	{
		type: "consonant",
		ipa: "f",
		deepzen: "f",
		combilex: "f",
		keywords: [
			{ text: "fear", highlight: [0, 1] },
			{ text: "leaf", highlight: [3, 4] },
			{ text: "free", highlight: [0, 1] },
		],
		sample: ["fear", 'f ea" r'],
	},
	{
		type: "consonant",
		ipa: "v",
		deepzen: "v",
		combilex: "v",
		keywords: [
			{ text: "have", highlight: [2, 3] },
			{ text: "of", highlight: [1, 2] },
			{ text: "velum", highlight: [0, 1] },
		],
		sample: ["velum", 'v ee" l @ m'],
	},
	{
		type: "consonant",
		ipa: "s",
		deepzen: "s",
		combilex: "s",
		keywords: [
			{ text: "scary", highlight: [0, 1] },
			{ text: "pass", highlight: [2, 4] },
			{ text: "scarify", highlight: [0, 1] },
		],
		sample: ["scary", 's k E" r ee'],
	},
	{
		type: "consonant",
		ipa: "z",
		deepzen: "z",
		combilex: "z",
		keywords: [
			{ text: "rose", highlight: [2, 3] },
			{ text: "zenith", highlight: [0, 1] },
		],
		sample: ["rose", 'r o" z'],
	},
	{
		type: "consonant",
		ipa: "ʃ",
		deepzen: "S",
		combilex: "S",
		keywords: [
			{ text: "she", highlight: [0, 2] },
			{ text: "emotion", highlight: [3, 4] },
			{ text: "session", highlight: [2, 4] },
			{ text: "crescendo", highlight: [3, 5] },
			{ text: "cretaceous", highlight: [5, 6] },
			{ text: "crucifixion", highlight: [7, 8] },
			{ text: "decoction", highlight: [5, 6] },
		],
		sample: ["emotion", 'e m o" S @ n'],
	},
	{
		type: "consonant",
		ipa: "ʒ",
		deepzen: "Z",
		combilex: "Z",
		keywords: [
			{ text: "genre", highlight: [0, 1] },
			{ text: "pleasure", highlight: [4, 5] },
			,
			{ text: "beige", highlight: [3, 4] },
			{ text: "equation", highlight: [4, 5] },
			{ text: "closure", highlight: [3, 4] },
			{ text: "dressage", highlight: [6, 7] },
			{ text: "garage", highlight: [4, 5] },
		],
		sample: ["genre", 'Z aa" n r @'],
	},
	{
		type: "consonant",
		ipa: "θ",
		deepzen: "th",
		combilex: "T",
		keywords: [
			{ text: "thesis", highlight: [0, 2] },
			{ text: "teeth", highlight: [3, 5] },
		],
		sample: ["thesis", 'th ee" s e s'],
	},
	{
		type: "consonant",
		ipa: "ð",
		deepzen: "dh",
		combilex: "D",
		keywords: [
			{ text: "the", highlight: [0, 2] },
			{ text: "father", highlight: [2, 4] },
			{ text: "rhythm", highlight: [3, 5] },
			{ text: "worthy", highlight: [3, 5] },
		],
		sample: ["worthy", 'w @@r" r dh ee'],
	},
	{
		type: "consonant",
		ipa: "h",
		deepzen: "h",
		combilex: "h",
		keywords: [
			{ text: "hello", highlight: [0, 1] },
			{ text: "ham", highlight: [0, 1] },
			{ text: "huge", highlight: [0, 1] },
			{ text: "stakeholder", highlight: [5, 6] },
			{ text: "billhook", highlight: [4, 5] },
		],
		sample: ["hello", 'h E" l o'],
	},
	{
		type: "consonant",
		ipa: "l",
		deepzen: "l",
		combilex: "l",
		keywords: [
			{ text: "absolve", highlight: [4, 5] },
			{ text: "wheel", highlight: [4, 5] },
			{ text: "literal", highlight: [0, 1] },
			{ text: "leak", highlight: [0, 1] },
			{ text: "lay", highlight: [0, 1] },
			{ text: "flight", highlight: [1, 2] },
			{ text: "blazer", highlight: [1, 2] },
			{ text: "lately", highlight: [0, 1] },
		],
		sample: ["leak", 'l ee" k'],
	},
	// TEMP
	{
		type: "consonant",
		ipa: "l!",
		deepzen: "l!",
		combilex: "l!",
		keywords: [],
		sample: ["", ""],
	},
	{
		type: "consonant",
		ipa: "ɹ",
		deepzen: "r",
		combilex: "r",
		keywords: [
			{ text: "rain", highlight: [0, 1] },
			{ text: "contractor", highlight: [4, 5] },
			{ text: "very", highlight: [2, 3] },
			{ text: "narrow", highlight: [2, 4] },
			{ text: "red", highlight: [0, 1] },
			{ text: "brace", highlight: [1, 2] },
			{ text: "reach", highlight: [0, 1] },
			{ text: "spring", highlight: [2, 3] },
		],
		sample: ["rain", 'r ey" n'],
	},
	{
		type: "consonant",
		ipa: "ʧ",
		deepzen: "ts",
		combilex: "tS",
		keywords: [
			{ text: "chance", highlight: [0, 2] },
			{ text: "nature", highlight: [2, 3] },
			{ text: "teach", highlight: [3, 5] },
			{ text: "Milosevic", highlight: [8, 9] },
			{ text: "adventure", highlight: [5, 6] },
			{ text: "mixture", highlight: [3, 4] },
			{ text: "mochi", highlight: [2, 4] },
			{ text: "moisturise", highlight: [4, 5] },
			{ text: "catch", highlight: [2, 5] },
		],
		sample: ["catch", 'k a" ts'],
	},
	{
		type: "consonant",
		ipa: "ʤ",
		deepzen: "dz",
		combilex: "dZ",
		keywords: [
			{ text: "gin", highlight: [0, 1] },
			{ text: "joy", highlight: [0, 2] },
			{ text: "edge", highlight: [1, 4] },
			{ text: "mangier", highlight: [3, 4] },
			{ text: "margarine", highlight: [3, 4] },
			{ text: "George", highlight: [0, 1] },
		],
		sample: ["joy", 'dz oy"'],
	},
	{
		type: "consonant",
		ipa: "j",
		deepzen: "j",
		combilex: "j",
		keywords: [
			{ text: "yes", highlight: [0, 1] },
			{ text: "backyard", highlight: [4, 5] },
			{ text: "banyan", highlight: [3, 4] },
			{ text: "piano", highlight: [1, 2] },
			{ text: "yak", highlight: [0, 1] },
			{ text: "jammer", highlight: [0, 1] },
			{ text: "jowl", highlight: [0, 1] },
			{ text: "bolognaise", highlight: [6, 8] },
			{ text: "espalier", highlight: [5, 6] },
			{ text: "jaeger", highlight: [0, 1] },
			{ text: "pied", highlight: [1, 2] },
			{ text: "sarajevo,", highlight: [4, 5] },
			{ text: "somelier", highlight: [5, 6] },
			{ text: "yale", highlight: [0, 1] },
			{ text: "yay", highlight: [0, 1] },
			{ text: "execute", highlight: [3, 5] },
			{ text: "petula", highlight: [2, 4] },
			{ text: "phew", highlight: [0, 2] },
			{ text: "picayune", highlight: [4, 5] },
			{ text: "pneumonia", highlight: [0, 5] },
			{ text: "compute", highlight: [3, 5] },
			{ text: "community", highlight: [2, 6] },
			{ text: "human", highlight: [0, 3] },
			{ text: "prelude", highlight: [3, 5] },
			{ text: "presume", highlight: [3, 6] },
			{ text: "produce", highlight: [3, 5] },
			{ text: "yeast", highlight: [0, 3] },
		],
		sample: ["yes", 'j E" s'],
	},
	{
		type: "consonant",
		ipa: "w",
		deepzen: "w",
		combilex: "w",
		keywords: [
			{ text: "water", highlight: [0, 1] },
			{ text: "we", highlight: [0, 1] },
			{ text: "waft", highlight: [0, 1] },
		],
		sample: ["waft", 'w aa" f t'],
	},
	{
		type: "vowel",
		ipa: "æ",
		deepzen: "a",
		combilex: "a",
		keywords: [
			{ text: "lad", highlight: [1, 2] },
			{ text: "plastic", highlight: [2, 3] },
			{ text: "rabbit", highlight: [1, 2] },
		],
		sample: ["lad", 'l a" d'],
	},
	{
		type: "vowel",
		ipa: "ɑː",
		deepzen: "aa",
		combilex: "A",
		keywords: [
			{ text: "father", highlight: [1, 2] },
			{ text: "arm", highlight: [0, 1] },
			{ text: "mono", highlight: [1, 2] },
			{ text: "not", highlight: [1, 2] },
			{ text: "wasp", highlight: [1, 2] },
			{ text: "bomb", highlight: [1, 2] },
		],
		sample: ["not", 'n aa" t'],
	},
	{
		type: "vowel",
		optionalStress: true, // This vowel can be not stressed unlike others
		ipa: "ə",
		deepzen: "@",
		combilex: "@",
		keywords: [
			{ text: "about", highlight: [0, 1] },
			{ text: "amateur", highlight: [2, 3] },
			{ text: "away", highlight: [0, 1] },
			{ text: "machine", highlight: [1, 2] },
		],
		sample: ["away", '@ w eI"'],
	},
	{
		type: "vowel",
		ipa: "ɚ",
		deepzen: "@r",
		combilex: "@r",
		keywords: [
			{ text: "abbreviator", highlight: [9, 10] },
			{ text: "reader", highlight: [4, 5] },
			{ text: "absorber", highlight: [6, 7] },
			{ text: "meter", highlight: [3, 4] },
		],
		sample: ["reader", 'r ee" d @r r'],
	},
	{
		type: "vowel",
		ipa: "ɝ",
		deepzen: "@@r",
		combilex: "@@r",
		keywords: [
			{ text: "bird", highlight: [1, 3] },
			{ text: "absurd", highlight: [3, 4] },
			{ text: "adjourn", highlight: [3, 6] },
			{ text: "adverb", highlight: [3, 4] },
		],
		sample: ["bird", 'b @@r" r d'],
	},
	{
		type: "vowel",
		ipa: "ɛ",
		deepzen: "E",
		combilex: "E",
		keywords: [
			{ text: "bed", highlight: [1, 2] },
			{ text: "mend", highlight: [1, 2] },
			{ text: "grep", highlight: [2, 3] },
			{ text: "hair", highlight: [1, 3] },
			{ text: "there", highlight: [2, 3] },
			{ text: "aerate", highlight: [0, 2] },
			{ text: "air", highlight: [0, 2] },
		],
		sample: ["aerate", 'E" r eI t'],
	},
	{
		type: "vowel",
		ipa: "ɪ",
		deepzen: "e",
		combilex: "I",
		keywords: [
			{ text: "pin", highlight: [1, 2] },
			{ text: "quit", highlight: [2, 3] },
			{ text: "near", highlight: [1, 3] },
			{ text: "hear", highlight: [1, 3] },
			{ text: "hero", highlight: [1, 2] },
			{ text: "imperial", highlight: [3, 4] },
		],
		sample: ["hear", 'h e" r'],
	},
	{
		type: "vowel",
		ipa: "iː",
		deepzen: "ee",
		combilex: "i",
		keywords: [
			{ text: "bee", highlight: [1, 3] },
			{ text: "agree", highlight: [3, 5] },
			{ text: "albeit", highlight: [3, 4] },
			{ text: "althaea", highlight: [4, 6] },
			{ text: "alveolar", highlight: [3, 4] },
		],
		sample: ["bee", 'b ee"'],
	},
	{
		type: "vowel",
		ipa: "ɔː",
		deepzen: "aw",
		combilex: "O",
		keywords: [
			{ text: "all", highlight: [0, 1] },
			{ text: "thought", highlight: [2, 4] },
			{ text: "applaud", highlight: [4, 6] },
		],
		sample: ["thought", 'th aw" t'],
	},
	{
		type: "vowel",
		ipa: "ɔːr",
		deepzen: "awr",
		combilex: "Or",
		keywords: [
			{ text: "abhor", highlight: [3, 4] },
			{ text: "absorb", highlight: [3, 4] },
			{ text: "editorial", highlight: [4, 6] },
		],
		sample: ["abhor", 'a b h awr" r'],
	},
	{
		type: "vowel",
		ipa: "ʊ",
		deepzen: "u",
		combilex: "U",
		keywords: [
			{ text: "hood", highlight: [1, 3] },
			{ text: "put", highlight: [1, 2] },
			{ text: "wood", highlight: [1, 3] },
			{ text: "tour", highlight: [1, 3] },
			{ text: "adjure", highlight: [3, 4] },
			{ text: "allure", highlight: [3, 4] },
			{ text: "boor", highlight: [1, 3] },
		],
		sample: ["hood", 'h u" d'],
	},
	{
		type: "vowel",
		ipa: "uː",
		deepzen: "uu",
		combilex: "u",
		keywords: [
			{ text: "soon", highlight: [1, 3] },
			{ text: "blue", highlight: [2, 4] },
			{ text: "brunette", highlight: [2, 3] },
		],
		sample: ["soon", 's uu" n'],
	},
	{
		type: "vowel",
		ipa: "ʌ",
		deepzen: "V",
		combilex: "V",
		keywords: [
			{ text: "run", highlight: [1, 2] },
			{ text: "enough", highlight: [2, 4] },
			{ text: "cuddle", highlight: [1, 2] },
			{ text: "blood", highlight: [2, 4] },
		],
		sample: ["run", 'r V" n'],
	},
	{
		type: "vowel",
		ipa: "aʊ",
		deepzen: "au",
		combilex: "aU",
		keywords: [
			{ text: "now", highlight: [1, 3] },
			{ text: "power", highlight: [1, 3] },
			{ text: "flour", highlight: [2, 4] },
			{ text: "mountain", highlight: [1, 3] },
		],
		sample: ["now", 'n au"'],
	},
	{
		type: "vowel",
		ipa: "aɪ",
		deepzen: "ay",
		combilex: "aI",
		keywords: [
			{ text: "my", highlight: [1, 2] },
			{ text: "comply", highlight: [5, 6] },
			{ text: "cry", highlight: [2, 3] },
			{ text: "decipher", highlight: [3, 4] },
			{ text: "denial", highlight: [3, 4] },
		],
		sample: ["my", 'm ay"'],
	},
	{
		type: "vowel",
		ipa: "eɪ",
		deepzen: "ey",
		combilex: "eI",
		keywords: [
			{ text: "day", highlight: [1, 3] },
			{ text: "name", highlight: [1, 2] },
			{ text: "away", highlight: [2, 4] },
			{ text: "combination", highlight: [6, 7] },
		],
		sample: ["day", 'd ey"'],
	},
	{
		type: "vowel",
		ipa: "ɔɪ",
		deepzen: "oy",
		combilex: "OI",
		keywords: [
			{ text: "boy", highlight: [1, 3] },
			{ text: "deploy", highlight: [4, 6] },
			{ text: "destroyer", highlight: [5, 7] },
			{ text: "loyalty", highlight: [1, 3] },
			{ text: "embroider", highlight: [4, 6] },
		],
		sample: ["boy", 'b oy"'],
	},
	{
		type: "vowel",
		ipa: "ɛə",
		deepzen: "eh",
		combilex: "E@",
		keywords: [
			{ text: "hair", highlight: [1, 3] },
			{ text: "there", highlight: [2, 3] },
			{ text: "aerate", highlight: [0, 2] },
			{ text: "air", highlight: [0, 2] },
		],
		sample: ["hair", 'h eh" r'],
	},
	{
		type: "vowel",
		ipa: "ʊə",
		deepzen: "ur",
		combilex: "U@",
		keywords: [
			{ text: "tour", highlight: [1, 3] },
			{ text: "adjure", highlight: [3, 4] },
			{ text: "allure", highlight: [3, 4] },
			{ text: "boor", highlight: [1, 3] },
		],
		sample: ["tour", 't ur" r'],
	},
	{
		type: "vowel",
		ipa: "ɪə",
		deepzen: "ea",
		combilex: "I@",
		keywords: [
			{ text: "near", highlight: [1, 3] },
			{ text: "hear", highlight: [1, 3] },
			{ text: "hero", highlight: [1, 2] },
			{ text: "imperial", highlight: [3, 4] },
		],
		sample: ["near", 'n ea" r'],
	},
	{
		type: "vowel",
		ipa: "oʊ",
		deepzen: "o",
		combilex: "@U",
		keywords: [
			{ text: "no", highlight: [1, 2] },
			{ text: "abode", highlight: [2, 3] },
			{ text: "adagio", highlight: [5, 6] },
			{ text: "hole", highlight: [1, 2] },
			{ text: "goat", highlight: [1, 3] },
		],
		sample: ["no", 'n o"'],
	},
];
