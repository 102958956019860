import React from "react";
import classNames from "classnames";

export const CardBody = ({ children }) => (
  <div className="border-t border-gray-200 p-4">{children}</div>
);

const Card = ({ title, headerActions, className, children }) => (
  <div
    className={classNames(
      "bg-white rounded-lg shadow",
      (!className || className.indexOf("overflow-") === -1) &&
        "overflow-hidden",
      className
    )}
  >
    {title && (
      <h3 className="px-4 py-5 sm:px-6 text-lg leading-6 font-medium text-gray-900">
        {title}
        {headerActions && <div className="float-right">{headerActions}</div>}
      </h3>
    )}
    {children}
  </div>
);

export default Card;
