import React from "react";
import { Link } from "react-router";
import classNames from "classnames";

const HelpLink = ({className}) => {
    const urlParams = new URLSearchParams(window.location.search);
    const customerTrackingCode = urlParams.get("src");

    return (
        <Link 
            to={customerTrackingCode ? `/help?src=${customerTrackingCode}` : `/help`} 
            target="_blank" 
            className={classNames("flex-inline items-center", className)}>
            <svg
                className="w-5 h-5 inline mr-1"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z"
                >
                </path>
            </svg>
            Need help? See our FAQ
        </Link>
    );
}  

export default HelpLink;