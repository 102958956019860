import React from "react";
import Slider from "../../../../components/Slider/Slider";
import ChaptersPlayer from "./ChaptersPlayer";
import RawText from "../../../../components/RawText";
import { CardBody } from "../../../../components/Card";

export function Chapters({
  project,
  chapters,
  activeChapterIndex,
  setActiveChapterIndex,
  onAddIssue,
  children,
}) {
  
  if (!chapters || chapters.length === 0) {
    return null;
  }

  const chapter = chapters[activeChapterIndex];

  return (
    <section>
      <div className="bg-gray-200 pt-4 pb-3">
        <Slider
          project={project}
          chapters={chapters}
          activeChapterIndex={activeChapterIndex}
          onChange={setActiveChapterIndex}
        />
      </div>

      <ChaptersPlayer deliveredChapter={chapter} onAddIssue={onAddIssue} />

      {children}

      <CardBody className="max-h-24 overflow-y-auto">
        <RawText>{chapter.text}</RawText>
      </CardBody>
    </section>
  );
}

export default Chapters;
