import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { getDefaultMiddleware } from "@reduxjs/toolkit";
import location from "./location";
import auth from "../routes/Auth/modules/auth";
import { reducer as formReducer } from "redux-form";

const rootReducer = combineReducers({
  auth,
  location,
  form: formReducer,
});

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: customizedMiddleware,
});

export default store;
