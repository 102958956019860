import axios from "axios";
import React, { useEffect, useState } from "react";
import Preview from "./Preview";
import StepChapter from "./StepChapter";
import StepClasses from "./StepClasses";
import StepTitle from "./StepTitle";

export let globRemovedClasses = [];

const Epub = ({ data, openingCredits, closingCredits, onSuccess }) => {
  const [step, setStep] = useState(0);

  // Data
  const [book, setBook] = useState(null);

  // Edits
  const [removedChapters, setRemovedChapters] = useState([]);
  const [removedXhtmls, setRemovedXhtmls] = useState([]);
  const [removedClasses, setRemovedClasses] = useState([]);

  // Final text
  const [text, setText] = useState("");

  useEffect(() => {
    globRemovedClasses = removedClasses.map((a) => a);
  }, [removedClasses]);

  useEffect(() => {
    async function fetch() {
      const res = await axios.post("clusters/parse/epub/parser/", {
        chapters: await book.getContents(removedChapters, removedXhtmls),
        removed_classes: removedClasses,
      });

      let out = "";

      if (openingCredits.length > 0) {
        out = `{{ Opening Credits }}\n\n${openingCredits}\n\n` + out;
      }

      res.data.forEach(
        ({ title, pages }) =>
          (out += `{{ ${title.trim()} }}\n\n ${pages.join("\n\n")}\n\n`)
      );

      if (closingCredits.length > 0) {
        out += `\n\n{{ Closing Credits }}\n\n${closingCredits}`;
      }

      setText(out);
    }

    if (step === 3) {
      fetch();
    }
  }, [step]);

  const handleRemoveChapter = (i) =>
    setRemovedChapters([...removedChapters, i]);

  const handleUndoChapter = (i) => {
    setRemovedChapters(removedChapters.filter((ind) => ind !== i));

    // Remove xhtmls of this chapters
    const chapterXhtmls = book.pages
      .filter((p) => p.chapter === i)
      .map((p) => p.href);

    setRemovedXhtmls(
      removedXhtmls.filter((h) => chapterXhtmls.indexOf(h) > -1)
    );
  };

  const handleAddClasses = (classes) => {
    const values = [...globRemovedClasses, ...classes];

    setRemovedClasses([...new Set(values)]);
  };

  return (
    <div className="h-full flex">
      <div className="w-1/2">
        <div className="bg-white shadow-md h-full">
          {step === 3 && (
            <textarea
              className="w-full h-full form-input"
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
          )}
          <Preview
            step={step}
            book={book}
            data={data}
            onBookReady={setBook}
            removedXhtmls={removedXhtmls}
            setRemovedXhtmls={setRemovedXhtmls}
            removedChapters={removedChapters}
            onRemoveChapter={handleRemoveChapter}
            onUndoChapter={handleUndoChapter}
            removedClasses={removedClasses}
            onAddClasses={handleAddClasses}
          />
        </div>
      </div>
      <div className="w-1/2 py-3 px-4 overflow-y-auto">
        <h1 className="text-2xl font-bold">
          Prepare your manuscript for conversion
        </h1>

        <p className="mt-2">
          This tool is designed to prepare your manuscript for conversion into
          an audiobook.
        </p>

        <p className="mt-2">
          We accept ePub and Word documents but cannot accept PDFs. 
        </p>

        <p className="mt-2">
          <strong>Please Note:</strong> {" "} 
          Files that have been previously converted from PDFs often 
          have formatting issues, such as double spaces after each line. 
          Please amend these before uploading your project.
        </p>

        <p className="mt-2">
            You should remove any content you do not wish to be converted into audio. 
            This might include extraneous content, such as images, 
            copyright notes, tables, picture credits, captions and so forth. 
            Pictures will automatically be removed.
        </p>

        <p className="mt-2">
          For optimal audio quality, please include the word 'chapter' 
          in the name, and not just the numerical indicator. 
          E.g. Chapter One, Chapter Two NOT One, Two, etc. 
        </p>

        <p className="mt-2">
          If you are providing an ePub, simply delete the chapters, 
          pages or extraneous content you do not wish to be converted. 
        </p>
        
        <p className="mt-2">
          If you have uploaded your manuscript as a word document, 
          you’ll need to add some additional formatting. 
        </p>

         <p className="mt-2">
          {"{{"} Chapter One {"}}"}
        </p>
        
        <p className="mt-2">
          Once upon a time...
        </p>
        
        <p className="mt-2">
          {"{{"} Chapter Two {"}}"}
        </p>

        <p className="mt-2">
          and so on.
        </p>

        {book && (
          <>
            <div className="mt-3 bg-white rounded-md shadow-md py-3 px-4">
              <StepTitle
                title="1. Remove chapters"
                onClick={() => setStep(0)}
                active={step === 0}
              />

              {step === 0 && (
                <>
                  <StepChapter
                    book={book}
                    removedChapters={removedChapters}
                    onUndo={handleUndoChapter}
                  />
                  <button
                    onClick={() => setStep(2)}
                    className="rounded-md block mt-3 py-1 text-white text-base text-center bg-indigo-600 hover:bg-indigo-700 active:bg-indigo-400 w-full"
                  >
                    Continue
                  </button>
                </>
              )}
            </div>

            <div className="mt-3 bg-white rounded-md shadow-md py-3 px-4">
              <StepTitle
                title="2. Remove specific content/text"
                onClick={() => setStep(2)}
                active={step === 2}
              />
              {step === 2 && (
                <>
                  <StepClasses
                    removedClasses={removedClasses}
                    setRemovedClasses={setRemovedClasses}
                  />
                  <button
                    onClick={() => setStep(3)}
                    className="rounded-md block mt-3 py-1 text-white text-base text-center bg-indigo-600 hover:bg-indigo-700 active:bg-indigo-400 w-full"
                  >
                    Continue
                  </button>
                </>
              )}
            </div>

            <div className="mt-3 bg-white rounded-md shadow-md py-3 px-4">
              <StepTitle
                title="3. Review final text"
                onClick={() => setStep(3)}
                active={step === 3}
              />
              {step === 3 && (
                <>
                  <p>
                    Please review the text to ensure that all content that needs
                    to be converted to audio is included.
                  </p>
                  <p className="mt-2">
                    You can use the text editor to make any final changes.
                  </p>

                  <p className="mt-2">
                    Don’t forget to add {"{{"} curly braces {"}}"} if you have uploaded a word document and
                    to make sure that all extraneous content (graphs, tables, images, footnotes, etc.) 
                    have been removed, including all text that refers to said items. 
                  </p>
                  
                  <p className="mt-2">
                    Make sure all {" "}
                    <u>content that you do not want to be converted in audio</u> is removed from the text, 
                    including reference numbers, page numbers, page breaks, copyright pages, and so on.
                  </p>

                  <button
                    onClick={() => onSuccess(text)}
                    className="rounded-md block mt-3 py-1 text-white text-base text-center bg-indigo-600 hover:bg-indigo-700 active:bg-indigo-400 w-full"
                  >
                    Continue
                  </button>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Epub;
