import { message } from "antd";
import React from "react";
import PageHeader from "../../components/PageHeader";
import Details from "../Dashboard/AccountSetup/Details";

const Settings = () => {
  return (
    <div className="container pb-10">
      <PageHeader title="Settings" />
      <Details
        cta="Save"
        onComplete={() => message.success("Changes have been saved.")}
      />
    </div>
  );
};

export default Settings;
