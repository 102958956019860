import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { login } from "../modules/auth";

const Finish = () => {
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");
  
  const customerTrackingCode = urlParams.get("src");
  if (customerTrackingCode) {
    localStorage.setItem("customerTrackingCode", customerTrackingCode);
  }
  
  useEffect(() => dispatch(login(code, customerTrackingCode)), []);

  return <div>Logging in...</div>;
};

export default Finish;
