import React from "react";
import PageHeader from "../../components/PageHeader";
import PricingCard from "./PricingCard";

export function Pricing() {
  return (
    <>
      <div className="container">
        <PageHeader
          title="Pricing"
          subTitle=""
        />

        <div className="mt-5 mb-5">
          <PricingCard />
        </div>
      </div>
    </>
  );
}

export default Pricing;
