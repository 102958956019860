import React from "react";
import { AUTH_CLIENT_ID, AUTH_URL } from "../config";
import classNames from "classnames";

const SignupLink = ({className}) => {
    const urlParams = new URLSearchParams(window.location.search);
    const customerTrackingCode = urlParams.get("src");
    
    const signup = () => {
        let url = window.location.origin +"/auth/login/finish";
        if(customerTrackingCode){
          url += "%3Fsrc=" + customerTrackingCode;
        }
        const redirectUri = encodeURIComponent(`${url}`);
        window.top.location = `${AUTH_URL}/accounts/signup/?next=%2Foauth%2Fauthorize%2F%3Fclient_id=${AUTH_CLIENT_ID}%26redirect_uri=${redirectUri}%26scope%3Dread%252Cwrite%26response_type%3Dcode`;
    };

    return (
        <button 
            className={classNames(
                "flex-inline items-center focus:outline-none hover:text-blue-400", 
                className
            )}
            onClick={(e) => signup()}
        >
            Sign up
        </button>
    );
}  

export default SignupLink;