import React from "react";
import Card, { CardBody } from "../../../../components/Card";

export function MakingCorrections() {
  return (
    <Card title="We're Working on Corrections">
      <CardBody>
        We’ll make any corrections you request within 3 working days.
      </CardBody>
    </Card>
  );
}

export default MakingCorrections;
