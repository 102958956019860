export const required = value => value ? undefined : 'Required';
export const email = value => value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,7}$/i.test(value) ? 'Invalid email address' : undefined;
export const maxLength = max => value => value && value.length > max ? `Must be ${max} characters or less` : undefined;
export const minLength = min => value => value && value.length < min ? `Must be ${min} characters or more` : undefined;
export const minValue = min => value => value && value < min ? `Must be at least ${min}` : undefined;
export const maxValue = max => value => value && value > max ? `Must be at most ${max}` : undefined;
export const number = value => value && isNaN(Number(value)) ? 'Must be a number' : undefined;
export const passwordConfirmation = (value, allValues) => value !== allValues.password ? `Passwords don't match` : undefined;
export const minValue0 = minValue(0);
export const maxValue23 = maxValue(23);
export const maxValue59 = maxValue(59);