import React, { useEffect, useState, useMemo } from "react";
import States from "./components/States";
import InitialLinguisticsReview from "./StateContents/InitialLinguisticsReview";
import CustomerLinguisticsInput from "./StateContents/CustomerLinguisticsInput";
import EditingSample from "./StateContents/EditingSample";
import CustomerSampleReview from "./StateContents/CustomerSampleReview";
import EditingBook from "./StateContents/EditingBook";
import CustomerProofing from "./StateContents/CustomerProofing";
import FinalizingBook from "./StateContents/FinalizingBook";
import BookDelivered from "./StateContents/BookDelivered";
import PageHeader from "../../../components/PageHeader";
import Loading from "../../../components/Loading";
import ApiError from "../../../components/ApiError";
import axios from "axios";
import Card, { CardBody } from "../../../components/Card";
import { message } from "antd";
import MakingCorrections from "./StateContents/MakingCorrections";
import CustomerProofingReview from "./StateContents/CustomerProofingReview";
import { useUser } from "../../Auth/modules/auth";
import Share from "./components/Share";
import DemoEmulator from "../../../components/DemoEmulator";
import NeedsPayment from "./components/NeedsPayment";
import Button from "../../../components/Button";
import { Link } from "react-router";

export function Detail({ params }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const user = useUser();

  const reload = () => {
    setData(null);
    setLoading(true);
    setError(null);

    axios
      .get(`portal/projects/${params.id}`)
      .then((res) => {
        setLoading(false);
        setData(res.data);
      })
      .catch((e) => {
        setLoading(false);
        setError(e);
      });
  };

  useEffect(reload, [params.id]);

  const handleSubmitLinguisticsInput = (submit) => async (words) => {
    const payload = {
      data: words.map((w) => ({
        id: w.id,
        ipa: w.ipa,
        base64Data: w.audioDetails.base64Data,
      })),
    };

    try {
      await axios.patch(
        `portal/projects/${data.id}/patch_lexicon_entries/`,
        payload
      );

      if (submit) {
        await axios.post(
          `portal/projects/${data.id}/finish_customer_linguistics_input/`
        );
        reload();
      }
    } catch (e) {
      message.error("An error occurred.");
    }
  };

  const handleSubmitSampleReview = (values) => {
    axios
      .post(`portal/projects/${data.id}/finish_customer_sample_review/`, values)
      .then(reload)
      .catch((e) => message.error("An error occurred."));
  };

  const handleSubmitCustomerProofing = (isReview) => async (issues, finish) => {
    for (let i = 0; i < issues.length; i++) {
      const issue = issues[i];

      if (
        !issue["time"] ||
        (!isReview && !issue["comment"]) ||
        !issue["type_of_issue"]
      ) {
        message.error("Correction fields cannot be blank");
        return;
      }
    }

    try {
      await axios.post(`portal/projects/${data.id}/issues/`, { data: issues });

      if (finish) {
        await axios.post(
          `portal/projects/${data.id}/finish_customer_proofing/`
        );
      }

      setData((await axios.get(`portal/projects/${params.id}`)).data);
      message.success("Saved");
    } catch (e) {
      message.error("An error occurred.");
    }
  };

  if (loading) return <Loading />;
  if (error) return <ApiError error={error} />;
  if (!data) return null;

  const content = (
    <div className="sm:flex items-start">
      <Card className="sm:w-1/3">
        <CardBody>
          <p className="mb-6">
            These are the stages of our production process, so you can keep
            track of the progress of your audio project. As your project
            progresses through each stage you’ll receive an email notification
            with instructions if any action is required from you. You can also
            see required actions within the publisher dashboard.{" "}
          </p>
          <States
            status={data.status}
            editMode={data.edit_mode}
            hasDistribution={data.distribution}
          />
        </CardBody>
      </Card>

      <div className="hidden sm:block w-2/3 ml-5">
        {data.status === "initial_linguistics_review" && (
          <InitialLinguisticsReview project={data} />
        )}

        {data.status === "customer_linguistics_input" && (
          <CustomerLinguisticsInput
            project={data}
            onSubmit={handleSubmitLinguisticsInput(true)}
            onSave={handleSubmitLinguisticsInput(false)}
          />
        )}

        {data.status === "editing_sample" && <EditingSample />}

        {data.status === "customer_sample_review" && (
          <CustomerSampleReview
            project={data}
            onSubmit={handleSubmitSampleReview}
          />
        )}

        {data.status.startsWith("editing_book") && <EditingBook />}

        {data.status === "customer_proofing" && (
          <CustomerProofing
            project={data}
            chapters={data?.delivered_chapters}
            onSubmit={handleSubmitCustomerProofing(false)}
          />
        )}

        {data.status.startsWith("making_corrections") && <MakingCorrections />}

        {data.status === "customer_proofing_review" && (
          <CustomerProofingReview
            project={data}
            chapters={data?.delivered_chapters}
            onSubmit={handleSubmitCustomerProofing(true)}
          />
        )}

        {data.status.startsWith("finalizing_book") && <FinalizingBook />}

        {data.status === "book_delivered" && (
          <BookDelivered project={data} chapters={data?.delivered_chapters} />
        )}

        {[
          "initial_linguistics_review",
          "editing_book",
          "making_corrections",
          "finalizing_book",
        ].indexOf(data.status) > -1 && <DemoEmulator project={data} />}
      </div>
    </div>
  );

  let extra = [];
  if (user) {
    extra.push(<Share project={data} />);
  }

  if (data.distribution && !data.needs_payment) {
    extra.push(
      <Link to={`/projects/${data.id}/distribution`}>
        <Button className="ml-2">Add/edit Metadata</Button>
      </Link>
    );
  }

  return (
    <div className="px-5 pb-4">
      <PageHeader
        title={data.title}
        subTitle="Production Process"
        extra={extra}
      />

      {data.needs_payment ? <NeedsPayment project={data} /> : content}
    </div>
  );
}

export default Detail;
