import React, { useState } from "react";
import {
  Table,
  TableRow,
  TableHead,
  TableHeadCell,
  TableBody,
  TableCell,
} from "../../../../components/Table";
import { Popconfirm } from "antd";
import Button from "../../../../components/Button";
import IssueRow from "./IssueRow";
import { CardBody } from "../../../../components/Card";

export const Issues = ({ chapter, issues, setIssues, isReview, project, onSubmit }) => {
  const [filteredOnly, setFilteredOnly] = useState([]);
  
  const handleChange = (updated, position) =>
    setIssues(issues.map((issue, i) => (i === position ? updated : issue)));

  const handleDelete = (position) =>
    setIssues(issues.filter((_, i) => i !== position));

  return (
    <>
      <Table className="border-t border-gray-200">
        <TableHead>
          <TableRow>
            <TableHeadCell className="w-24">Time</TableHeadCell>
            <TableHeadCell width="27%">Correction Type</TableHeadCell>
            {!isReview && 
              <TableHeadCell>
                Note
              </TableHeadCell>
            }
            {!isReview && project.edit_mode === "managed" && (
              <TableHeadCell colspan={2}>Record</TableHeadCell>
            )}
            {isReview && (
              <>
                <TableHeadCell>Status</TableHeadCell>
                <TableHeadCell>Approve</TableHeadCell>
                <TableHeadCell>Further notes</TableHeadCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {issues.map(
            (issue, i) =>
              (!filteredOnly || issue.chapter === chapter) && (
                <>
                <TableRow>
                  <TableCell colspan={5} className="bg-gray-50">
                    #Added by {" "} 
                    <span className="font-medium">
                      {issue.email ? `${issue.email}` : `guest`}
                    </span>
                  </TableCell>
                </TableRow>
                <IssueRow
                  key={i}
                  issue={issue}
                  onChange={(updated) => handleChange(updated, i)}
                  onDelete={() => handleDelete(i)}
                  isReview={isReview}
                  project={project}
                />
                </>
              )
          )}
        </TableBody>
      </Table>

      <CardBody>
        {issues.filter((issue) => !filteredOnly || issue.chapter === chapter)
          .length === 0 && (
          <div className="py-6 pb-12 max-w-xl mx-auto text-xl text-gray-400 text-center">
            {isReview
              ? "There are not any issues with chapter."
              : "You can create issues using the button next to the player if you notice something about this chapter. Don't forget to review all chapters."}
          </div>
        )}

        <div className="text-center">
          <Button
            size="large"
            type="info"
            onClick={() => onSubmit(issues, false)}
          >
            Save
          </Button>
          <Popconfirm
            placement="top"
            onConfirm={() => onSubmit(issues, true)}
            title="Confirm submission"
          >
            <Button className="ml-3" size="large" type="primary">
              Save &amp; Submit Book
            </Button>
          </Popconfirm>
        </div>
      </CardBody>
    </>
  );
};

export default Issues;
