import React, { useState } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, getFormSyncErrors } from "redux-form";
import Button from "../../components/Button";
import Card, { CardBody } from "../../components/Card";
import TextField from "../../components/Forms/TextField";
import ApiError from "../../components/ApiError";
import { passwordConfirmation, required } from "../../services/validations";
import { useUser } from "../Auth/modules/auth";
import axios from "axios";
import classNames from "classnames";
import { Link } from "react-router";
import { Alert } from "antd";

const Form = ({
  loading,
  handleSubmit,
  isFormValid,
}) => {
  const [showFormValidation, setShowFormValidation] = useState(false);
  
  const CustomLabel = ({text}) => (
    <label className="text-gray-500 font-bold mb-0.5 flex items-center text-sm">
        {text}
    </label>
  );

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <CardBody>
          <div className="px-2">
              <div className="flex mb-4">
                <Field
                  className="h-10 sm:h-9"
                  type="password"
                  component={TextField}
                  validate={[required]}
                  label={<CustomLabel text="New Password"/>}
                  name="password"
                />
              </div>
              <div className="flex flex-col mb-4">
                <Field
                  className="h-10 sm:h-9"
                  type="password"
                  component={TextField}
                  validate={[required, passwordConfirmation]}
                  label={<CustomLabel text="Confirm Password"/>}
                  name="confirm_password"
                />
                <ul className="text-xs leading-6 font-small text-gray-500">
                  <li>Your password can’t be too similar to your other personal information.</li>
                  <li>Your password must contain at least 8 characters.</li>
                  <li>Your password can’t be a commonly used password.</li>
                  <li>Your password can’t be entirely numeric.</li>
                </ul>
              </div>
          </div>
        
        <div className={classNames("flex",
          showFormValidation && !isFormValid ? "justify-between" : "justify-end"
        )}>
        
        {showFormValidation && !isFormValid && (
          <div className="flex flex-1 items-center border border-red-400 rounded bg-red-100 mx-2 px-4 text-red-700">
            <svg className="fill-current h-5 w-5 text-red-700 mr-2" viewBox="0 0 20 20">
              <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/>
            </svg>
            <p>Please fill required fields.</p>
          </div>
        )}

        <div className="flex flex-5 justify-center items-center px-2">
          <Button 
            loading={loading} 
            size="large" 
            type="success"
            onClick={(e) => setShowFormValidation(!isFormValid)}
          >
            Save
          </Button>
        </div>
        </div>
      </CardBody>
    </Card>
  </form>
  );
};

const formName = "password-reset";
const mapStateToProps = (state, props) => {
  const formErrors = getFormSyncErrors(formName)(state);
  const isFormValid = Object.keys(formErrors).length === 0;
  return {
    formErrors,
    isFormValid,
  };
};

const ConnectedForm = connect(mapStateToProps)(reduxForm({
  form: formName,
})(Form));

const PasswordForm = ({ onComplete }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const user = useUser();
  const urlParams = new URLSearchParams(window.location.search);
  const resetToken = urlParams.get("resetToken");

  if(!(user || resetToken)) return (
    <div className="api-error">
      <Alert
        message="Error"
        description={
          <p>You do not have access to this page, please {" "}
            <Link to="/" className="font-bold underline">
              sign in
            </Link>
          </p>
        }
        type="error"
        showIcon
      />
    </div>
  );
  
  const handleSubmit = (values) => {
    setLoading(true);
    const payload = {
      ...values
    };
    if(resetToken && !user){
      payload["resetToken"] = resetToken;
    }
    axios
      .post(`portal/password-reset/`, payload)
      .then((res) => {
        onComplete();
      })
      .catch((e) => setError(e))
      .finally(() => setLoading(false));
  };

  return (
    <>
      {error && (
        <div className="mb-4">
          <ApiError error={error} />
        </div>
      )}

      <ConnectedForm
        onSubmit={handleSubmit}
        loading={loading}
      />
    </>
  );
};

export default PasswordForm;
