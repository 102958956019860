import AuthRoute from "./Auth";
import DashboardRoute from "./Dashboard";
import GuestRoute from "./Guest";
import NotFoundPage from "./NotFoundPage";
import HomePage from "./HomePage";
import SampleRoute from "./Sample";

export const createRoutes = (store) => ({
  path: "/",
  childRoutes: [
    AuthRoute, 
    DashboardRoute, 
    GuestRoute, 
    SampleRoute,
    HomePage, 
    NotFoundPage,
  ],
});

export default createRoutes;
